import * as React from "react";
import "./index.css";
import Input from "../BaseInput";

type TextInputProps = {
  className: string;
  invalid: boolean;
  defaultValue: string;
  label: string;
  placeholder: string;
  maxLength: number;
  disabled?: boolean;
  onChange: (text: string) => void;
};

class TextInput extends React.Component<TextInputProps> {
  public static defaultProps = {
    className: "",
    defaultValue: "",
    invalid: false,
    maxLength: 50,
    disabled: undefined,
    placeholder: "",
    label: "",
  };

  render() {
    const { className, placeholder, label, defaultValue, onChange, disabled, invalid, maxLength } = this.props;
    return (
      <Input
        placeholder={placeholder}
        maxLength={maxLength}
        label={label}
        defaultValue={defaultValue}
        className={className}
        onChange={onChange}
        invalid={invalid}
        disabled={disabled}
        inputType={"text"}
      />
    );
  }
}

export default TextInput;
