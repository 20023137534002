import * as React from "react";
import "./index.css";
import InnerLayout from "../partial/InnerLayout";
import { Theme } from "../../infrastructure/config";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore, RMainStore } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { RestaurantInfoStore } from "../../infrastructure/store";
import { DayOfWeekType } from "types/date";
import PageLayout from "../partial/PageLayout";

type AboutUsProps = {
  className: string;
} & RCustomerStore.CustomerState &
  RMainStore.MainState &
  RouteComponentProps;

class AboutUs extends React.Component<AboutUsProps> {
  public static defaultProps = {
    className: "",
  };

  componentDidMount = () => {
    const { isAppLoaded, isSignedIn, history } = this.props;
    if (isAppLoaded === false) return;
    if (isSignedIn === false) history.replace("/");

    // const { isLoaded } = this.state;
    // if (!isLoaded) this.getAddressList();
  };

  componentDidUpdate = () => {
    const { isAppLoaded, isSignedIn, history } = this.props;

    if (isAppLoaded === false) return;
    if (isSignedIn === false) history.replace("/");
    // const { isLoaded } = this.state;
    // if (!isLoaded) this.getAddressList();
  };

  render() {
    const { className } = this.props;
    return (
      <PageLayout>
        <InnerLayout hideBasket={true} className={className + " wa-about-us"}>
          {RestaurantInfoStore.getAboutUsText() && (
            <>
              <span
                className="wa-au-label"
                style={{ color: Theme.color.onBackground }}
              >
                About Us
              </span>
              <div
                className="wa-au-container"
                style={{ backgroundColor: Theme.color.containerBackground }}
              >
                <span
                  className="wa-au-about-us-title"
                  style={{
                    color: Theme.color.secondary,
                    borderColor: Theme.color.inputBorder,
                  }}
                >
                  {RestaurantInfoStore.getAboutUsTitle()}
                </span>
                <span
                  style={{ color: Theme.color.descriptionDarker }}
                  className="wa-au-about-us-body"
                >
                  {RestaurantInfoStore.getAboutUsText()}
                </span>
              </div>
            </>
          )}
          <span
            className="wa-au-label"
            style={{ color: Theme.color.onBackground }}
          >
            Working Hours
          </span>
          <div
            className="wa-au-container"
            style={{ backgroundColor: Theme.color.containerBackground }}
          >
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Sunday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Sunday)}
              </span>
            </div>
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Monday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Sunday)}
              </span>
            </div>
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Tuesday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Tuesday)}
              </span>
            </div>
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Wednesday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Wednesday)}
              </span>
            </div>
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Thursday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Thursday)}
              </span>
            </div>
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Friday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Friday)}
              </span>
            </div>
            <div
              className="wa-au-working-hours"
              style={{ color: Theme.color.descriptionDarker }}
            >
              <span className="wa-auwh-day">Saturday</span>
              <span className="wa-auwh-hours">
                : {RestaurantInfoStore.getWorkingTime(DayOfWeekType.Saturday)}
              </span>
            </div>
          </div>
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & RMainStore.MainState =>
      ({ ...state.customer, ...state.main } as RCustomerStore.CustomerState &
        RMainStore.MainState)
  )(AboutUs)
);
