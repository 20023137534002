import { Customer, CustomerType } from "../../types/customer";
import { DateTime } from "../helper";
import * as LocalStore from "./store";
import { CustomerService } from "../../services";
import { Store } from "types/system";

let CustomerValue = {} as Customer;

export function getToken(): string {
  checkToken();
  return CustomerValue ? CustomerValue.token.token : "";
}

export function getCustomer() {
  checkToken();
  return CustomerValue;
}

export function getId(): string {
  checkToken();
  return CustomerValue ? CustomerValue.id : "";
}

export function getFullName(): string {
  checkToken();
  return CustomerValue ? CustomerValue.fullName : "";
}

export function updateFullName(fullName: string): boolean {
  checkToken();
  CustomerValue.fullName = fullName;
  saveCustomer();
  return true;
}

export function getEmail(): string {
  checkToken();
  return CustomerValue ? CustomerValue.email : "";
}

export function getPhoneNumber(): string {
  checkToken();
  return CustomerValue ? CustomerValue.phoneNumber : "";
}

export function updatePhoneNumber(phoneNumber: string): boolean {
  checkToken();
  CustomerValue.phoneNumber = phoneNumber;
  saveCustomer();
  return true;
}

export function initializeCustomerData() {
  let customer = LocalStore.getData<Customer>(Store.Customer);
  if (!customer || !customer.id)
    customer = LocalStore.getData<Customer>(Store.GuestCustomer);
  CustomerValue = customer;
}

export function isSignedIn(): boolean {
  checkToken();
  return (
    CustomerValue !== undefined &&
    CustomerValue !== null &&
    CustomerValue !== ({} as Customer) &&
    CustomerValue.type === CustomerType.Customer &&
    CustomerValue.token !== undefined &&
    CustomerValue.token !== null &&
    CustomerValue.token.token !== undefined &&
    CustomerValue.token.token !== null &&
    CustomerValue.token.token !== ""
  );
}

export function isGuestSignedIn(): boolean {
  checkToken();
  return (
    CustomerValue !== undefined &&
    CustomerValue !== null &&
    CustomerValue !== ({} as Customer) &&
    CustomerValue.type === CustomerType.GuestCustomer &&
    CustomerValue.token !== undefined &&
    CustomerValue.token !== null &&
    CustomerValue.token.token !== undefined &&
    CustomerValue.token.token !== null &&
    CustomerValue.token.token !== ""
  );
}

export function signOut() {
  CustomerValue = {} as Customer;
  saveCustomer();
  initializeCustomerData();
  return true;
}

export async function authenticate(
  email: string,
  password: string
): Promise<LocalStore.AuthResult> {
  let authResult = await CustomerService.authenticateCustomer(email, password);

  if (authResult.isSuccess) {
    let data = authResult.data;
    let signInResult = signIn({
      id: data.id,
      email: data.email,
      fullName: data.fullName,
      phoneNumber: data.phoneNumber,
      token: data.token,
      type: data.type,
    });

    if (signInResult) {
      return { isSuccess: true, data: signInResult } as LocalStore.AuthResult;
    } else {
      return {
        isSuccess: false,
        message: "An error has occurred.",
      } as LocalStore.AuthResult;
    }
  } else {
    return {
      isSuccess: false,
      message: authResult.message,
    } as LocalStore.AuthResult;
  }
}

export function signIn(customer: Customer): Customer | undefined {
  if (!customer) return undefined;
  if (!customer.id || customer.id === "") return undefined;
  if (!customer.email || customer.email === "") return undefined;
  if (!customer.fullName || customer.fullName === "") return undefined;
  if (!customer.token || customer.token.token === "") return undefined;
  if (
    customer.type !== CustomerType.Customer &&
    customer.type !== CustomerType.GuestCustomer
  )
    return undefined;
  CustomerValue = {
    id: customer.id,
    fullName: customer.fullName,
    email: customer.email,
    token: customer.token,
    type: customer.type,
    phoneNumber: customer.phoneNumber,
  };
  if (CustomerValue.type === CustomerType.Customer) {
    LocalStore.saveData(CustomerValue, Store.Customer);
  } else if (CustomerValue.type === CustomerType.GuestCustomer) {
    LocalStore.saveData(CustomerValue, Store.GuestCustomer);
  }

  return CustomerValue;
}

export async function authanticateGuestCustomer(): Promise<
  LocalStore.AuthResult
> {
  let guestCustomer = LocalStore.getData<Customer>(Store.GuestCustomer);

  let authResult = await CustomerService.authenticateGuestCustomer(
    guestCustomer ? guestCustomer.id : ""
  );

  if (authResult.isSuccess) {
    let data = authResult.data;
    let signInResult = signIn({
      id: data.id,
      email: data.email,
      fullName: data.fullName,
      token: data.token,
      type: data.type,
    } as Customer);
    if (signInResult) {
      return { isSuccess: true, data: signInResult } as LocalStore.AuthResult;
    } else {
      return {
        isSuccess: false,
        message: "An error has occurred.",
      } as LocalStore.AuthResult;
    }
  } else {
    return {
      isSuccess: false,
      message: authResult.message,
    } as LocalStore.AuthResult;
  }
}

function saveCustomer() {
  LocalStore.saveData(CustomerValue, Store.Customer);
}

function checkToken() {
  if (CustomerValue && CustomerValue.token) {
    if (
      CustomerValue.token.token !== "" &&
      CustomerValue.token.expiryDateTime != null
    ) {
      let utcDate = DateTime.getUTCDate();
      let expiryDateTime = DateTime.convertDate(
        CustomerValue.token.expiryDateTime
      );
      if (expiryDateTime && expiryDateTime > utcDate) return true;
      else {
        CustomerValue = {} as Customer;
        return false;
      }
    } else {
      CustomerValue = {} as Customer;
      return false;
    }
  } else {
    CustomerValue = {} as Customer;
    return false;
  }
}
