import { Action, Reducer } from "redux";
import { Customer } from "../types/customer";
import { CustomerStore } from "../infrastructure/store";

export interface CustomerState {
  isSignedIn: boolean;
  customer: Customer | undefined;
}

interface SignInCustomerAction {
  type: "SIGN_IN_CUSTOMER";
  customer: Customer;
}
interface GuestSignInCustomerAction {
  type: "GUEST_SIGN_IN_CUSTOMER";
  customer: Customer;
}

interface SignOutCustomerAction {
  type: "SIGN_OUT_CUSTOMER";
}

interface UpdateFullName {
  type: "UPDATE_FULL_NAME";
  fullName: string;
}

interface UpdatePhone {
  type: "UPDATE_PHONE_NUMBER";
  phone: string;
}

type KnownAction =
  | SignOutCustomerAction
  | SignInCustomerAction
  | GuestSignInCustomerAction
  | UpdateFullName
  | UpdatePhone;

export const actionCreators = {
  signOut: () => ({ type: "SIGN_OUT_CUSTOMER" } as SignOutCustomerAction),
  signIn: (customer: Customer) =>
    ({ type: "SIGN_IN_CUSTOMER", customer: customer } as SignInCustomerAction),
  signInGuest: (customer: Customer) =>
    ({
      type: "GUEST_SIGN_IN_CUSTOMER",
      customer: customer,
    } as GuestSignInCustomerAction),
  updateFullName: (fullName: string) =>
    ({
      type: "UPDATE_FULL_NAME",
      fullName: fullName,
    } as UpdateFullName),
  updatePhone: (phone: string) =>
    ({
      type: "UPDATE_PHONE_NUMBER",
      phone: phone,
    } as UpdatePhone),
};

const unloadedState: CustomerState = {
  isSignedIn: false,
  customer: undefined,
};

export const reducer: Reducer<CustomerState> = (
  state: CustomerState | undefined,
  incomingAction: Action
): CustomerState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "UPDATE_PHONE_NUMBER":
      CustomerStore.updatePhoneNumber(action.phone);
      let newCustomer = state.customer ? state.customer : ({} as Customer);
      newCustomer.phoneNumber = action.phone;
      return {
        isSignedIn: state.isSignedIn,
        customer: newCustomer,
      };
    case "UPDATE_FULL_NAME":
      CustomerStore.updateFullName(action.fullName);
      let fullNameNewCustomer = state.customer
        ? state.customer
        : ({} as Customer);
      fullNameNewCustomer.fullName = action.fullName;
      return {
        isSignedIn: state.isSignedIn,
        customer: fullNameNewCustomer,
      };
    case "SIGN_OUT_CUSTOMER":
      CustomerStore.signOut();
      return {
        isSignedIn: false,
        customer: undefined,
      };
    case "SIGN_IN_CUSTOMER":
      CustomerStore.signIn(action.customer);
      return {
        isSignedIn: true,
        customer: action.customer,
      };
    case "GUEST_SIGN_IN_CUSTOMER":
      CustomerStore.signIn(action.customer);
      return {
        isSignedIn: false,
        customer: action.customer,
      };
  }

  return state;
};
