import * as React from "react";
import "./index.css";
import Input from "../BaseInput";

type PostCodeInputProps = {
  className: string;
  invalid: boolean;
  defaultValue: string;
  label: string;
  disabled?: boolean;
  onChange: (text: string) => void;
};

class PostCodeInput extends React.Component<PostCodeInputProps> {
  public static defaultProps = {
    className: "",
    defaultValue: "",
    disabled: undefined,
    invalid: false,
  };

  render() {
    const {
      className,
      label,
      defaultValue,
      onChange,
      invalid,
      disabled,
    } = this.props;
    return (
      <Input
        maxLength={8}
        label={label}
        defaultValue={defaultValue}
        className={className}
        onChange={onChange}
        disabled={disabled}
        invalid={invalid}
        inputType={"text"}
      />
    );
  }
}

export default PostCodeInput;
