import * as React from "react";
import "./index.css";
import { Theme } from "../../../../../infrastructure/config";
import ProductDetailInfo from "../../components/ProductDetailInfo";
import MenuItemExtraSingle from "../../components/MenuItemExtraSingle";
import MenuItemExtraMultiple from "../../components/MenuItemExtraMultiple";
import MenuItemExtraMultipleQuantity from "../../components/MenuItemExtraMultipleQuantity";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Product,
  ProductOptionType,
  ProductOption,
  SelectedOption,
} from "../../../../../types/product";
import Button from "../../../../../components/Button";

type ProductDetailBodyProps = {
  className: string;
  product: Product;
  show: boolean;
  quantity: number;
  buttonText: string;
  headerText: string;
  showDeleteButton: boolean;
  onChangeQuantity: (quantity: number) => void;
  onChangeExtras: (selectedOptions: SelectedOption[]) => void;
  onHide: () => void;
  onSubmit: () => void;
  onDelete: () => void;
};

type ProductDetailBodyState = {
  extraTotal: number;
  options: ProductOption[];
};
class ProductDetailBody extends React.Component<
  ProductDetailBodyProps,
  ProductDetailBodyState
> {
  options: any;
  constructor(props: ProductDetailBodyProps) {
    super(props);
    this.options = {};
    this.createOptionRefs(props.product.productOptions.length);
  }

  public static defaultProps = {
    className: "",
    showDeleteButton: false,
    onDelete: () => {},
  };

  public state = {
    extraTotal: 0,
    options: this.props.product.productOptions,
  };

  componentDidMount = () => {
    setTimeout(() => this.calculateExtraTotal(), 1);
  };

  createOptionRefs = (length: number): void => {
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      this.options[`option${index}`] = React.createRef();
    }
  };

  onOptionQuantityChange = (index: number) => {
    this.markAsValid(index);
    this.calculateExtraTotal();
    this.handleExtras();
  };

  markAsValid = (index: number) => {
    let item = this.options[`option${index}`].current;
    if (item) item.markAsValid();
  };

  checkAllOptionsAreValid = () => {
    const { options } = this.state;
    if (!options || options.length <= 0) return true;
    let isValid = true;
    for (let index = 0; index < options.length; index++) {
      if (!this.isOptionValid(index)) isValid = false;
    }

    return isValid;
  };

  isOptionValid = (index: number) => {
    let item = this.options[`option${index}`].current;
    if (item) return item.isValid();
    else return false;
  };

  onSubmit = () => {
    const { options } = this.state;
    let isValid = true;
    if (options && options.length > 0) {
      isValid = this.checkAllOptionsAreValid();
    }

    if (isValid === false) return;
    const { onSubmit } = this.props;
    onSubmit();
  };

  onSelectOption = (index: number, option: ProductOption) => {
    const { options } = this.state;
    if (options && options.length > index) {
      let newOptions = options;
      newOptions[index] = option;
      this.markAsValid(index);
      this.setState(
        {
          options: newOptions,
        },
        () => {
          this.calculateExtraTotal();
          this.handleExtras();
        }
      );
    }
  };

  handleExtras() {
    const { options } = this.state;
    const { onChangeExtras } = this.props;
    let selectedOptions: SelectedOption[] = [];
    if (options && options.length > 0) {
      for (let index = 0; index < options.length; index++) {
        let option = this.options[`option${index}`].current;
        if (option) {
          let itemOptions = option.getItemOptions() as SelectedOption[];
          if (itemOptions.length > 0)
            selectedOptions = [...selectedOptions, ...itemOptions];
        }
      }
    }

    onChangeExtras(selectedOptions);
  }

  calculateExtraTotal = () => {
    const { options } = this.state;
    if (options && options.length > 0) {
      let extraTotal = 0.0;
      for (let index = 0; index < options.length; index++) {
        let option = this.options[`option${index}`].current;
        if (option) extraTotal += option.getTotalPrice();
      }
      this.setState({ extraTotal: extraTotal });
    }
  };

  handleQuantity = (quantity: number) => {
    const { onChangeQuantity } = this.props;
    if (quantity < 1) quantity = 1;
    if (quantity > 10) quantity = 10;
    onChangeQuantity(quantity);
  };

  render() {
    const {
      className,
      product,
      quantity,
      onHide,
      onDelete,
      show,
      buttonText,
      headerText,
      showDeleteButton,
    } = this.props;
    const { extraTotal } = this.state;
    const closeBtn = (
      <input
        className="wa-product-detail-close"
        style={{
          color: Theme.color.onPrimary,
          backgroundColor: Theme.color.primary,
        }}
        onClick={onHide}
        type="button"
        value="&times;"
      />
    );
    return (
      <Modal
        isOpen={show}
        toggle={onHide}
        centered={true}
        className={className}
        contentClassName="wa-product-details-content"
        scrollable={true}
        fade={false}
      >
        <ModalHeader
          className="wa-product-detail-header"
          style={{
            backgroundColor: Theme.color.primary,
            color: Theme.color.onPrimary,
          }}
          toggle={onHide}
          close={closeBtn}
        >
          {headerText}
        </ModalHeader>
        <ModalBody
          className="wa-product-details"
          style={{ backgroundColor: Theme.color.background }}
        >
          <div
            className="wa-product-detail-wrapper"
            style={{ backgroundColor: Theme.color.background }}
          >
            <ProductDetailInfo
              quantity={quantity}
              product={product}
              onQuantityChange={this.handleQuantity}
              extraTotal={extraTotal}
            />
            {product.productOptions &&
              product.productOptions.length > 0 &&
              product.productOptions.map((option, index) => {
                if (option.type === ProductOptionType.Single)
                  return (
                    <MenuItemExtraSingle
                      onSelect={this.onSelectOption}
                      ref={this.options[`option${index}`]}
                      index={index}
                      key={index}
                      option={option}
                    />
                  );
                else if (option.type === ProductOptionType.MultipleQuantity)
                  return (
                    <MenuItemExtraMultipleQuantity
                      onSelect={this.onSelectOption}
                      onDeselect={this.onSelectOption}
                      ref={this.options[`option${index}`]}
                      onQuantityChange={this.onOptionQuantityChange}
                      index={index}
                      key={index}
                      option={option}
                    />
                  );
                else
                  return (
                    <MenuItemExtraMultiple
                      onSelect={this.onSelectOption}
                      onDeselect={this.onSelectOption}
                      ref={this.options[`option${index}`]}
                      onQuantityChange={this.onOptionQuantityChange}
                      index={index}
                      key={index}
                      option={option}
                    />
                  );
              })}
          </div>
        </ModalBody>
        <ModalFooter
          className={
            "wa-product-detail-footer" + (showDeleteButton ? "" : " center")
          }
        >
          {showDeleteButton && <span></span>}
          <Button
            onClick={this.onSubmit}
            text={buttonText}
            color="success"
            className="wa-pdf-save"
          />
          {showDeleteButton && (
            <Button
              onClick={onDelete}
              iconType="trash"
              color="error"
              className="wa-pdb-empty"
            />
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ProductDetailBody;
