import * as React from "react";
import "./index.css";
import ModalContainer from "components/ModalContainer";
import Button from "components/Button";
import { OrderType } from "types/order";
import { OrderStore } from "../../../infrastructure/store";
import { TextHelper } from "infrastructure/helper";

type OrderProductGroupSelectionProps = {
  show: boolean;
  onSelect: (orderType: OrderType) => void;
};

class OrderProductGroupSelection extends React.Component<
  OrderProductGroupSelectionProps
> {
  public static defaultProps = {
    show: false,
  };

  render() {
    const { show, onSelect } = this.props;
    const orderTypes = OrderStore.getActiveOrderTypes();
    let actualShow = orderTypes && orderTypes.length > 0 && show;

    return (
      <ModalContainer
        show={actualShow}
        showFooter={false}
        showHeader={false}
        className="wa-"
        onHide={() => {}}
      >
        <div className="wa-ots-container">
          <span className="wa-ots-title">Please, choose an order type:</span>
          {orderTypes &&
            orderTypes.map((type) => (
              <Button
                key={type.orderType}
                className="wa-ots-button"
                iconType={
                  type.orderType === OrderType.Delivery
                    ? "motorcycle"
                    : type.orderType === OrderType.Takeaway
                    ? "shoppingBag"
                    : type.orderType === OrderType.EatIn
                    ? "cutlery"
                    : undefined
                }
                text={TextHelper.getOrderTypeText(type.orderType)}
                color={
                  type.orderType === OrderType.Delivery
                    ? "delivery"
                    : type.orderType === OrderType.Takeaway
                    ? "takeaway"
                    : type.orderType === OrderType.EatIn
                    ? "eatin"
                    : "primary"
                }
                onClick={() => onSelect(type.orderType)}
              />
            ))}
        </div>
      </ModalContainer>
    );
  }
}

export default OrderProductGroupSelection;
