import * as LocalStore from "./store";
import { Store } from "types/system";

let appSettings = {
  version: "",
} as AppSettings;

export function getVersion(): string {
  return appSettings ? appSettings.version : "";
}

export function setVersion(version: string): void {
  appSettings.version = version;
}

export function initializeAppSettings() {
  appSettings = LocalStore.getData<AppSettings>(Store.AppSettings);
}

export function saveAppSettings() {
  LocalStore.saveData(appSettings, Store.AppSettings);
}

interface AppSettings {
  version: string;
}
