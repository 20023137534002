import * as React from "react";
import "./index.css";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import {
  ProductOptionItem,
  SelectedOption,
} from "../../../../../types/product";

type MenuItemExtraSingleItemProps = {
  className: string;
  item: ProductOptionItem;
  index: number;
  show: boolean;
  onSelect: (index: number) => void;
};

class MenuItemExtraSingleItem extends React.Component<
  MenuItemExtraSingleItemProps
> {
  public static defaultProps = {
    className: "",
    selected: false,
  };

  onPress = (): void => {
    const { onSelect, index } = this.props;
    onSelect(index);
  };

  getItemOption = (): SelectedOption | undefined => {
    const { item } = this.props;
    return item.selected
      ? ({ quantity: 1, productOptionItemId: item.id } as SelectedOption)
      : undefined;
  };

  getQuantity = (): number => {
    const { item } = this.props;
    return item && item.selected ? 1 : 0;
  };

  getPrice = (): number => {
    const { item } = this.props;
    return item ? item.price : 0;
  };

  getTotalPrice = (): number => {
    return this.getQuantity() * this.getPrice();
  };

  render() {
    const { className, item, show } = this.props;
    return (
      <button
        onClick={(e: React.MouseEvent) => this.onPress()}
        className={className + " wa-miesi" + (show ? "" : " wa-d-none")}
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        <div className="wa-miesi-detail">
          <span className="wa-miesi-name" style={{ color: Theme.color.title }}>
            {item.name}
          </span>
          {item.price > 0 && (
            <span
              className="wa-miesi-price"
              style={{ color: Theme.color.primary }}
            >
              (+£{item.price.toFixed(2)})
            </span>
          )}
        </div>
        {item.selected === true && (
          <RadioButtonChecked
            className="wa-miesi-icon"
            style={{ color: Theme.color.secondary }}
          />
        )}
        {item.selected === false && (
          <RadioButtonUnchecked
            className="wa-miesi-icon"
            style={{ color: Theme.color.title }}
          />
        )}
      </button>
    );
  }
}

export default MenuItemExtraSingleItem;
