import * as React from "react";
import "./index.css";
import { Theme } from "../../../../infrastructure/config";

type BannerProps = {
  className: string;
  backgroundColor: string;
  text: string;
};

class Banner extends React.Component<BannerProps> {
  public static defaultProps = {
    className: "",
    backgroundColor: Theme.color.secondary,
  };

  render() {
    const { className, backgroundColor, text } = this.props;
    return (
      <span
        className={className + " wa-banner"}
        style={{
          backgroundColor: backgroundColor,
          color: Theme.color.onPrimary,
        }}
      >
        {text}
      </span>
    );
  }
}

export default Banner;
