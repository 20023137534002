import * as React from "react";
import "./index.css";
import { Delete } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import Basket from "./Basket";
import { OrderStore } from "../../../../../infrastructure/store";
import {
  ApplicationState,
  RShoppingCartStore,
  RMainStore,
  RCustomerStore,
  ROrderStore,
} from "../../../../../store";
import { connect } from "react-redux";
import Alert from "../../../../../components/Alert";
import { ShoppingCartService } from "../../../../../services";
import { ShoppingCart } from "../../../../../types/shoppingCart";

type RightAreaProps = {
  className: string;
} & RMainStore.MainState &
  ROrderStore.OrderState &
  RCustomerStore.CustomerState &
  typeof RShoppingCartStore.actionCreators;

type RightAreaState = {
  showRemoveAllAlert: boolean;
};

class RightArea extends React.Component<RightAreaProps, RightAreaState> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    showRemoveAllAlert: false,
  };

  onRemoveAll = async () => {
    const { customer, updateShoppingCart, selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    if (customer === undefined) return;
    let emptyResult = await ShoppingCartService.emptyShoppingCart(
      customer.id,
      orderProductGroup,
      customer.token.token
    );

    if (emptyResult.isSuccess) updateShoppingCart({} as ShoppingCart);
    this.setState({ showRemoveAllAlert: false });
  };
  onCancelRemoveAll = () => {
    this.setState({ showRemoveAllAlert: false });
  };

  render() {
    const { className, isAppLoaded } = this.props;
    const { showRemoveAllAlert } = this.state;
    return (
      <div className={className + " wa-right-area"}>
        <Alert
          show={showRemoveAllAlert}
          bodyText={"Are you sure clear the basket?"}
          okButtonText={"YES"}
          cancelButtonText={"NO"}
          showCancelButton={true}
          onPressOk={this.onRemoveAll}
          onPressCancel={this.onCancelRemoveAll}
        />
        <div
          className="wa-right-area-container"
          style={{ borderColor: Theme.color.backgroundDarker }}
        >
          <div
            className="wa-right-area-header"
            style={{
              backgroundColor: Theme.color.primary,
              color: Theme.color.onPrimary,
            }}
          >
            <span className="wa-right-area-header-text">Basket</span>
            <a
              href="#0"
              onClick={() => this.setState({ showRemoveAllAlert: true })}
            >
              <Delete htmlColor={Theme.color.onPrimary} />
            </a>
          </div>
          {isAppLoaded && <Basket />}
        </div>
      </div>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RMainStore.MainState &
    RCustomerStore.CustomerState &
    ROrderStore.OrderState =>
    ({
      ...state.customer,
      ...state.main,
      ...state.order,
    } as RMainStore.MainState &
      RCustomerStore.CustomerState &
      ROrderStore.OrderState),
  { ...RShoppingCartStore.actionCreators }
)(RightArea);
