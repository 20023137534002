import * as React from "react";
import "./index.css";
import { Theme, Settings } from "../../../../../infrastructure/config";

type MenuTabListProps = {
  className: string;
  activeTab: ActiveTab;
  onChange: (activeTab: ActiveTab) => void;
};

export enum ActiveTab {
  MenuList,
  CategoryList,
  Info,
}

class MenuTabList extends React.Component<MenuTabListProps> {
  public static defaultProps = {
    className: "",
  };

  onChange = (activeTab: ActiveTab) => {
    const { onChange } = this.props;
    onChange(activeTab);
  };

  render() {
    const { className, activeTab } = this.props;
    return (
      <div
        className={
          className +
          " wa-menu-tab-list" +
          (Settings.show.appBanner ? " app-banner" : "")
        }
        role="group"
        style={{
          borderColor: Theme.color.primary,
          backgroundColor: Theme.color.background,
        }}
      >
        <button
          type="button"
          onClick={(e) => this.onChange(ActiveTab.CategoryList)}
          style={{
            borderColor: Theme.color.primaryDarker,
            color:
              activeTab === ActiveTab.CategoryList
                ? Theme.color.primaryDarker
                : Theme.color.title,
          }}
          className={
            "btn wa-menu-tab" +
            (activeTab === ActiveTab.CategoryList ? " active" : "")
          }
        >
          Categories
        </button>
        <button
          type="button"
          onClick={(e) => this.onChange(ActiveTab.MenuList)}
          style={{
            borderColor: Theme.color.primaryDarker,
            color:
              activeTab === ActiveTab.MenuList
                ? Theme.color.primaryDarker
                : Theme.color.title,
          }}
          className={
            "btn wa-menu-tab" +
            (activeTab === ActiveTab.MenuList ? " active" : "")
          }
        >
          Menu
        </button>
        <button
          type="button"
          onClick={(e) => this.onChange(ActiveTab.Info)}
          style={{
            borderColor: Theme.color.primaryDarker,
            color:
              activeTab === ActiveTab.Info
                ? Theme.color.primaryDarker
                : Theme.color.title,
          }}
          className={
            "btn wa-menu-tab" + (activeTab === ActiveTab.Info ? " active" : "")
          }
        >
          Info
        </button>
      </div>
    );
  }
}

export default MenuTabList;
