import * as React from "react";
import "./index.css";
import InnerLayout from "../partial/InnerLayout";
import EmptyAddressList from "./components/EmptyAddressList";
import { CustomerAddress } from "../../types/customerAddress";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  RMainStore,
  RAddAddressModalStore,
} from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { CustomerAddressService } from "../../services";
import Loading from "../../components/Loading";
import AddressListBody from "../partial/AddressList";
import Button from "../../components/Button";
import { Theme } from "../../infrastructure/config";
import EditAddress from "./components/EditAddress";
import PageLayout from "../partial/PageLayout";

type AddressListProps = {
  className: string;
} & RCustomerStore.CustomerState &
  typeof RAddAddressModalStore.actionCreators &
  RMainStore.MainState &
  RouteComponentProps;

type AddressListState = {
  addressList: CustomerAddress[];
  isLoaded: boolean;
  showEditModal: boolean;
  selectedAddress: CustomerAddress;
};

class AddressList extends React.Component<AddressListProps, AddressListState> {
  constructor(props: AddressListProps) {
    super(props);

    this.onClickAddAddress = this.onClickAddAddress.bind(this);
  }

  public static defaultProps = {
    className: "",
  };

  public state = {
    addressList: [] as CustomerAddress[],
    isLoaded: false,
    showEditModal: false,
    selectedAddress: {} as CustomerAddress,
  };

  componentDidMount = async () => {
    const { isAppLoaded, isSignedIn, history } = this.props;
    if (isAppLoaded === false) return;
    if (isSignedIn === false) history.replace("/");

    const { isLoaded } = this.state;
    if (!isLoaded) {
      let addressList = await this.getAddressList();
      this.setState({ addressList: addressList, isLoaded: true });
    }
  };

  componentDidUpdate = async () => {
    const { isAppLoaded, isSignedIn, history } = this.props;

    if (isAppLoaded === false) return;
    if (isSignedIn === false) history.replace("/");
    const { isLoaded } = this.state;
    if (!isLoaded) {
      let addressList = await this.getAddressList();
      this.setState({ addressList: addressList, isLoaded: true });
    }
  };

  getAddressList = async () => {
    const { customer } = this.props;
    if (customer === undefined) return [] as CustomerAddress[];
    let addressList = await CustomerAddressService.getCustomerAddresses(
      customer.id,
      customer.token.token
    );

    if (addressList.isSuccess) return addressList.data;
    else return [] as CustomerAddress[];
  };

  onAddAddress = async () => {
    let addressList = await this.getAddressList();
    this.setState({ addressList: addressList });
  };

  onClickAddAddress = async () => {
    const { showAddAddressModal, hideAddAddressModal } = this.props;
    showAddAddressModal(hideAddAddressModal, async () => {
      hideAddAddressModal();
      let addressList = await this.getAddressList();
      this.setState({ addressList: addressList });
    });
  };

  onSelectAddress = (address: CustomerAddress) => {
    this.setState({ showEditModal: true, selectedAddress: address });
  };

  onHideEditAddress = () => {
    this.setState({
      showEditModal: false,
      selectedAddress: {} as CustomerAddress,
    });
  };

  onUpdateAddress = async (address: CustomerAddress) => {
    const { customer } = this.props;

    if (customer === undefined || address === undefined) return;

    let updateResult = await CustomerAddressService.updateCustomerAddress(
      {
        addressDescription: address.addressDescription,
        addressName: address.addressName,
        customerAddressId: address.id,
        customerFullName: address.customerFullName,
        doorNumber: address.doorNumber,
        isDefault: address.isDefault,
        phoneNumber: address.phoneNumber,
        postCode: address.postCode,
      },
      customer.id,
      customer.token.token
    );

    if (updateResult.isSuccess) {
      let addressList = await this.getAddressList();

      this.setState({ addressList: addressList });
      this.onHideEditAddress();
    }
  };

  onDeleteAddress = async (id: string) => {
    const { customer } = this.props;
    if (customer === undefined) return;
    let deleteResult = await CustomerAddressService.deleteCustomerAddress(
      id,
      customer.id,
      customer.token.token
    );

    if (deleteResult.isSuccess) {
      let addressList = await this.getAddressList();
      this.setState({ addressList: addressList });
      this.onHideEditAddress();
    }
  };

  render() {
    const { className } = this.props;
    const {
      addressList,
      isLoaded,
      showEditModal,
      selectedAddress,
    } = this.state;
    return (
      <PageLayout>
        <InnerLayout
          hideBasket={true}
          className={className + " wa-address-list"}
        >
          <Loading className="wa-al-container" show={!isLoaded}>
            {(addressList && addressList.length) > 0 ? (
              <div
                className="wa-al-body"
                style={{ backgroundColor: Theme.color.containerBackground }}
              >
                {showEditModal && (
                  <EditAddress
                    onHide={this.onHideEditAddress}
                    address={selectedAddress}
                    show={showEditModal}
                    onSave={this.onUpdateAddress}
                    onDelete={this.onDeleteAddress}
                  />
                )}
                <AddressListBody
                  addressList={addressList}
                  onSelect={this.onSelectAddress}
                />
                <Button
                  text="ADD NEW ADDRESS"
                  onClick={this.onClickAddAddress}
                />
              </div>
            ) : (
              <EmptyAddressList onAddAddress={this.onAddAddress} />
            )}
          </Loading>
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & RMainStore.MainState =>
      ({ ...state.customer, ...state.main } as RCustomerStore.CustomerState &
        RMainStore.MainState),
    {
      ...RAddAddressModalStore.actionCreators,
    }
  )(AddressList)
);
