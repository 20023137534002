import { TextHelper } from ".";
import { OrderStatus, OrderType } from "../../types/order";
import { Theme } from "../config";

export function orderStatus(status: OrderStatus): StatusStyle {
  switch (status) {
    case OrderStatus.Waiting:
      return { color: Theme.color.orderWaiting, label: "Waiting" };
    case OrderStatus.Preparing:
      return { color: Theme.color.orderPreparing, label: "Preparing" };
    case OrderStatus.Cancelled:
      return { color: Theme.color.orderCancelled, label: "Cancelled" };
    case OrderStatus.Rejected:
      return { color: Theme.color.orderRejected, label: "Rejected" };
    case OrderStatus.Shipped:
      return { color: Theme.color.title, label: "Shipped" };
    case OrderStatus.Returned:
      return { color: Theme.color.title, label: "Returned" };
    case OrderStatus.Completed:
      return { color: Theme.color.orderCompleted, label: "Completed" };
    case OrderStatus.ScheduledWaiting:
      return { color: Theme.color.orderScheduled, label: "Scheduled" };
    default:
      return { color: Theme.color.title, label: "" };
  }
}

export function orderType(type: OrderType): StatusStyle {
  switch (type) {
    case OrderType.Delivery:
      return {
        color: Theme.color.title,
        label: TextHelper.getOrderTypeText(type),
      };
    case OrderType.Takeaway:
      return {
        color: Theme.color.title,
        label: TextHelper.getOrderTypeText(type),
      };
    case OrderType.EatIn:
      return {
        color: Theme.color.title,
        label: TextHelper.getOrderTypeText(type),
      };
    default:
      return {
        color: Theme.color.title,
        label: TextHelper.getOrderTypeText(type),
      };
  }
}

interface StatusStyle {
  color: string;
  label: string;
}
