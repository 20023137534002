export enum DayOfWeekType {
  None = 0,
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export interface TimeSpan {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  ticks: number;
}

export enum TTL {
  Millisecond = 1,
  Second = 1000,
  Minute = 60000,
  Hour = 3600000,
  Day = 86400000,
  Week = 604800000,
}
