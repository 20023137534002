import * as React from "react";
import "./index.css";
import { Theme } from "../../../../../../infrastructure/config";
import { Validator } from "../../../../../../infrastructure/helper";
import {
  CustomerStore,
  OrderStore,
} from "../../../../../../infrastructure/store";
import {
  ApplicationState,
  RCustomerStore,
  RShoppingCartStore,
  ROrderStore,
  RForgetPasswordModalStore,
  RSignUpModalStore,
} from "../../../../../../store";
import { connect } from "react-redux";
import { ShoppingCartService } from "../../../../../../services";
import { EmailInput, PasswordInput } from "../../../../../../components/Input";
import Button from "../../../../../../components/Button";
import Alert from "../../../../../../components/Alert";
import { ShoppingCart } from "types/shoppingCart";

type LeftAreaSignInProps = {
  className: string;
} & RCustomerStore.CustomerState &
  ROrderStore.OrderState &
  typeof RCustomerStore.actionCreators &
  typeof RShoppingCartStore.actionCreators &
  typeof RForgetPasswordModalStore.actionCreators &
  typeof RSignUpModalStore.actionCreators;

type LeftAreaSignInState = {
  email: string;
  password: string;
  isEmailValid: boolean;
  isPasswordValid: boolean;
  showAlert: boolean;
  alertMessage: string;
};

class LeftAreaSignIn extends React.Component<
  LeftAreaSignInProps,
  LeftAreaSignInState
> {
  public static defaultProps = {
    className: "",
  };
  public state = {
    email: "",
    password: "",
    isEmailValid: true,
    isPasswordValid: true,
    showAlert: false,
    alertMessage: "",
  };

  onClickSignIn = async (): Promise<void> => {
    const { email, password } = this.state;
    const { customer, signIn, updateShoppingCart } = this.props;
    let isEmailValid = Validator.isValidEmail(email);
    let isPasswordValid = Validator.isValidPassword(password);

    if (!isPasswordValid || !isEmailValid) {
      this.setState({
        isEmailValid: isEmailValid,
        isPasswordValid: isPasswordValid,
      });
      return;
    }

    let authResult = await CustomerStore.authenticate(email, password);

    if (!authResult.isSuccess || !authResult.data) {
      this.showAlert(authResult.message);
      return;
    }

    let newCustomer = authResult.data;
    let migrateResult = await this.migrateShoppingCart(
      customer === undefined ? "" : customer.id,
      newCustomer.id,
      newCustomer.token.token
    );

    if (migrateResult) {
      let shoppingCart = await this.getShoppingCart(
        newCustomer.id,
        newCustomer.token.token
      );
      if (shoppingCart) updateShoppingCart(shoppingCart);
    }

    signIn(newCustomer);
  };

  migrateShoppingCart = async (
    prevCustomerId: string,
    currentCustomerId: string,
    currentCustomerToken: string
  ): Promise<boolean> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let migrateResult = await ShoppingCartService.migrateShoppingCart(
      prevCustomerId,
      currentCustomerId,
      orderProductGroup,
      currentCustomerToken
    );

    if (!migrateResult.isSuccess) this.showAlert(migrateResult.message);
    return migrateResult.isSuccess;
  };

  getShoppingCart = async (
    customerId: string,
    customerToken: string
  ): Promise<ShoppingCart | undefined> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let shoppingCartResult = await ShoppingCartService.getShoppingCart(
      customerId,
      orderProductGroup,
      selectedOrderType,
      customerToken
    );

    if (shoppingCartResult.isSuccess) return shoppingCartResult.data;
    else return undefined;
  };

  showAlert = (message: string) => {
    this.setState({ showAlert: true, alertMessage: message });
  };

  hideAlert = () => {
    this.setState({ showAlert: false, alertMessage: "" });
  };

  render() {
    const {
      className,
      showForgetPasswordModal,
      hideForgetPasswordModal,
      showSignUpModal,
      hideSignUpModal,
    } = this.props;
    const {
      email,
      password,
      isEmailValid,
      isPasswordValid,
      showAlert,
      alertMessage,
    } = this.state;
    return (
      <div
        className={className + " wa-left-area-sign-in"}
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        <Alert
          show={showAlert}
          bodyText={alertMessage}
          okButtonText={"OK"}
          onPressOk={this.hideAlert}
        />
        <EmailInput
          name="em-sign-in"
          invalid={!isEmailValid}
          label={"E-mail"}
          defaultValue={email}
          onChange={(text) =>
            this.setState({ email: text, isEmailValid: true })
          }
        />
        <PasswordInput
          name="pw-sign-in"
          invalid={!isPasswordValid}
          label={"Password"}
          defaultValue={password}
          onChange={(text) =>
            this.setState({ password: text, isPasswordValid: true })
          }
        />
        <div className="wa-sign-in-middle">
          <a
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              // this.setState({ showForgetPassword: true });
              showForgetPasswordModal(hideForgetPasswordModal, (email) => {
                hideForgetPasswordModal();
                this.setState({ email: email });
              });
            }}
            className="wa-sign-in-forget-password"
            style={{ color: Theme.color.forgetPassword }}
          >
            Forget Password
          </a>
          <div className="wa-sign-in-remember-me-container">
            <input
              className=""
              type="checkbox"
              id="rememberMe"
              defaultChecked={true}
            />
            <label
              className="wa-sign-in-remember-me-label"
              htmlFor="rememberMe"
            >
              Remember Me
            </label>
          </div>
        </div>
        <Button text="Sign-in" onClick={this.onClickSignIn} />
        <span
          className="wa-sign-in-create-account"
          style={{ backgroundColor: Theme.color.backgroundDarker }}
        >
          Don't you have an account?
          <a
            href="#0"
            className="wa-sign-in-sign-up"
            onClick={(e) => {
              e.preventDefault();
              showSignUpModal(hideSignUpModal, (email) => {
                hideSignUpModal();
                this.setState({ email: email });
              });
            }}
            style={{ color: Theme.color.primary }}
          >
            Create Now!
          </a>
        </span>
      </div>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
    ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
      ROrderStore.OrderState),
  {
    ...RCustomerStore.actionCreators,
    ...RShoppingCartStore.actionCreators,
    ...RSignUpModalStore.actionCreators,
    ...RForgetPasswordModalStore.actionCreators,
  }
)(LeftAreaSignIn as any);
