import * as React from "react";
import "./index.css";
import Input from "../BaseInput";

type PasswordInputProps = {
  name?: string;
  className?: string;
  invalid?: boolean;
  defaultValue?: string;
  label: string;
  disabled?: boolean;
  onChange: (text: string) => void;
};

class PasswordInput extends React.Component<PasswordInputProps> {
  render() {
    const {
      className,
      label,
      defaultValue,
      onChange,
      invalid,
      disabled,
      name,
    } = this.props;
    return (
      <Input
        name={name}
        maxLength={50}
        label={label}
        defaultValue={defaultValue}
        className={className}
        onChange={onChange}
        disabled={disabled}
        invalid={invalid}
        inputType={"password"}
      />
    );
  }
}

export default PasswordInput;
