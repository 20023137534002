import * as React from "react";
import { connect } from "react-redux";
import "./index.css";
import Menu from "./components/Menu";
import MobileBasket from "./components/MobileBasket";
import InnerLayout from "../partial/InnerLayout";
import Banner from "./components/Banner";
import { Theme } from "../../infrastructure/config";
import { RestaurantTable } from "../../types/restaurant";
import { Campaign } from "../../types/campaign";
import { CampaignService, ShoppingCartService } from "../../services";
import {
  ApplicationState,
  ROrderStore,
  RCustomerStore,
  RShoppingCartStore,
  RSignInModalStore,
} from "store";
import { OrderStore } from "../../infrastructure/store";
import { OrderProductGroupType, OrderType } from "types/order";
import OrderProductGroupSelection from "../partial/OrderProductGroupSelection";
import PageLayout from "../partial/PageLayout";
import ChangeOrderType from "../partial/ChangeOrderType";
import OrderSelectTable from "pages/partial/OrderSelectTable";
import RestaurantStatusBanner from "./components/RestaurantStatusBanner";
import OrderTypeStatusBanner from "./components/OrderTypeStatusBanner";
import { RouteComponentProps, withRouter } from "react-router";
import { HomePageState } from "../../types/system";

type HomeProps = {} & ROrderStore.OrderState &
  RCustomerStore.CustomerState &
  typeof RShoppingCartStore.actionCreators &
  typeof ROrderStore.actionCreators &
  typeof RSignInModalStore.actionCreators &
  RouteComponentProps<{}, any, HomePageState>;

type HomeState = {
  activeCampaigns: Campaign[];
  showOrderProductTypeBanner: boolean;
  showOrderTypeSelection: boolean;
  showLoading: boolean;
  showTableSelectModal: boolean;
};
class Home extends React.Component<HomeProps, HomeState> {
  public state = {
    activeCampaigns: [] as Campaign[],
    showOrderProductTypeBanner: false,
    showOrderTypeSelection: false,
    showLoading: true,
    showTableSelectModal: false,
  };

  componentDidMount = async () => {
    const { selectedOrderType } = this.props;

    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    if (
      orderProductGroup !== OrderProductGroupType.EatIn &&
      orderProductGroup !== OrderProductGroupType.DeliveryTakeaway
    ) {
      this.setState({
        activeCampaigns: [],
        showOrderProductTypeBanner: OrderStore.showChangeOrderTypeBanner(),
        showOrderTypeSelection: true,
        showLoading: false,
      });
    } else {
      let activeCampaigns = await this.getActiveCampaigns();
      this.setState({
        activeCampaigns: activeCampaigns,
        showOrderProductTypeBanner: OrderStore.showChangeOrderTypeBanner(),
        showOrderTypeSelection: false,
        showLoading: false,
      });
    }

    this.showSignIn();
  };

  showSignIn = () => {
    const {
      showSignInModal,
      hideSignInModal,
      isSignedIn,
      location,
    } = this.props;
    if (
      location &&
      location.state &&
      location.state.openModal === true &&
      isSignedIn === false
    ) {
      showSignInModal(
        hideSignInModal,
        () => {
          hideSignInModal();
        },
        location.state.email ? location.state.email : ""
      );
    }
  };

  getActiveCampaigns = async (): Promise<Campaign[]> => {
    const { selectedOrderType } = this.props;

    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let campaignResult = await CampaignService.getActiveCampaigns(
      orderProductGroup
    );

    if (campaignResult.isSuccess) return campaignResult.data;
    else return [] as Campaign[];
  };

  onOrderTypeChanged = async (orderType: OrderType): Promise<void> => {
    this.setState({ showLoading: true });
    const { selectOrderType } = this.props;
    let prevOrderType = OrderStore.getOrderType();
    if (prevOrderType === orderType) {
      if (orderType === OrderType.EatIn) {
        this.setState({
          showOrderTypeSelection: false,
          showLoading: false,
          showTableSelectModal: true,
        });
      } else {
        this.setState({ showOrderTypeSelection: false, showLoading: false });
      }
      return;
    }

    OrderStore.setTable(undefined);
    OrderStore.setOrderType(orderType);
    selectOrderType(orderType);
    let orderProductGroup = OrderStore.getOrderProductGroup();

    await this.getShoppingCart(orderProductGroup, orderType);

    let activeCampaigns = await this.getActiveCampaigns();
    if (orderProductGroup === OrderProductGroupType.EatIn) {
      this.setState({
        activeCampaigns: activeCampaigns,
        showOrderTypeSelection: false,
        showLoading: false,
        showTableSelectModal: true,
      });
    } else {
      this.setState({
        activeCampaigns: activeCampaigns,
        showOrderTypeSelection: false,
        showLoading: false,
      });
    }
  };

  getShoppingCart = async (
    orderProductGroup: OrderProductGroupType,
    orderType: OrderType
  ): Promise<void> => {
    const { customer, updateShoppingCart } = this.props;
    if (customer === undefined) return;
    let shoppingCartResult = await ShoppingCartService.getShoppingCart(
      customer.id,
      orderProductGroup,
      orderType,
      customer.token.token
    );

    if (shoppingCartResult.isSuccess) {
      updateShoppingCart(shoppingCartResult.data);
    }
  };

  renderActiveCampaigns = () => {
    const { activeCampaigns } = this.state;
    if (activeCampaigns && activeCampaigns.length > 0) {
      return (
        <>
          {activeCampaigns.map((campaign, index) => (
            <Banner
              key={index}
              text={campaign.description}
              backgroundColor={Theme.color.secondary}
            />
          ))}
        </>
      );
    } else return <></>;
  };

  onSelectTable = (table: RestaurantTable) => {
    OrderStore.setTable(table);
    this.setState({ showTableSelectModal: false });
  };

  render() {
    const {
      showOrderTypeSelection,
      showOrderProductTypeBanner,
      showTableSelectModal,
    } = this.state;
    return (
      <PageLayout>
        <InnerLayout>
          {showOrderTypeSelection && (
            <OrderProductGroupSelection
              onSelect={this.onOrderTypeChanged}
              show={showOrderTypeSelection}
            />
          )}
          {showTableSelectModal && (
            <OrderSelectTable
              onHide={() => this.setState({ showTableSelectModal: false })}
              onError={() => {}}
              onSelect={this.onSelectTable}
              show={showTableSelectModal}
            />
          )}
          {showOrderProductTypeBanner && (
            <ChangeOrderType
              onPress={() => this.setState({ showOrderTypeSelection: true })}
            />
          )}
          {showOrderTypeSelection === false && (
            <>
              <RestaurantStatusBanner />
              <OrderTypeStatusBanner />
              <this.renderActiveCampaigns />
              <Menu className="wa-home-menu" />
            </>
          )}
          <MobileBasket className="d-block d-lg-none" />
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): ROrderStore.OrderState & RCustomerStore.CustomerState =>
      ({ ...state.order, ...state.customer } as ROrderStore.OrderState &
        RCustomerStore.CustomerState),
    {
      ...RShoppingCartStore.actionCreators,
      ...ROrderStore.actionCreators,
      ...RSignInModalStore.actionCreators,
    }
  )(Home)
);
