import { Action, Reducer } from "redux";
import { Customer } from "../types/customer";

export interface MainState {
  isAppLoaded: boolean;
}

interface AppLoadedAction {
  type: "APP_LOADED";
  customer: Customer;
}

type KnownAction = AppLoadedAction;

export const actionCreators = {
  loadCompleted: () => ({ type: "APP_LOADED" } as AppLoadedAction),
};

const unloadedState: MainState = {
  isAppLoaded: false,
};

export const reducer: Reducer<MainState> = (
  state: MainState | undefined,
  incomingAction: Action
): MainState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "APP_LOADED":
      return {
        ...state,
        isAppLoaded: true,
      };
  }

  return state;
};
