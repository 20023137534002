import * as React from "react";
import "./index.css";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "../../store";
import SelectOrderPageHeader from "./components/header";
import { Settings } from "../../infrastructure/config";
import EatInButton from "./components/eatin-button";
import DeliveryTakeAwayButton from "./components/delivery-takeaway-button";
import ReservationButton from "./components/reservation-button";
import { Row, Col, Container } from "reactstrap";

type OrderSummaryProps = {
  className: string;
} & RCustomerStore.CustomerState &
  RouteComponentProps<{ orderId: string }>;

type OrderSummaryState = {};

class SelectOrderTypePage extends React.Component<
  OrderSummaryProps,
  OrderSummaryState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {};

  componentDidMount = () => {};

  componentDidUpdate = (prevProps: OrderSummaryProps) => {};

  render() {
    const { className } = this.props;

    const deliveryTakeawayText = "Delivery / Takeaway";
    return (
      <div className={`${className} wa-select-order-type-page`}>
        <SelectOrderPageHeader />
        <div className="wa-select-order-type-body">
          <Container>
            <Row>
              <Col>
                <h1 className="wa-select-order-type-title">
                  Welcome to {Settings.restaurant.name}
                </h1>
              </Col>
            </Row>
            <Row className="wa-select-order-type-buttons">
              {deliveryTakeawayText && (
                <Col xs={12} md={6}>
                  <DeliveryTakeAwayButton text={deliveryTakeawayText} />
                </Col>
              )}
              <Col xs={12} md={6}>
                <EatInButton />
              </Col>
              <Col xs={12} md={6}>
                <ReservationButton />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect((state: ApplicationState) => state.customer)(SelectOrderTypePage)
);
