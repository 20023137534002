import * as React from "react";
import "./index.css";
import { RouteComponentProps, withRouter } from "react-router";
import OrderSummaryMessage from "./components/OrderSummaryMessage";
import OrderSummaryBody from "../partial/OrderSummaryBody";
import { OrderService } from "../../services";
import { Order, OrderStatus } from "../../types/order";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "../../store";
import InnerLayout from "../partial/InnerLayout";
import PageLayout from "../partial/PageLayout";

type OrderSummaryProps = {
  className: string;
} & RCustomerStore.CustomerState &
  RouteComponentProps<{ orderId: string }>;

type OrderSummaryState = {
  order: Order;
};

class OrderSummary extends React.Component<
  OrderSummaryProps,
  OrderSummaryState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    order: {} as Order,
  };

  componentDidMount = async () => {
    const { history } = this.props;
    const { order } = this.state;
    if (order === undefined || !order.id) {
      let order = await this.getOrderSummary();
      if (order) {
        this.setState({ order: order });
      } else {
        history.replace("/");
      }
    }
  };

  componentDidUpdate = async (prevProps: OrderSummaryProps) => {
    const { customer, history } = this.props;
    const { order } = this.state;
    if (prevProps.customer !== customer) {
      if (order === undefined || !order.id) {
        let order = await this.getOrderSummary();
        if (order) {
          this.setState({ order: order });
        } else {
          history.replace("/");
        }
      }
    }
  };

  getOrderSummary = async (): Promise<Order | undefined> => {
    const { customer, match } = this.props;
    if (
      customer === undefined ||
      match === undefined ||
      match.params === undefined ||
      match.params.orderId === undefined
    ) {
      return undefined;
    }
    let orderResult = await OrderService.getOrderSummary(
      match.params.orderId,
      customer.id,
      customer.token.token
    );

    if (orderResult.isSuccess) {
      return orderResult.data;
    } else return undefined;
  };

  render() {
    const { className } = this.props;
    const { order } = this.state;
    return (
      order && (
        <PageLayout>
          <InnerLayout
            hideBasket={true}
            className={className + " wa-order-summary"}
          >
            {order.status === OrderStatus.Waiting && <OrderSummaryMessage />}
            <OrderSummaryBody order={order} className="wa-os-body" />
          </InnerLayout>
        </PageLayout>
      )
    );
  }
}

export default withRouter(
  connect((state: ApplicationState) => state.customer)(OrderSummary)
);
