import { Token } from "../token";

export interface Customer {
  id: string;
  fullName: string;
  email: string;
  token: Token;
  type: CustomerType;
  phoneNumber: string;
}

export enum CustomerType {
  None = 0,
  GuestCustomer = 5,
  Customer = 1,
}

export interface CustomerRegister {
  fullName: string;
  email: string;
  password: string;
}

export interface CustomerUpdatePaswordWithToken {
  newPassword: string;
  newPasswordAgain: string;
}

export interface CustomerUpdatePasword {
  oldPassword: string;
  newPassword: string;
  newPasswordAgain: string;
}

export interface UpdateCustomerInfo {
  fullName: string;
  phoneNumber: string;
}
