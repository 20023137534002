import { OrderStatus, OrderType } from "../../types/order";
import { DayOfWeekType } from "../../types/date";
import { PaymentType } from "../../types/payment";

export function getOrderTypeText(orderType: OrderType): string {
  switch (orderType) {
    case OrderType.Delivery:
      return "Delivery";
    case OrderType.EatIn:
      return "Eat-In";
    case OrderType.Takeaway:
      return "Takeaway";
    default:
      return "Not Specified";
  }
}

export function getDayOfWeekText(dayOfWeek: DayOfWeekType): string {
  switch (dayOfWeek) {
    case DayOfWeekType.Sunday:
      return "Sunday";
    case DayOfWeekType.Monday:
      return "Monday";
    case DayOfWeekType.Tuesday:
      return "Tuesday";
    case DayOfWeekType.Wednesday:
      return "Wednesday";
    case DayOfWeekType.Thursday:
      return "Thursday";
    case DayOfWeekType.Friday:
      return "Friday";
    case DayOfWeekType.Saturday:
      return "Saturday";
    default:
      return "Not Specified";
  }
}

export function getPaymentTypeText(
  paymentType: PaymentType,
  orderType: OrderType
): string {
  if (paymentType === PaymentType.Cash) {
    if (orderType === OrderType.EatIn) return "Cash at till";
    else return "Cash on collection";
  } else if (paymentType === PaymentType.CreditCard) {
    if (orderType === OrderType.EatIn) return "Card at till";
    else return "Card on collection";
  } else if (paymentType === PaymentType.SquareUp) {
    return "Card (Online)";
  } else return "";
}

export function getOrderStatusText(orderStatus: OrderStatus): string {
  switch (orderStatus) {
    case OrderStatus.Waiting:
      return "Waiting";
    case OrderStatus.Preparing:
      return "Preparing";
    case OrderStatus.Cancelled:
      return "Cancelled";
    case OrderStatus.Rejected:
      return "Rejected";
    case OrderStatus.Shipped:
      return "On the way";
    case OrderStatus.Returned:
      return "Returned";
    case OrderStatus.Completed:
      return "Completed";
    case OrderStatus.ScheduledWaiting:
      return "Scheduled";
    default:
      return "Not Specified";
  }
}
