import React, { Component } from "react";
import Banner from "../Banner";
import {
  RestaurantOrderTypeStatus,
  RestaurantOrderTypeStatusType,
} from "../../../../types/restaurant";
import { RestaurantService } from "../../../../services";
import { Theme } from "../../../../infrastructure/config";
import { ApplicationState, ROrderStore } from "../../../../store";
import { OrderType } from "../../../../types/order";
import { connect } from "react-redux";

type OrderTypeStatusBannerProps = {} & ROrderStore.OrderState;
type OrderTypeStatusBannerState = {
  orderTypeStatus?: RestaurantOrderTypeStatus;
};

class OrderTypeStatusBanner extends Component<
  OrderTypeStatusBannerProps,
  OrderTypeStatusBannerState
> {
  state = {
    orderTypeStatus: undefined as RestaurantOrderTypeStatus | undefined,
  };

  componentDidMount = async () => {
    await this.getOrderTypeStatus();
  };

  getOrderTypeStatus = async (): Promise<void> => {
    const { selectedOrderType } = this.props;
    if (
      selectedOrderType !== OrderType.Delivery &&
      selectedOrderType !== OrderType.EatIn &&
      selectedOrderType !== OrderType.Takeaway
    )
      return;
    let orderTypeStatusResult = await RestaurantService.getRestaurantOrderTypeStatus(
      selectedOrderType
    );

    const { data } = orderTypeStatusResult;
    this.setState({ orderTypeStatus: data });
  };

  render() {
    const { orderTypeStatus } = this.state;
    return orderTypeStatus && orderTypeStatus.statusMessage ? (
      <Banner
        text={orderTypeStatus.statusMessage.message}
        backgroundColor={
          orderTypeStatus.status === RestaurantOrderTypeStatusType.Busy
            ? Theme.color.restaurantBusy
            : orderTypeStatus.status === RestaurantOrderTypeStatusType.Closed
            ? Theme.color.restaurantClosed
            : Theme.color.secondary
        }
      />
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ROrderStore.OrderState => {
  const { order } = state;
  return { ...order } as ROrderStore.OrderState;
};

export default connect(mapStateToProps)(OrderTypeStatusBanner);
