import React from "react";
import "./index.css";
import { OrderType } from "types/order";
import { motorcycle } from "react-icons-kit/fa/motorcycle";
import { cutlery } from "react-icons-kit/fa/cutlery";
import { angleRight } from "react-icons-kit/fa/angleRight";
import { shoppingBag } from "react-icons-kit/fa/shoppingBag";
import { OrderStore } from "../../../infrastructure/store";
import { Theme } from "infrastructure/config";
import Icon from "react-icons-kit";
import { TextHelper } from "infrastructure/helper";

type ChangeOrderTypeProps = { onPress: () => void };
type ChangeOrderTypeState = {
  isLoaded: boolean;
};

const iconStyles = {
  color: Theme.color.onButtonSecondary,
} as React.CSSProperties;

class ChangeOrderType extends React.Component<
  ChangeOrderTypeProps,
  ChangeOrderTypeState
> {
  componentDidMount = () => {
    this.setState({ isLoaded: true });
  };

  public state = {
    isLoaded: false,
  };

  getText = (): string => {
    let orderType = OrderStore.getOrderType();
    let table = OrderStore.getTable();

    if (orderType === OrderType.Delivery)
      return TextHelper.getOrderTypeText(OrderType.Delivery);
    else if (orderType === OrderType.Takeaway)
      return TextHelper.getOrderTypeText(OrderType.Takeaway);
    else if (orderType === OrderType.EatIn && !(table && table.name))
      return TextHelper.getOrderTypeText(OrderType.EatIn);
    else if (orderType === OrderType.EatIn && table && table.name) {
      return (
        TextHelper.getOrderTypeText(OrderType.EatIn) +
        (table.prefix ? `  ${table.prefix}-${table.name}` : `   ${table.name}`)
      );
    } else return "Select an order type";
  };

  render() {
    const { onPress } = this.props;
    const { isLoaded } = this.state;
    let orderType = OrderStore.getOrderType();

    return isLoaded ? (
      <button
        onClick={(e) => {
          e.preventDefault();
          onPress();
        }}
        className="wa-copg-button"
        style={{
          borderColor: Theme.color.inputBorder,
          backgroundColor: Theme.color.buttonSecondary,
          color: Theme.color.onButtonSecondary,
        }}
      >
        {(orderType === OrderType.Delivery ||
          orderType === OrderType.Takeaway ||
          orderType === OrderType.EatIn) && (
          <Icon
            size={orderType === OrderType.Delivery ? 19 : 16}
            style={iconStyles}
            className="wa-copg-left-icon"
            icon={
              orderType === OrderType.Delivery
                ? motorcycle
                : orderType === OrderType.Takeaway
                ? shoppingBag
                : cutlery
            }
          />
        )}
        <span className="wa-copg-text">{this.getText()}</span>
        <Icon
          size={18}
          style={iconStyles}
          className="wa-copg-right-icon"
          icon={angleRight}
        />
      </button>
    ) : (
      <></>
    );
  }
}

export default ChangeOrderType;
