import * as React from "react";
import "./index.css";
import { Home } from "@material-ui/icons";
import Button from "../../../../components/Button";
import { Theme } from "../../../../infrastructure/config";
import { connect } from "react-redux";
import { ApplicationState, RAddAddressModalStore } from "../../../../store";

type EmptyAddressListProps = {
  className: string;
  onAddAddress: () => void;
} & typeof RAddAddressModalStore.actionCreators &
  RAddAddressModalStore.AddAddressModalState;

class EmptyAddressList extends React.Component<EmptyAddressListProps> {
  public static defaultProps = {
    className: "",
  };

  onClickAddAddressShopping = () => {
    const {
      showAddAddressModal,
      hideAddAddressModal,
      onAddAddress,
    } = this.props;

    showAddAddressModal(hideAddAddressModal, () => {
      onAddAddress();
      hideAddAddressModal();
    });
  };

  render() {
    const { className } = this.props;
    return (
      <div
        className={className + " wa-empty-address-list"}
        style={{ backgroundColor: Theme.color.containerBackground }}
      >
        <Home htmlColor={Theme.color.title} className="wa-eal-icon" />
        <span className="wa-eal-label" style={{ color: Theme.color.title }}>
          There is no address found.
        </span>
        <Button
          text="ADD NEW ADDRESS"
          className="wa-eal-button"
          onClick={this.onClickAddAddressShopping}
        />
      </div>
    );
  }
}

export default connect((state: ApplicationState) => state.addAddressModal, {
  ...RAddAddressModalStore.actionCreators,
})(EmptyAddressList);
