import * as React from "react";
import "./index.css";
import { Restore } from "@material-ui/icons";
import Button from "../../../../components/Button";
import { withRouter, RouteComponentProps } from "react-router";
import { Theme } from "../../../../infrastructure/config";

type EmptyOrderListProps = {
  className: string;
} & RouteComponentProps;

class EmptyOrderList extends React.Component<EmptyOrderListProps> {
  public static defaultProps = {
    className: "",
  };

  onClickStartShopping = () => {
    const { history } = this.props;
    history.push("/");
  };

  render() {
    const { className } = this.props;
    return (
      <div
        className={className + " wa-empty-order-list"}
        style={{ backgroundColor: Theme.color.containerBackground }}
      >
        <Restore htmlColor={Theme.color.title} className="wa-eol-icon" />
        <span className="wa-eol-label" style={{ color: Theme.color.title }}>
          You don't have any order yet.
        </span>
        <Button
          text="START SHOPPING"
          className="wa-eol-button"
          onClick={this.onClickStartShopping}
        />
      </div>
    );
  }
}

export default withRouter(EmptyOrderList);
