import React from "react";
import "./index.css";
import Logo from "../../../../assets/img/logo.png";
import { Container, Row, Col } from "reactstrap";

import Icon from "react-icons-kit";
import { tripadvisor as tripadvisorIcon } from "react-icons-kit/fa/tripadvisor";
import { facebookOfficial } from "react-icons-kit/fa/facebookOfficial";
import { twitter as twitterIcon } from "react-icons-kit/fa/twitter";
import { instagram as instagramIcon } from "react-icons-kit/fa/instagram";
import { Theme, Settings } from "infrastructure/config";

export interface SelectOrderPageHeaderProps {}

const iconStyles = { color: Theme.color.socialMediaIcon };

const { twitter, facebook, instagram, tripadvisor } = Settings.socialMedia;

class SelectOrderPageHeader extends React.Component<
  SelectOrderPageHeaderProps
> {
  public render() {
    return (
      <div className="wa-select-order-type-header">
        <Container style={{ maxWidth: 1600 }}>
          <Row className="wa-header-row">
            <Col xs="auto">
              <img alt="logo" className="wa-welcome-page-logo" src={Logo} />
            </Col>
            <Col xs={{ offset: 0 }}>
              {instagram.link && (
                <a className="wa-header-icon" href={instagram.link}>
                  <Icon size={26} style={iconStyles} icon={instagramIcon} />
                </a>
              )}
              {facebook.link && (
                <a className="wa-header-icon" href={facebook.link}>
                  <Icon size={26} style={iconStyles} icon={facebookOfficial} />
                </a>
              )}
              {twitter.link && (
                <a className="wa-header-icon" href={twitter.link}>
                  <Icon size={26} style={iconStyles} icon={twitterIcon} />
                </a>
              )}
              {tripadvisor.link && (
                <a className="wa-header-icon" href={tripadvisor.link}>
                  <Icon size={26} style={iconStyles} icon={tripadvisorIcon} />
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SelectOrderPageHeader;
