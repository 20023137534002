import * as React from "react";
import "./index.css";
import { Text } from "../../../../infrastructure/config";
import Alert from "../../../../components/Alert";

type AllergyTextProps = {
  className: string;
};

type AllergyTextState = {
  show: boolean;
};
class AllergyText extends React.Component<AllergyTextProps, AllergyTextState> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    show: false,
  };
  render() {
    const { className } = this.props;
    const { show } = this.state;
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          this.setState({ show: true });
        }}
        style={{ color: "#0288D1" }}
        className={className + " wa-allergy-text"}
      >
        <Alert
          bodyText={Text.allergy.description}
          okButtonText={"OK"}
          show={show}
          onPressOk={() => this.setState({ show: false })}
        />
        {Text.allergy.title}
      </button>
    );
  }
}

export default AllergyText;
