import * as LocalStore from "./store";
import { OrderProductGroupType, OrderType } from "../../types/order";
import { Store, SystemStatus } from "../../types/system";
import { RestaurantOrderType, RestaurantTable } from "types/restaurant";
import { RestaurantService } from "services";

let OrderSettingsObject = {
  orderType: OrderType.None,
  selectedTable: undefined,
  activeOrderTypes: [] as RestaurantOrderType[],
} as OrderSettings;

export function getOrderProductGroup(
  orderTypeParam?: OrderType
): OrderProductGroupType {
  let activeOrderTypes = getActiveOrderTypes();
  let orderType = orderTypeParam ? orderTypeParam : getOrderType();
  if (!activeOrderTypes || activeOrderTypes.length <= 0) {
    setTable(undefined);
    return OrderProductGroupType.None;
  }

  let orderTypes = {
    delivery: activeOrderTypes.some(
      (item) => item.orderType === OrderType.Delivery
    ),
    takeaway: activeOrderTypes.some(
      (item) => item.orderType === OrderType.Takeaway
    ),
    eatIn: activeOrderTypes.some((item) => item.orderType === OrderType.EatIn),
  };

  if (orderType === OrderType.Delivery && orderTypes.delivery)
    return OrderProductGroupType.DeliveryTakeaway;
  else if (orderType === OrderType.Takeaway && orderTypes.takeaway)
    return OrderProductGroupType.DeliveryTakeaway;
  else if (orderType === OrderType.EatIn && orderTypes.eatIn)
    return OrderProductGroupType.EatIn;
  else return OrderProductGroupType.None;
}

export function getTable(): RestaurantTable | undefined {
  return OrderSettingsObject &&
    OrderSettingsObject.selectedTable &&
    OrderSettingsObject.orderType === OrderType.EatIn
    ? OrderSettingsObject.selectedTable
    : undefined;
}

export function getOrderCompleteTime(orderType: OrderType): string {
  if (!OrderSettingsObject || !OrderSettingsObject.activeOrderTypes) return "";
  let orderTypeObject = OrderSettingsObject.activeOrderTypes.find(
    (x) => x.orderType === orderType && x.status === SystemStatus.Active
  );
  if (!orderTypeObject) return "";

  return `${orderTypeObject.minCompleteTime} - ${orderTypeObject.maxCompleteTime} mins`;
}

export function getActiveOrderTypes(): RestaurantOrderType[] {
  if (OrderSettingsObject && OrderSettingsObject.activeOrderTypes)
    return OrderSettingsObject.activeOrderTypes;
  else return [];
}

export function showChangeOrderTypeBanner(): boolean {
  let activeOrderTypes = getActiveOrderTypes();
  if (!activeOrderTypes || activeOrderTypes.length <= 0) return false;
  let orderTypes = {
    delivery: activeOrderTypes.some(
      (item) => item.orderType === OrderType.Delivery
    ),
    takeaway: activeOrderTypes.some(
      (item) => item.orderType === OrderType.Takeaway
    ),
    eatIn: activeOrderTypes.some((item) => item.orderType === OrderType.EatIn),
  };
  if (
    (orderTypes.delivery && orderTypes.eatIn) ||
    (orderTypes.takeaway && orderTypes.eatIn) ||
    (orderTypes.delivery && orderTypes.takeaway)
  )
    return true;
  else return false;
}

export function getOrderType(): OrderType {
  return OrderSettingsObject ? OrderSettingsObject.orderType : OrderType.None;
}

export function setOrderType(orderType: OrderType) {
  let orderSettings = {
    selectedTable: getTable(),
    activeOrderTypes: getActiveOrderTypes(),
    orderType: orderType,
  } as OrderSettings;

  OrderSettingsObject = orderSettings;

  saveOrderSettings();
}

export function setTable(table?: RestaurantTable): void {
  let orderSettings = {
    selectedTable: table,
    activeOrderTypes: getActiveOrderTypes(),
    orderType: getOrderType(),
  } as OrderSettings;

  OrderSettingsObject = orderSettings;

  saveOrderSettings();
}

export function isOrderTypeActive(orderType: OrderType): boolean {
  return OrderSettingsObject && OrderSettingsObject.activeOrderTypes
    ? OrderSettingsObject.activeOrderTypes.some(
        (item) =>
          item.status === SystemStatus.Active && item.orderType === orderType
      )
    : false;
}

export function setActiveOrderTypes(
  activeOrderTypes: RestaurantOrderType[]
): void {
  let orderSettings: OrderSettings;
  if (!activeOrderTypes || activeOrderTypes.length <= 0) {
    orderSettings = {
      selectedTable: undefined,
      activeOrderTypes: [] as RestaurantOrderType[],
      orderType: OrderType.None,
    } as OrderSettings;
  } else {
    let orderTypes = {
      delivery: activeOrderTypes.some(
        (item) => item.orderType === OrderType.Delivery
      ),
      takeaway: activeOrderTypes.some(
        (item) => item.orderType === OrderType.Takeaway
      ),
      eatIn: activeOrderTypes.some(
        (item) => item.orderType === OrderType.EatIn
      ),
    };
    let orderType = getOrderType();
    if (
      (!orderTypes.delivery && orderType === OrderType.Delivery) ||
      (!orderTypes.takeaway && orderType === OrderType.Takeaway) ||
      (!orderTypes.eatIn && orderType === OrderType.EatIn)
    ) {
      orderType = OrderType.None;
    }

    if (
      orderTypes.delivery === true &&
      orderTypes.eatIn === false &&
      orderTypes.takeaway === false
    ) {
      orderType = OrderType.Delivery;
    } else if (
      orderTypes.delivery === false &&
      orderTypes.eatIn === true &&
      orderTypes.takeaway === false
    ) {
      orderType = OrderType.EatIn;
    } else if (
      orderTypes.delivery === false &&
      orderTypes.eatIn === false &&
      orderTypes.takeaway === true
    ) {
      orderType = OrderType.Takeaway;
    }

    orderSettings = {
      orderType: orderType,
      selectedTable: getTable(),
      activeOrderTypes: activeOrderTypes,
    } as OrderSettings;
  }

  OrderSettingsObject = orderSettings;

  saveOrderSettings();
}

export async function initializeOrderSettings(): Promise<void> {
  OrderSettingsObject = LocalStore.getData<OrderSettings>(Store.OrderSettings);
  await initializeActiveOrderTypes();
}

async function initializeActiveOrderTypes(): Promise<void> {
  let result = await RestaurantService.getRestaurantOrderTypes();
  if (result.isSuccess) {
    let activeOrderTypes = result.data.filter(
      (type) => type.status === SystemStatus.Active
    );
    setActiveOrderTypes(activeOrderTypes);
  } else {
    setActiveOrderTypes([]);
  }
}

export function saveOrderSettings() {
  LocalStore.saveData(OrderSettingsObject, Store.OrderSettings);
}

interface OrderSettings {
  orderType: OrderType;
  selectedTable?: RestaurantTable;
  activeOrderTypes: RestaurantOrderType[];
}
