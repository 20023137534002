import * as React from "react";
import "./index.css";
import { Home, KeyboardArrowRight, AddCircleOutline } from "@material-ui/icons";
import { Theme } from "../../../../infrastructure/config";
import { CustomerAddress } from "../../../../types/customerAddress";
import OrderSelectAddress from "../../../partial/OrderSelectAddress";
import * as RAddAddressStore from "../../../../store/Modal/addAddress";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../store";

type AddressSelectionProps = {
  className: string;
  message: string;
  selectedAddress: CustomerAddress | undefined;
  onChangeAddress: (address: CustomerAddress) => void;
} & RAddAddressStore.AddAddressModalState &
  typeof RAddAddressStore.actionCreators;

type AddressSelectionState = {
  showAddressList: boolean;
};

class AddressSelection extends React.Component<
  AddressSelectionProps,
  AddressSelectionState
> {
  public static defaultProps = {
    className: "",
  };
  public state = {
    showAddressList: false,
  };

  onClick = () => {
    const {
      selectedAddress,
      showAddAddressModal,
      hideAddAddressModal,
    } = this.props;
    if (
      selectedAddress !== undefined &&
      selectedAddress !== null &&
      selectedAddress.customerFullName
    ) {
      this.setState({ showAddressList: true });
      return;
    } else {
      showAddAddressModal(hideAddAddressModal, (address) => {
        hideAddAddressModal();
        this.onSelectAddress(address);
      });
      return;
    }
  };

  onSelectAddress = (address: CustomerAddress) => {
    const { onChangeAddress } = this.props;
    onChangeAddress(address);
    this.setState({ showAddressList: false });
  };

  render() {
    const { className, selectedAddress, message } = this.props;
    const { showAddressList } = this.state;
    return (
      <div className="wa-address-wrapper">
        <button
          onClick={(e) => {
            e.preventDefault();
            this.onClick();
          }}
          className={
            (className ? `${className} ` : "") + "wa-address-selection"
          }
          style={{
            backgroundColor: Theme.color.containerBackground,
            borderColor: Theme.color.inputBorder,
          }}
        >
          {showAddressList && (
            <OrderSelectAddress
              onSelect={this.onSelectAddress}
              selectedAddress={selectedAddress}
              show={showAddressList}
              onHide={() => this.setState({ showAddressList: false })}
            />
          )}
          {selectedAddress === undefined ? (
            <AddCircleOutline
              fontSize={"large"}
              htmlColor={Theme.color.secondary}
            />
          ) : (
            <Home fontSize={"large"} htmlColor={Theme.color.title} />
          )}

          <div className="wa-address-selection-body">
            {selectedAddress === undefined || selectedAddress === null ? (
              <>
                <span
                  className="wa-address-selection-title"
                  style={{ color: Theme.color.secondary }}
                >
                  {"Add New Address"}
                </span>
                <span
                  className="wa-address-selection-detail"
                  style={{ color: Theme.color.title }}
                >
                  {"You don't have registered address yet"}
                </span>
              </>
            ) : (
              <>
                <span
                  className="wa-address-selection-title"
                  style={{ color: Theme.color.title }}
                >
                  {selectedAddress.addressName}
                </span>
                <span
                  className="wa-address-selection-detail"
                  style={{ color: Theme.color.title }}
                >
                  {selectedAddress.doorNumber} {selectedAddress.postCode}
                </span>
                {message && (
                  <span
                    style={{ color: Theme.color.error }}
                    className="wa-address-selection-message"
                  >
                    {message}
                  </span>
                )}
              </>
            )}
          </div>
          <KeyboardArrowRight
            fontSize={"large"}
            htmlColor={Theme.color.touchableIcon}
          />
        </button>
      </div>
    );
  }
}

export default connect((state: ApplicationState) => state.addAddressModal, {
  ...RAddAddressStore.actionCreators,
})(AddressSelection);
