import * as React from "react";
import "./index.css";
import InnerLayout from "../partial/InnerLayout";
import { Theme } from "../../infrastructure/config";
import { LocationOn, Language, Mail, Phone } from "@material-ui/icons";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore, RMainStore } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { RestaurantInfoStore } from "../../infrastructure/store";
import PageLayout from "../partial/PageLayout";

type ContactUsProps = {
  className: string;
} & RCustomerStore.CustomerState &
  RMainStore.MainState &
  RouteComponentProps;

class ContactUs extends React.Component<ContactUsProps> {
  public static defaultProps = {
    className: "",
  };

  componentDidMount = () => {
    const { isAppLoaded, isSignedIn, history } = this.props;
    if (isAppLoaded === false) return;
    if (isSignedIn === false) history.replace("/");
  };

  componentDidUpdate = () => {
    const { isAppLoaded, isSignedIn, history } = this.props;

    if (isAppLoaded === false) return;
    if (isSignedIn === false) history.replace("/");
  };

  render() {
    const { className } = this.props;
    return (
      <PageLayout>
        <InnerLayout hideBasket={true} className={className + " wa-contact-us"}>
          <span
            className="wa-cu-label"
            style={{ color: Theme.color.onBackground }}
          >
            Contact Us
          </span>

          <div
            className="wa-cu-container"
            style={{ backgroundColor: Theme.color.containerBackground }}
          >
            {RestaurantInfoStore.getAddress() && (
              <div className="wa-cu-item">
                <div className="wa-cu-item-icon-container">
                  <LocationOn
                    className="wa-cu-item-icon"
                    htmlColor={Theme.color.secondary}
                  />
                </div>
                <span className="wa-cu-item-text">
                  <a
                    href={
                      "https://maps.google.com/?q=" +
                      RestaurantInfoStore.getLocation()
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: Theme.color.descriptionDarker }}
                  >
                    {RestaurantInfoStore.getAddress()}
                  </a>
                </span>
              </div>
            )}
            {RestaurantInfoStore.getWebSiteText() && (
              <div className="wa-cu-item">
                <div className="wa-cu-item-icon-container">
                  <Language
                    className="wa-cu-item-icon"
                    htmlColor={Theme.color.secondary}
                  />
                </div>
                <span className="wa-cu-item-text">
                  <a
                    href={RestaurantInfoStore.getWebSite()}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: Theme.color.descriptionDarker }}
                  >
                    {RestaurantInfoStore.getWebSiteText()}
                  </a>
                </span>
              </div>
            )}
            {RestaurantInfoStore.getContactMailText() && (
              <div className="wa-cu-item">
                <div className="wa-cu-item-icon-container">
                  <Mail
                    className="wa-cu-item-icon"
                    htmlColor={Theme.color.secondary}
                  />
                </div>
                <span className="wa-cu-item-text">
                  <a
                    href={"mailto:" + RestaurantInfoStore.getContactMail()}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: Theme.color.descriptionDarker }}
                  >
                    {RestaurantInfoStore.getContactMailText()}
                  </a>
                </span>
              </div>
            )}
            {RestaurantInfoStore.getPhoneText() && (
              <div className="wa-cu-item">
                <div className="wa-cu-item-icon-container">
                  <Phone
                    className="wa-cu-item-icon"
                    htmlColor={Theme.color.secondary}
                  />
                </div>
                <span className="wa-cu-item-text">
                  <a
                    href={"tel:" + RestaurantInfoStore.getPhone()}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: Theme.color.descriptionDarker }}
                  >
                    {RestaurantInfoStore.getPhoneText()}
                  </a>
                </span>
              </div>
            )}
          </div>
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & RMainStore.MainState =>
      ({ ...state.customer, ...state.main } as RCustomerStore.CustomerState &
        RMainStore.MainState)
  )(ContactUs)
);
