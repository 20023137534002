import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import { EmailInput } from "../../../components/Input";
import Button from "../../../components/Button";
import Alert from "../../../components/Alert";
import { Validator } from "../../../infrastructure/helper";
import { CustomerService } from "../../../services";

type ForgetPasswordProps = {
  className: string;
  show: boolean;
  onHide: () => void;
  onSuccess: (email: string) => void;
};

type ForgetPasswordState = {
  showAlert: boolean;
  alertMessage: string;
  isEmailValid: boolean;
  email: string;
  status: Status;
};

enum Status {
  Waiting,
  Success,
  Error,
}
class ForgetPassword extends React.Component<
  ForgetPasswordProps,
  ForgetPasswordState
> {
  public static defaultProps = {
    className: "",
    show: false,
    onSuccess: () => {},
  };

  public state = {
    showAlert: false,
    alertMessage: "",
    isEmailValid: true,
    email: "",
    status: Status.Waiting,
  };

  onEmailTextChange = (value: string) => {
    if (!this.state.isEmailValid)
      this.setState({ isEmailValid: true, email: value });
    else this.setState({ email: value });
  };

  showAlert = (message: string) => {
    this.setState({ showAlert: true, alertMessage: message });
  };

  hideAlert = () => {
    this.setState({ showAlert: false, alertMessage: "" });
    const { status, email } = this.state;
    const { onSuccess } = this.props;
    if (status === Status.Success) {
      onSuccess(email);
    }
  };
  validateInputs = () => {
    let validations = {
      isEmailValid: true,
    };
    const { email } = this.state;
    if (!email || email.trim() === "" || !Validator.isValidEmail(email)) {
      validations.isEmailValid = false;
    }

    if (!validations.isEmailValid)
      this.setState({
        isEmailValid: validations.isEmailValid,
      });

    return validations.isEmailValid;
  };

  onClick = async () => {
    if (!this.validateInputs()) return;
    const { email } = this.state;
    let forgetResult = await CustomerService.forgetPassword(email);

    if (forgetResult.isSuccess) {
      this.setState({ status: Status.Success });
    }

    this.showAlert(forgetResult.message);
  };

  onHide = () => {
    const { onHide } = this.props;
    this.setState({ email: "", isEmailValid: true });
    onHide();
  };

  render() {
    const { className, show } = this.props;
    const { showAlert, alertMessage, isEmailValid, email } = this.state;
    return (
      <ModalContainer
        show={show}
        onHide={this.onHide}
        showHeader={true}
        headerText={"Forget Password"}
        className={className + " wa-forget-password"}
      >
        <div className="wa-center">
          <div className="wa-forget-password-body">
            <Alert
              show={showAlert}
              bodyText={alertMessage}
              okButtonText={"OK"}
              onPressOk={this.hideAlert}
            />
            <EmailInput
              name="em-forget-password"
              label="E-mail"
              invalid={!isEmailValid}
              defaultValue={email}
              onChange={this.onEmailTextChange}
            />
            <Button text="Reset Password" onClick={this.onClick} />
          </div>
        </div>
      </ModalContainer>
    );
  }
}

export default ForgetPassword;
