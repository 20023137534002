import * as React from "react";
import { PaymentType } from "../../types/payment";
import PaymentTypeItem, { PaymentIconType } from "./paymentTypeItem";
import { OrderType } from "../../types/order";
import { TextHelper } from "../../infrastructure/helper";

type OrderPaymentTypeProps = {
  className: string;
  paymentType: PaymentType;
  orderType: OrderType;
  onClick: () => void;
};

class OrderPaymentType extends React.Component<OrderPaymentTypeProps> {
  public static defaultProps = {
    className: "",
    onClick: () => {},
  };

  renderItem = (): JSX.Element => {
    const { paymentType, onClick, className, orderType } = this.props;
    switch (paymentType) {
      case PaymentType.Cash:
        return (
          <Cash onClick={onClick} orderType={orderType} className={className} />
        );
      case PaymentType.CreditCard:
        return (
          <CreditCard
            onClick={onClick}
            orderType={orderType}
            className={className}
          />
        );
      case PaymentType.SquareUp:
        return (
          <OnlineCreditCard
            onClick={onClick}
            orderType={orderType}
            className={className}
          />
        );
      default:
        return (
          <SelectPaymentType
            orderType={orderType}
            onClick={onClick}
            className={className}
          />
        );
    }
  };
  render() {
    return <this.renderItem />;
  }
}

export default OrderPaymentType;

type PaymentTypeProps = {
  onClick: () => void;
  className: string;
  orderType: OrderType;
};
class CreditCard extends React.PureComponent<PaymentTypeProps> {
  render() {
    const { onClick, className, orderType } = this.props;
    return (
      <PaymentTypeItem
        className={className}
        onClick={onClick}
        iconType={PaymentIconType.CreditCard}
        title={TextHelper.getPaymentTypeText(PaymentType.CreditCard, orderType)}
        description={"Pay by card"}
      />
    );
  }
}

class OnlineCreditCard extends React.PureComponent<PaymentTypeProps> {
  render() {
    const { onClick, className, orderType } = this.props;
    return (
      <PaymentTypeItem
        className={className}
        onClick={onClick}
        iconType={PaymentIconType.CreditCard}
        title={TextHelper.getPaymentTypeText(PaymentType.SquareUp, orderType)}
        description={"Online pay by card"}
      />
    );
  }
}
class Cash extends React.PureComponent<PaymentTypeProps> {
  render() {
    const { onClick, className, orderType } = this.props;
    return (
      <PaymentTypeItem
        className={className}
        onClick={onClick}
        iconType={PaymentIconType.AccountBalanceWallet}
        title={TextHelper.getPaymentTypeText(PaymentType.Cash, orderType)}
        description={"Pay by cash"}
      />
    );
  }
}

class SelectPaymentType extends React.PureComponent<PaymentTypeProps> {
  render() {
    const { onClick, className } = this.props;
    return (
      <PaymentTypeItem
        className={className}
        onClick={onClick}
        iconType={PaymentIconType.AccountBalance}
        title={"Select"}
      />
    );
  }
}
