import httpRequest, { HttpRequestOptions } from "./httpRequest";
import { ApiResponse } from "../../types/response";

export default async function apiRequest<T>(
  config: ApiRequestConfig
): Promise<ApiResponse<T>> {
  var defaultConfig: ApiRequestConfig = {
    url: "",
    data: {},
    options: {
      authToken: "",
    } as HttpRequestOptions,
  };
  defaultConfig = Object.assign(defaultConfig, config);
  try {
    let result = await httpRequest<ApiResponse<T>>(
      defaultConfig.url,
      defaultConfig.data,
      defaultConfig.options
    );
    return result;
  } catch (error) {
    return {
      isSuccess: false,
      message: "An error has occurred.",
    } as ApiResponse<T>;
  }
}

export type ApiRequestConfig = {
  url: string;
  data: object | undefined;
  options: HttpRequestOptions;
};
