import * as React from "react";
import "./index.css";
import { Theme } from "../../../../../infrastructure/config";

type MenuItemHeaderProps = {
  text: string;
};
class MenuItemHeader extends React.Component<MenuItemHeaderProps> {
  render() {
    const { text } = this.props;
    return (
      <div
        className="wa-menu-item-header"
        style={{ color: Theme.color.menuSectionHeader }}
      >
        <span>{text}</span>
      </div>
    );
  }
}

export default MenuItemHeader;
