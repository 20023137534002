import * as React from "react";
import "./index.css";
import { Theme } from "../../../infrastructure/config";
import { ShoppingCartService } from "../../../services";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore, ROrderStore } from "../../../store";
import { OrderStore } from "../../../infrastructure/store";
import { ShoppingCartSummary } from "../../../types/shoppingCart";
import { OrderType } from "../../../types/order";
import { TextHelper } from "infrastructure/helper";

type OrderSummaryStickyProps = {
  className: string;
  onSubmit: () => void;
  onOrderFetched: (orderTotal: number) => void;
  buttonText: string;
  deliveryDistanceId: string;
  orderType: OrderType;
  onError: (message: string) => void;
} & RCustomerStore.CustomerState &
  ROrderStore.OrderState;

type OrderSummaryStickyState = {
  shoppingCartSummary: ShoppingCartSummary;
};

class OrderSummarySticky extends React.Component<
  OrderSummaryStickyProps,
  OrderSummaryStickyState
> {
  public static defaultProps = {
    className: "",
    orderType: OrderType.None,
  };

  public state = {
    shoppingCartSummary: {} as ShoppingCartSummary,
  };

  componentDidMount = async () => {
    const { onOrderFetched } = this.props;
    let summary = await this.getShoppingCartSummary();
    if (summary) {
      this.setState({ shoppingCartSummary: summary });
      if (onOrderFetched) onOrderFetched(summary.totalPrice);
    }
  };

  componentDidUpdate = async (prevProps: OrderSummaryStickyProps) => {
    const { customer, deliveryDistanceId, orderType, onOrderFetched } =
      this.props;

    const { shoppingCartSummary } = this.state;
    if (
      (prevProps.customer !== customer &&
        shoppingCartSummary.totalPrice === undefined) ||
      prevProps.deliveryDistanceId !== deliveryDistanceId ||
      prevProps.orderType !== orderType
    ) {
      let summary = await this.getShoppingCartSummary();
      if (summary) {
        this.setState({ shoppingCartSummary: summary });
        if (onOrderFetched) onOrderFetched(summary.totalPrice);
      }
    }
  };

  getShoppingCartSummary = async (): Promise<
    ShoppingCartSummary | undefined
  > => {
    const { customer, deliveryDistanceId, selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    if (customer === undefined) return;
    let summaryResult = await ShoppingCartService.getShoppingCartSummary(
      customer.id,
      deliveryDistanceId || "",
      orderProductGroup,
      selectedOrderType,
      customer.token.token
    );

    if (summaryResult.isSuccess) return summaryResult.data;
    else return undefined;
  };

  onSubmit = () => {
    const { onSubmit, onError } = this.props;
    const { shoppingCartSummary } = this.state;
    if (shoppingCartSummary === undefined) return;
    if (shoppingCartSummary.isApproveAllowed === false) {
      onError(
        `Sorry, the minimum order amount is £${shoppingCartSummary.minOrderAmount.toFixed(
          2
        )} for selected order type.`
      );
      return;
    }
    onSubmit();
  };

  render() {
    const { className, buttonText, orderType } = this.props;
    const { shoppingCartSummary } = this.state;
    return (
      <div
        className={className + " wa-order-summary-sticky"}
        style={{ backgroundColor: Theme.color.primaryLighter }}
      >
        <div className="wa-oss-left">
          {shoppingCartSummary.totalPrice && (
            <>
              {shoppingCartSummary &&
                shoppingCartSummary.isApproveAllowed === false && (
                  <div
                    className="wa-oss-row wa-oss-row-warning"
                    style={{ color: Theme.color.shoppingCartWarning }}
                  >
                    <span className="wa-oss-label">
                      Spend another £
                      {(
                        shoppingCartSummary.minOrderAmount -
                        shoppingCartSummary.totalPrice +
                        shoppingCartSummary.deliveryFee
                      ).toFixed(2)}
                      {" for " +
                        TextHelper.getOrderTypeText(
                          orderType
                        ).toLocaleLowerCase()}
                    </span>
                  </div>
                )}

              <div
                className="wa-oss-row"
                style={{ color: Theme.color.titleDarker }}
              >
                <span className="wa-oss-label">Subtotal</span>
                <span className="wa-oss-value">
                  : £{shoppingCartSummary.subTotalPrice.toFixed(2)}
                </span>
              </div>
              {shoppingCartSummary.campaigns &&
                shoppingCartSummary.campaigns.map((campaign, index) => (
                  <div
                    key={index}
                    className="wa-oss-row"
                    style={{ color: Theme.color.titleDarker }}
                  >
                    <span className="wa-oss-label">{campaign.name}</span>
                    <span className="wa-oss-value">
                      : £{campaign.discountAmount.toFixed(2)}
                    </span>
                  </div>
                ))}
              {shoppingCartSummary.totalDiscountAmountWithoutCampaigns > 0 && (
                <div
                  className="wa-oss-row"
                  style={{ color: Theme.color.titleDarker }}
                >
                  <span className="wa-oss-label">Product discounts</span>
                  <span className="wa-oss-value">
                    : £
                    {shoppingCartSummary.totalDiscountAmountWithoutCampaigns.toFixed(
                      2
                    )}
                  </span>
                </div>
              )}
              {orderType === OrderType.Delivery && (
                <div
                  className="wa-oss-row"
                  style={{ color: Theme.color.titleDarker }}
                >
                  <span className="wa-oss-label">Delivery Fee</span>
                  <span className="wa-oss-value">
                    : £{shoppingCartSummary.deliveryFee.toFixed(2)}
                  </span>
                </div>
              )}
              <div
                className="wa-oss-row total"
                style={{ color: Theme.color.onPrimary }}
              >
                <span className="wa-oss-label">Total</span>
                <span className="wa-oss-value">
                  : £{shoppingCartSummary.totalPrice.toFixed(2)}
                </span>
              </div>
            </>
          )}
        </div>
        <input
          type="button"
          onClick={(e) => {
            e.preventDefault();
            this.onSubmit();
          }}
          className="btn wa-oss-right"
          value={buttonText}
          style={{
            backgroundColor: Theme.color.primaryDarker,
            color: Theme.color.onPrimary,
          }}
        />
      </div>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
    ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
      ROrderStore.OrderState)
)(OrderSummarySticky);
