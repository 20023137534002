import React from "react";
import "./index.css";
import { withRouter, RouteComponentProps } from "react-router";
import Loading from "../../../components/Loading";
import { ApplicationState, RCustomerStore, ROrderStore } from "store";
import { connect } from "react-redux";
import { OrderStore } from "../../../infrastructure/store";
import { RestaurantService } from "../../../services";
import { RestaurantTable } from "../../../types/restaurant";
import { OrderType } from "../../../types/order";
import { Settings } from "../../../infrastructure/config";
import PageLayout from "../../partial/PageLayout";

type EatInTableSelectedProps = {} & ROrderStore.OrderState &
  RCustomerStore.CustomerState &
  typeof ROrderStore.actionCreators &
  RouteComponentProps<{ tableCode: string }>;
type EatInTableSelectedState = {
  showLoading: boolean;
};

class EatInTableSelected extends React.Component<
  EatInTableSelectedProps,
  EatInTableSelectedState
> {
  state = {
    showLoading: true,
  };

  componentDidMount = async () => {
    const { history, selectOrderType } = this.props;
    if (OrderStore.isOrderTypeActive(OrderType.EatIn) === false) {
      history.replace("/");
    }
    var loadedAt = +new Date();
    let that = this;
    setTimeout(async function () {
      if (+new Date() - loadedAt < 2000) {
        selectOrderType(OrderType.EatIn);
        OrderStore.setOrderType(OrderType.EatIn);
        let table = await that.getRestaurantTable();
        OrderStore.setTable(table);
        history.replace("/");
      }
    }, 25);

    // Try launching the app using URL schemes
    try {
      window.open(
        `${Settings.application.deepLink.scheme}://${window.location.hostname}${window.location.pathname}`,
        "_self"
      );
    } catch (error) {}
  };

  getTableCode = (): string | undefined => {
    const { match } = this.props;
    if (
      match === undefined ||
      match.params === undefined ||
      match.params.tableCode === undefined
    )
      return undefined;
    if (match.params.tableCode.trim() === "") return undefined;
    else return match.params.tableCode;
  };

  getRestaurantTable = async (): Promise<RestaurantTable | undefined> => {
    const { customer, match } = this.props;
    if (customer === undefined) return undefined;
    let tableCode = this.getTableCode();

    if (tableCode === undefined) return undefined;

    let response = await RestaurantService.getRestaurantTable(
      customer.token.token,
      match.params.tableCode
    );

    if (response.isSuccess) return response.data;
    else return undefined;
  };

  render() {
    const { showLoading } = this.state;
    return (
      <PageLayout>
        <Loading
          show={showLoading}
          className="wa-eat-in-table-selected"
        ></Loading>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
      ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
        ROrderStore.OrderState),
    { ...ROrderStore.actionCreators }
  )(EatInTableSelected)
);
