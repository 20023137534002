import * as React from "react";
import "./index.css";
import BasketItem from "../BasketItem";
import { Theme } from "../../../../../../infrastructure/config";
import EmptyBasket from "../EmptyBasket";
import { ShoppingCartService } from "../../../../../../services";
import { OrderStore } from "../../../../../../infrastructure/store";
import {
  ApplicationState,
  RShoppingCartStore,
  RCustomerStore,
  ROrderStore,
  RSignInModalStore,
} from "../../../../../../store";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Alert from "../../../../../../components/Alert";
import { ShoppingCart } from "types/shoppingCart";
import { OrderProductGroupType } from "types/order";
import { TextHelper } from "infrastructure/helper";

type BasketProps = {
  className: string;
  useFullHeight: boolean;
  showEmptyBasket: boolean;
  innerContainerClassName: string;
} & RShoppingCartStore.ShoppingCartState &
  ROrderStore.OrderState &
  RCustomerStore.CustomerState &
  RouteComponentProps &
  typeof RSignInModalStore.actionCreators &
  typeof RShoppingCartStore.actionCreators;

type BasketState = {
  showEmptyAlert: boolean;
  showAlert: boolean;
  alertText: string;
};

class Basket extends React.Component<BasketProps, BasketState> {
  public static defaultProps = {
    className: "",
    useFullHeight: false,
    showEmptyBasket: false,
    innerContainerClassName: "",
  };

  public state = {
    showEmptyAlert: false,
    showAlert: false,
    alertText: "",
  };

  componentDidMount = async () => {
    const { updateShoppingCart } = this.props;
    let shoppingCart = await this.getShoppingCart();
    if (shoppingCart) updateShoppingCart(shoppingCart);
  };

  componentDidUpdate = async () => {
    const { shoppingCart, updateShoppingCart } = this.props;
    if (shoppingCart === undefined) {
      let shoppingCart = await this.getShoppingCart();
      if (shoppingCart) updateShoppingCart(shoppingCart);
    }
  };

  getShoppingCart = async (): Promise<ShoppingCart | undefined> => {
    const { customer, selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    if (
      orderProductGroup !== OrderProductGroupType.EatIn &&
      orderProductGroup !== OrderProductGroupType.DeliveryTakeaway
    ) {
      return undefined;
    }
    if (customer !== undefined) {
      let shopppingCartResult = await ShoppingCartService.getShoppingCart(
        customer.id,
        orderProductGroup,
        selectedOrderType,
        customer.token.token
      );
      if (shopppingCartResult.isSuccess) return shopppingCartResult.data;
      else return undefined;
    } else return undefined;
  };

  showAlertModal = (text: string) => {
    this.setState({ showAlert: true, alertText: text });
  };

  onCloseModal = () => {
    this.setState({ showAlert: false, alertText: "" });
  };

  onSuccessSignIn = () => {
    const { history } = this.props;
    history.push("/approve");
  };

  onSubmit = () => {
    const {
      history,
      isSignedIn,
      shoppingCart,
      showSignInModal,
      hideSignInModal,
    } = this.props;
    if (shoppingCart === undefined) return;
    if (shoppingCart.isApproveAllowed === false) {
      this.showAlertModal(
        `Sorry, the minimum order amount is £${shoppingCart.minOrderAmount.toFixed(
          2
        )} for selected order type.`
      );
      return;
    }
    if (isSignedIn) {
      history.push("/approve");
    } else {
      showSignInModal(
        hideSignInModal,
        (email) => {
          hideSignInModal();
          this.onSuccessSignIn();
        },
        ""
      );
    }
  };

  onPressEmptyBasket = async () => {
    const { customer, updateShoppingCart, selectedOrderType } = this.props;
    if (customer === undefined) {
      this.setState({ showEmptyAlert: false });
      return;
    }
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let emptyResult = await ShoppingCartService.emptyShoppingCart(
      customer.id,
      orderProductGroup,
      customer.token.token
    );

    if (emptyResult.isSuccess) {
      let shoppingCart = await this.getShoppingCart();
      if (shoppingCart) updateShoppingCart(shoppingCart);
    }

    this.setState({ showEmptyAlert: false });
  };

  render() {
    const {
      className,
      shoppingCart,
      useFullHeight,
      showEmptyBasket,
      innerContainerClassName,
      selectedOrderType,
    } = this.props;
    const { showEmptyAlert, showAlert, alertText } = this.state;
    return shoppingCart === undefined ||
      shoppingCart.shoppingCartItems === undefined ||
      shoppingCart.shoppingCartItems.length <= 0 ? (
      <EmptyBasket />
    ) : (
      <div className={className}>
        <Alert
          show={showEmptyAlert}
          okButtonText="YES"
          cancelButtonText="NO"
          showCancelButton={true}
          onPressOk={this.onPressEmptyBasket}
          onPressCancel={() => this.setState({ showEmptyAlert: false })}
          bodyText="Are you sure clear the basket?"
        />
        <Alert
          show={showAlert}
          bodyText={alertText}
          okButtonText="OK"
          onPressOk={this.onCloseModal}
        />
        <div
          className={
            "wa-right-area-item-container" +
            (innerContainerClassName ? " " + innerContainerClassName : "")
          }
        >
          {shoppingCart.shoppingCartItems.map((item, index) => (
            <BasketItem key={index} item={item} />
          ))}
          {showEmptyBasket && (
            <button
              onClick={() => this.setState({ showEmptyAlert: true })}
              style={{
                color: Theme.color.onBackground,
                backgroundColor: Theme.color.background,
              }}
              className="wa-raic-empty"
            >
              Clear the basket
            </button>
          )}
        </div>
        <div
          className={
            useFullHeight
              ? "wa-bs-container-full"
              : "wa-basket-summary-container"
          }
        >
          <div
            className="wa-basket-summary"
            style={{
              backgroundColor: Theme.color.primaryLighter,
            }}
          >
            {shoppingCart && shoppingCart.isApproveAllowed === false && (
              <div className="wa-basket-summary-item">
                <span
                  className="wa-basket-summary-item-name wa-bsin-warning"
                  style={{ color: Theme.color.shoppingCartWarning }}
                >
                  Spend another £
                  {(
                    shoppingCart.minOrderAmount -
                    shoppingCart.totalPrice +
                    shoppingCart.deliveryFee
                  ).toFixed(2)}
                  {" for " +
                    TextHelper.getOrderTypeText(
                      selectedOrderType
                    ).toLocaleLowerCase()}
                </span>
              </div>
            )}

            <div className="wa-basket-summary-item">
              <span
                className="wa-basket-summary-item-name"
                style={{ color: Theme.color.titleDarker }}
              >
                Subtotal
              </span>
              <span
                className="wa-basket-summary-item-value"
                style={{ color: Theme.color.titleDarker }}
              >
                : £{shoppingCart.subTotalPrice.toFixed(2)}
              </span>
            </div>
            {(shoppingCart.campaigns && shoppingCart.campaigns.length) > 0 &&
              shoppingCart.campaigns.map((campaign, index) => (
                <div className="wa-basket-summary-item" key={index}>
                  <span
                    className="wa-basket-summary-item-name"
                    style={{ color: Theme.color.titleDarker }}
                  >
                    {campaign.name}
                  </span>
                  <span
                    className="wa-basket-summary-item-value"
                    style={{ color: Theme.color.titleDarker }}
                  >
                    : £{campaign.discountAmount.toFixed(2)}
                  </span>
                </div>
              ))}
            {shoppingCart.totalDiscountAmountWithoutCampaigns > 0 && (
              <div className="wa-basket-summary-item">
                <span
                  className="wa-basket-summary-item-name"
                  style={{ color: Theme.color.titleDarker }}
                >
                  Discount
                </span>
                <span
                  className="wa-basket-summary-item-value"
                  style={{ color: Theme.color.titleDarker }}
                >
                  : £
                  {shoppingCart.totalDiscountAmountWithoutCampaigns.toFixed(2)}
                </span>
              </div>
            )}
            <div
              className="wa-basket-summary-item total"
              style={{
                color: Theme.color.onPrimary,
              }}
            >
              <span className="wa-basket-summary-item-name total">Total</span>
              <span className="wa-basket-summary-item-value total">
                : £{shoppingCart.totalPrice.toFixed(2)}
              </span>
            </div>
          </div>
          <input
            type="button"
            onClick={this.onSubmit}
            className="btn wa-right-area-approve-basket"
            value="APPROVE BASKET"
            style={{
              backgroundColor: Theme.color.primaryDarker,
              color: Theme.color.onPrimary,
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState &
      RShoppingCartStore.ShoppingCartState &
      ROrderStore.OrderState =>
      ({
        ...state.shoppingCart,
        ...state.customer,
        ...state.order,
      } as RCustomerStore.CustomerState &
        RShoppingCartStore.ShoppingCartState &
        ROrderStore.OrderState),
    {
      ...RShoppingCartStore.actionCreators,
      ...RSignInModalStore.actionCreators,
    }
  )(Basket)
);
