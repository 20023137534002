import * as React from "react";
import "./index.css";
import { Theme } from "../../../../../../infrastructure/config";
import { ShoppingCartItem } from "../../../../../../types/shoppingCart";
import ShoppingCartItemDetail from "../../../../../partial/ShoppingCartItemDetail";

type RightAreaBasketItemProps = {
  className: string;
  item: ShoppingCartItem;
};

type RightAreaBasketItemState = {
  showDetail: boolean;
};

class RightAreaBasketItem extends React.Component<
  RightAreaBasketItemProps,
  RightAreaBasketItemState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    showDetail: false,
  };

  onHideDetail = () => {
    this.setState({ showDetail: false });
  };

  onClick = () => {
    this.setState({ showDetail: true });
  };

  render() {
    const { className, item } = this.props;
    const { showDetail } = this.state;
    return (
      <button
        className={className + " wa-basket-item"}
        onClick={(e) => {
          e.preventDefault();
          this.onClick();
        }}
        onDoubleClick={(e) => {
          e.preventDefault();
          this.onClick();
        }}
        style={{
          backgroundColor: Theme.color.containerBackground,
          borderColor: Theme.color.backgroundDarker,
        }}
      >
        {showDetail && (
          <ShoppingCartItemDetail
            id={item.id}
            show={showDetail}
            onHide={this.onHideDetail}
          />
        )}
        <div className="wa-basket-item-quantity-container">
          <span
            className="wa-basket-item-quantity"
            style={{
              borderColor: Theme.color.description,
              color: Theme.color.titleDarker,
            }}
          >
            {item.quantity}
          </span>
        </div>
        <div className="wa-basket-item-detail">
          <span
            className="wa-basket-item-name"
            style={{ color: Theme.color.titleDarker }}
          >
            {item.product.name}
          </span>
          <span
            className="wa-basket-item-description"
            style={{ color: Theme.color.description }}
          >
            {item.displayDescripiton}
          </span>
        </div>
        <div className="wa-basket-item-prices">
          {item.subTotalPrice > item.totalPrice ? (
            <>
              <span
                className="wa-basket-item-subtotal"
                style={{ color: Theme.color.title }}
              >
                £{item.subTotalPrice.toFixed(2)}
              </span>
              <span
                className="wa-basket-item-total"
                style={{ color: Theme.color.button }}
              >
                £{item.totalPrice.toFixed(2)}
              </span>
            </>
          ) : (
            <span
              className="wa-basket-item-total"
              style={{ color: Theme.color.button }}
            >
              £{item.totalPrice.toFixed(2)}
            </span>
          )}
        </div>
      </button>
    );
  }
}

export default RightAreaBasketItem;
