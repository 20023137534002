import React from "react";
import { Settings, Theme } from "../../infrastructure/config";
import { RestaurantInfoStore } from "../../infrastructure/store";
import "./index.css";
import { Container } from "reactstrap";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { Menu, ExitToApp, Home } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  RSignInModalStore,
} from "../../store";
import DownloadAppBanner from "../DownloadAppBanner";
// @ts-ignore-file

type NavMenuProps = {} & RCustomerStore.CustomerState &
  typeof RSignInModalStore.actionCreators &
  RouteComponentProps;

class NavMenu extends React.PureComponent<NavMenuProps> {
  public static defaultProps = {};

  onClickAction = () => {
    const { showSignInModal, hideSignInModal } = this.props;
    showSignInModal(
      hideSignInModal,
      () => {
        hideSignInModal();
      },
      ""
    );
  };

  public render() {
    const { isSignedIn, history, location } = this.props;

    return (
      <header
        className="sticky-top"
        style={{ backgroundColor: Theme.color.primary }}
      >
        {Settings.show.appBanner && (
          <Container>
            <DownloadAppBanner />
          </Container>
        )}

        <div
          className="wa-mn-home-cover"
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          <div
            className="wa-mn-home-cover-bg"
            style={{ backgroundColor: Theme.color.primary }}
          ></div>
          <Container>
            <nav
              className={
                "navbar wa-menu-nav" +
                (Settings.show.appBanner ? " app-banner" : "")
              }
            >
              {isSignedIn || history.location.pathname !== "/" ? (
                <NavLink
                  className="wa-mn-home d-lg-none"
                  to={{ pathname: "/" }}
                  style={{ color: Theme.color.onPrimary }}
                >
                  <Home
                    className="wa-mn-home-icon"
                    htmlColor={Theme.color.onPrimary}
                  />
                </NavLink>
              ) : (
                <a
                  className="wa-mn-home d-lg-none"
                  href={RestaurantInfoStore.getWebSite()}
                  style={{ color: Theme.color.onPrimary }}
                >
                  <Home
                    className="wa-mn-home-icon"
                    htmlColor={Theme.color.onPrimary}
                  />
                </a>
              )}
              <NavLink
                className="wa-mn-title"
                to={{ pathname: "/" }}
                style={{ color: Theme.color.onPrimary }}
              >
                {Settings.restaurant.name}
              </NavLink>
              {isSignedIn && (
                <NavLink
                  to={
                    history.location.pathname === "/menu"
                      ? //@ts-ignore
                        // location.state.from
                        "/"
                      : {
                          pathname: "/menu",
                          state: { from: location.pathname },
                        }
                  }
                  className="wa-mn-menu d-lg-none"
                >
                  <Menu
                    className="wa-mn-menu-icon"
                    htmlColor={Theme.color.onPrimary}
                  />
                </NavLink>
              )}
              {isSignedIn === false && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.onClickAction();
                  }}
                  onDoubleClick={(e) => {
                    e.preventDefault();
                    this.onClickAction();
                  }}
                  className="wa-mn-action d-lg-none"
                >
                  <ExitToApp
                    className="wa-mn-action-icon"
                    htmlColor={Theme.color.onPrimary}
                  />
                </button>
              )}
            </nav>
          </Container>
        </div>
      </header>
    );
  }
}

export default withRouter(
  connect((state: ApplicationState) => state.customer, {
    ...RSignInModalStore.actionCreators,
  })(NavMenu)
);
