import { Action, Reducer } from "redux";
import { CustomerAddress } from "../../types/customerAddress";
export interface AddAddressModalState {
  showAddAddress: boolean;
  onHideAddAddress: () => void;
  onSuccessAddAddress: (address: CustomerAddress) => void;
}

interface ShowModalAction {
  type: "SHOW_ADDADDRESS_MODAL";
  onHide: () => {};
  onSuccess: (address: CustomerAddress) => void;
}
interface HideModalAction {
  type: "HIDE_ADDADDRESS_MODAL";
}

type KnownAction = ShowModalAction | HideModalAction;

export const actionCreators = {
  showAddAddressModal: (
    onHide: () => void,
    onSuccess: (addrerss: CustomerAddress) => void
  ) =>
    ({
      type: "SHOW_ADDADDRESS_MODAL",
      onHide,
      onSuccess,
    } as ShowModalAction),
  hideAddAddressModal: () =>
    ({ type: "HIDE_ADDADDRESS_MODAL" } as HideModalAction),
};

const unloadedState: AddAddressModalState = {
  showAddAddress: false,
  onHideAddAddress: () => {},
  onSuccessAddAddress: () => {},
};

export const addAddressReducer: Reducer<AddAddressModalState> = (
  state: AddAddressModalState | undefined,
  incomingAction: Action
): AddAddressModalState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SHOW_ADDADDRESS_MODAL":
      return {
        showAddAddress: true,
        onHideAddAddress: action.onHide,
        onSuccessAddAddress: action.onSuccess,
      } as AddAddressModalState;
    case "HIDE_ADDADDRESS_MODAL":
      return {
        showAddAddress: false,
        onHideAddAddress: () => {},
        onSuccessAddAddress: () => {},
      } as AddAddressModalState;
  }

  return state;
};
