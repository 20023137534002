import * as React from "react";
import "./index.css";
import { RestaurantTable } from "../../../../types/restaurant";
import OrderSelectTable from "../../../partial/OrderSelectTable";
import { Restaurant, KeyboardArrowRight } from "@material-ui/icons";
import { Theme } from "../../../../infrastructure/config";

type SelectTableProps = {
  onChange: (restaurantTable: RestaurantTable) => void;
  onError: (message: string) => void;
  selectedTable?: RestaurantTable;
};

type SelectTableState = {
  showSelectModal: boolean;
  restaurantTable: RestaurantTable;
};

class SelectTable extends React.Component<SelectTableProps, SelectTableState> {
  public state = {
    restaurantTable: this.props.selectedTable
      ? this.props.selectedTable
      : ({} as RestaurantTable),
    showSelectModal: false,
  };

  onChange = (table: RestaurantTable) => {
    const { onChange } = this.props;
    onChange(table);
    this.setState({ restaurantTable: table, showSelectModal: false });
  };

  toggleShow = () => {
    this.setState({ showSelectModal: true });
  };

  render() {
    const { restaurantTable, showSelectModal } = this.state;
    const { onError } = this.props;
    const { name, prefix } = restaurantTable;

    return (
      <div className="wa-ab-section">
        {!restaurantTable || !restaurantTable.id ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              this.toggleShow();
            }}
            style={{
              backgroundColor: Theme.color.containerBackground,
              borderColor: Theme.color.inputBorder,
            }}
            className="wa-table-select"
          >
            <Restaurant
              fontSize={"large"}
              className="wa-pt-selection-li"
              htmlColor={Theme.color.title}
            />
            <div
              className="wa-table-name"
              style={{ color: Theme.color.secondary }}
            >
              Select your table
            </div>
            <KeyboardArrowRight
              fontSize={"large"}
              className="wa-pt-selection-ri"
              htmlColor={Theme.color.touchableIcon}
            />
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              this.toggleShow();
            }}
            className="wa-table-selected"
            style={{
              backgroundColor: Theme.color.containerBackground,
              borderColor: Theme.color.inputBorder,
            }}
          >
            <Restaurant
              fontSize={"large"}
              className="wa-pt-selection-li"
              htmlColor={Theme.color.title}
            />
            <div className="wa-table-name" style={{ color: Theme.color.title }}>
              {prefix ? `${prefix}-${name}` : name}
            </div>
            <KeyboardArrowRight
              fontSize={"large"}
              className="wa-pt-selection-ri"
              htmlColor={Theme.color.touchableIcon}
            />
          </button>
        )}
        {showSelectModal && (
          <OrderSelectTable
            onHide={() => this.setState({ showSelectModal: false })}
            onError={onError}
            onSelect={this.onChange}
            show={showSelectModal}
          />
        )}
      </div>
    );
  }
}

export default SelectTable;
