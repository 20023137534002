import * as React from "react";
import "./index.css";
import { Theme } from "../../../infrastructure/config";

type InputProps = {
  name?: string;
  className?: string;
  invalid?: boolean;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  inputType: InputType;
  disabled?: boolean;
  maxLength: number;
  onChange?: (text: string) => void;
};

type InputType = "email" | "password" | "number" | "text" | "tel";

class Input extends React.Component<InputProps> {
  public static defaultProps = {
    maxLength: 250,
    inputType: "text",
  };

  render() {
    const {
      className,
      placeholder,
      inputType,
      invalid,
      defaultValue,
      label,
      maxLength,
      onChange,
      disabled,
      name,
    } = this.props;
    return (
      <div className={className + " wa-input-container"}>
        {label && (
          <span
            style={{
              color: !invalid ? Theme.color.title : Theme.color.error,
            }}
            className="wa-input-label"
          >
            {label}
          </span>
        )}
        <input
          name={name}
          maxLength={maxLength}
          disabled={disabled}
          onChange={(event) => onChange && onChange(event.target.value)}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className="wa-input"
          style={{
            borderColor:
              !invalid ? Theme.color.inputBorder : Theme.color.error,
          }}
          type={inputType}
        />
      </div>
    );
  }
}

export default Input;
