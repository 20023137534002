import * as React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import { Theme } from "../infrastructure/config";
import ForgetPassword from "../pages/partial/ForgetPassword";
import { connect } from "react-redux";
import {
  ApplicationState,
  RSignInModalStore,
  RSignUpModalStore,
  RForgetPasswordModalStore,
  RAddAddressModalStore,
} from "../store";
import SignUp from "../pages/partial/SignUp";
import SignInModal from "../pages/partial/SignInModal";
import AddAddress from "../pages/partial/AddAddress";

type LayoutProps = {} & RSignInModalStore.SignInModalState &
  RForgetPasswordModalStore.ForgetPasswordModalState &
  RAddAddressModalStore.AddAddressModalState &
  RSignUpModalStore.SignUpModalState;

class Layout extends React.Component<LayoutProps> {
  public static defaultProps = {};

  render() {
    const {
      children,
      showForgetPassword,
      showSignUp,
      showSignIn,
      showAddAddress,
      email,
      onHideForgetPassword,
      onSuccessForgetPassword,
      onHideSignUp,
      onSuccessSignUp,
      onHideSignIn,
      onSuccessSignIn,
      onHideAddAddress,
      onSuccessAddAddress,
    } = this.props;
    return (
      <React.Fragment>
        <NavMenu />
        {showForgetPassword && (
          <ForgetPassword
            show={showForgetPassword}
            onHide={onHideForgetPassword}
            onSuccess={onSuccessForgetPassword}
          />
        )}

        {showSignIn && (
          <SignInModal
            defaultEmail={email}
            show={showSignIn}
            onHide={onHideSignIn}
            onSuccess={onSuccessSignIn}
          />
        )}

        {showSignUp && (
          <SignUp
            show={showSignUp}
            onHide={onHideSignUp}
            onSuccess={onSuccessSignUp}
          />
        )}

        {showAddAddress && (
          <AddAddress
            show={showAddAddress}
            onHide={onHideAddAddress}
            onSuccess={onSuccessAddAddress}
          />
        )}

        <Container
          style={{ backgroundColor: Theme.color.background }}
          id="container"
        >
          {children}
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RForgetPasswordModalStore.ForgetPasswordModalState &
    RSignUpModalStore.SignUpModalState &
    RSignInModalStore.SignInModalState &
    RAddAddressModalStore.AddAddressModalState =>
    ({
      ...state.forgetPasswordModal,
      ...state.signUpModal,
      ...state.signInModal,
      ...state.addAddressModal,
    } as RForgetPasswordModalStore.ForgetPasswordModalState &
      RSignUpModalStore.SignUpModalState &
      RSignInModalStore.SignInModalState &
      RAddAddressModalStore.AddAddressModalState)
)(Layout);
