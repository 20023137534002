import * as React from "react";
import "./index.css";
import {
  OrderStore,
  RestaurantInfoStore,
} from "../../../../../../../infrastructure/store";
import { OrderType } from "../../../../../../../types/order";
import OrderLimit from "../OrderLimit";

type OrderLimitsProps = {};

class OrderLimits extends React.Component<OrderLimitsProps> {
  render() {
    return (
      <div className="wa-ols">
        {OrderStore.isOrderTypeActive(OrderType.Delivery) && (
          <OrderLimit
            orderType={OrderType.Delivery}
            showDivider={
              OrderStore.isOrderTypeActive(OrderType.EatIn) ||
              OrderStore.isOrderTypeActive(OrderType.Takeaway)
            }
            orderLimit={RestaurantInfoStore.getOrderLimit(OrderType.Delivery)}
            limitsWithDistance={RestaurantInfoStore.getOrderLimitsWithDistance(
              OrderType.Delivery
            )}
          />
        )}
        {OrderStore.isOrderTypeActive(OrderType.Takeaway) && (
          <OrderLimit
            orderType={OrderType.Takeaway}
            showDivider={OrderStore.isOrderTypeActive(OrderType.EatIn)}
            orderLimit={RestaurantInfoStore.getOrderLimit(OrderType.Takeaway)}
          />
        )}
        {OrderStore.isOrderTypeActive(OrderType.EatIn) && (
          <OrderLimit
            orderType={OrderType.EatIn}
            orderLimit={RestaurantInfoStore.getOrderLimit(OrderType.EatIn)}
          />
        )}
      </div>
    );
  }
}

export default OrderLimits;
