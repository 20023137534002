import * as React from "react";
import "./index.css";
import InnerLayout from "../partial/InnerLayout";
import { PasswordInput } from "../../components/Input";
import { Theme } from "../../infrastructure/config";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "../../store";
import { CustomerStore } from "../../infrastructure/store";
import { withRouter, RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import Alert from "../../components/Alert";
import { CustomerService } from "../../services";
import PageLayout from "../partial/PageLayout";

type ChangePasswordProps = {
  className: string;
} & RouteComponentProps &
  RCustomerStore.CustomerState;

type ChangePasswordState = {
  oldPassword: string;
  isOldPasswordValid: boolean;
  newPassword: string;
  isNewPasswordValid: boolean;
  newPasswordAgain: string;
  isNewPasswordAgainValid: boolean;
  showAlert: boolean;
  alertText: string;
  goBack: boolean;
};

class ChangePassword extends React.Component<
  ChangePasswordProps,
  ChangePasswordState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    oldPassword: "",
    newPassword: "",
    newPasswordAgain: "",
    isOldPasswordValid: true,
    isNewPasswordValid: true,
    isNewPasswordAgainValid: true,
    showAlert: false,
    alertText: "",
    goBack: false,
  };

  componentDidMount = () => {
    const { history } = this.props;
    if (CustomerStore.isSignedIn() === false) history.replace("/");
  };

  showAlertModal = (text: string) => {
    this.setState({ showAlert: true, alertText: text });
  };

  onCloseModal = () => {
    this.setState({ showAlert: false, alertText: "" });
    const { goBack } = this.state;
    const { history } = this.props;
    if (goBack === true) history.replace("/profile");
  };

  validateInputs = () => {
    const { oldPassword, newPassword, newPasswordAgain } = this.state;
    let validations = {
      isOldPasswordValid: true,
      isNewPasswordValid: true,
      isNewPasswordAgainValid: true,
    };

    let isValid = true;

    if (!oldPassword || oldPassword.length <= 0) {
      validations.isOldPasswordValid = false;
      isValid = false;
    }

    if (!newPassword || newPassword.length <= 0) {
      validations.isNewPasswordValid = false;
      isValid = false;
    }

    if (!newPasswordAgain || newPasswordAgain.length <= 0) {
      validations.isNewPasswordAgainValid = false;
      isValid = false;
    }

    if (!isValid) {
      this.setState({
        isOldPasswordValid: validations.isOldPasswordValid,
        isNewPasswordValid: validations.isNewPasswordValid,
        isNewPasswordAgainValid: validations.isNewPasswordAgainValid,
      });
    }

    return isValid;
  };

  onPressedUpdate = async () => {
    if (!this.validateInputs()) return;
    const { customer } = this.props;
    if (customer === undefined) return;
    const { oldPassword, newPassword, newPasswordAgain } = this.state;
    let updateResult = await CustomerService.updatePassword(
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordAgain: newPasswordAgain,
      },
      customer.id,
      customer.token.token
    );

    if (updateResult.isSuccess) {
      this.showAlertModal("Your password updated successfully");
      this.setState({ goBack: true });
    } else {
      this.showAlertModal(updateResult.message);
    }
  };

  render() {
    const { className } = this.props;
    const {
      oldPassword,
      isOldPasswordValid,
      newPassword,
      isNewPasswordValid,
      newPasswordAgain,
      isNewPasswordAgainValid,
      alertText,
      showAlert,
    } = this.state;
    return (
      <PageLayout>
        <InnerLayout
          hideBasket={true}
          className={className + " wa-change-password"}
        >
          <Alert
            show={showAlert}
            bodyText={alertText}
            okButtonText="OK"
            onPressOk={this.onCloseModal}
          />
          <span
            className="wa-change-password-label"
            style={{ color: Theme.color.title }}
          >
            Change password:
          </span>
          <div
            className="wa-change-password-detail"
            style={{ backgroundColor: Theme.color.containerBackground }}
          >
            <PasswordInput
              name="pw-change-password-op"
              label="Old Password"
              invalid={!isOldPasswordValid}
              defaultValue={oldPassword}
              onChange={(text) =>
                this.setState({ oldPassword: text, isOldPasswordValid: true })
              }
            />
            <PasswordInput
              name="pw-change-password-np"
              label="New Password"
              invalid={!isNewPasswordValid}
              defaultValue={newPassword}
              onChange={(text) =>
                this.setState({ newPassword: text, isNewPasswordValid: true })
              }
            />
            <PasswordInput
              name="pw-change-password-npc"
              label="New Password (Confirm)"
              invalid={!isNewPasswordAgainValid}
              defaultValue={newPasswordAgain}
              onChange={(text) =>
                this.setState({
                  newPasswordAgain: text,
                  isNewPasswordAgainValid: true,
                })
              }
            />
          </div>
          <Button
            text="UPDATE"
            className="wa-cp-button"
            onClick={this.onPressedUpdate}
          />
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect((state: ApplicationState) => state.customer)(ChangePassword)
);
