import { Theme } from "infrastructure/config";
import { DateTime } from "infrastructure/helper";
import React, { Component } from "react";
import { OrderType } from "types/order";
import "./index.css";

type OrderSummaryCompleteTimeProps = {
  minCompleteTime?: Date;
  maxCompleteTime?: Date;
  orderType: OrderType;
  scheduled?: boolean;
};

class OrderSummaryCompleteTime extends Component<
  OrderSummaryCompleteTimeProps
> {
  getMaxCompleteTime = (): string | undefined => {
    const { maxCompleteTime } = this.props;
    if (!maxCompleteTime) return undefined;
    return `${maxCompleteTime
      .getHours()
      .toString()
      .padStart(2, "0")}:${maxCompleteTime
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  getMinCompleteTime = (): string | undefined => {
    const { minCompleteTime } = this.props;
    if (!minCompleteTime) return undefined;
    return `${minCompleteTime
      .getHours()
      .toString()
      .padStart(2, "0")}:${minCompleteTime
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  getCompleteDateText = (): string | undefined => {
    const { minCompleteTime, maxCompleteTime } = this.props;
    if (!minCompleteTime || !maxCompleteTime) return undefined;

    let dateTime = new Date();
    let nowDateTime = new Date();
    if (maxCompleteTime) {
      dateTime = maxCompleteTime;
    } else {
      dateTime = minCompleteTime;
    }

    if (nowDateTime.getDate() === dateTime.getDate()) return `on today`;
    else return `on ${DateTime.dateString(dateTime)}`;
  };

  getCompleteTimeText = (): string | undefined => {
    let minCompleteTime = this.getMinCompleteTime();
    let maxCompleteTime = this.getMaxCompleteTime();

    if (!minCompleteTime && !maxCompleteTime) return undefined;

    if (minCompleteTime && !maxCompleteTime) return `after ${minCompleteTime}`;
    else if (maxCompleteTime && !minCompleteTime)
      return `by ${maxCompleteTime}`;
    else if (maxCompleteTime && minCompleteTime)
      return `between ${minCompleteTime} - ${maxCompleteTime}`;
    else return undefined;
  };

  getCompleteStatusText = (): string => {
    const { orderType } = this.props;
    switch (orderType) {
      case OrderType.Delivery:
        return "delivered";
      case OrderType.Takeaway:
        return "ready";
      case OrderType.EatIn:
        return "served";
      default:
        return "";
    }
  };

  getCompleteTimeFullText = (): string | undefined => {
    let completeTimeText = this.getCompleteTimeText();
    if (!completeTimeText) return undefined;
    else return completeTimeText;
  };

  render() {
    const { scheduled } = this.props;
    let completeTimeText = this.getCompleteTimeFullText();
    let completeStatusText = this.getCompleteStatusText();
    let completeDateText = this.getCompleteDateText();
    return completeTimeText ? (
      <div
        className="wa-osct-container"
        style={{ backgroundColor: Theme.color.containerBackground }}
      >
        <span className="wa-osct-text" style={{ color: Theme.color.title }}>
          {scheduled
            ? "Your order is scheduled"
            : `Your order will be ${completeStatusText}`}
        </span>
        <span className="wa-osct-time" style={{ color: Theme.color.primary }}>
          {completeTimeText}
        </span>
        {completeTimeText !== undefined && (
          <span className="wa-osct-date" style={{ color: Theme.color.title }}>
            {completeDateText}
          </span>
        )}
      </div>
    ) : (
      <></>
    );
  }
}

export default OrderSummaryCompleteTime;
