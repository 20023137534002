import * as React from "react";
import "./index.css";
import Menu from "../../../../Menu";
import { Theme } from "../../../../../../infrastructure/config";

type LeftMenuProps = {
  className: string;
};

class LeftMenu extends React.Component<LeftMenuProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;
    return (
      <div
        className={className + " wa-left-menu"}
        style={{ borderColor: Theme.color.backgroundDarker }}
      >
        <Menu />
      </div>
    );
  }
}

export default LeftMenu;
