import * as React from "react";
import CashPayment from "./CashPayment";
import { PaymentType, PaymentEnvironment } from "../../types/payment";
import CreditCardPayment from "./CreditCardPayment";
import SquareUpPayment from "./SquareUpPayment";

type PaymentProps = {
  paymentType: PaymentType;
  startPayment: boolean;
  deliveryDistanceId: string;
  orderTotal: number;
  environment: PaymentEnvironment;
  onComplete: (orderId?: string, paymentHistoryId?: string) => void;
  onError: () => void;
  onCancel: () => void;
};

class Payment extends React.Component<PaymentProps> {
  constructor(props: PaymentProps) {
    super(props);
    this.renderPayment = this.renderPayment.bind(this);
  }

  renderPayment() {
    const {
      paymentType,
      startPayment,
      onComplete,
      onError,
      onCancel,
      environment,
      orderTotal,
      deliveryDistanceId,
    } = this.props;
    switch (paymentType) {
      case PaymentType.Cash:
        return (
          <CashPayment
            environment={environment}
            startPayment={startPayment}
            onCancel={onCancel}
            onComplete={onComplete}
            onError={onError}
          />
        );
      case PaymentType.CreditCard:
        return (
          <CreditCardPayment
            environment={environment}
            startPayment={startPayment}
            onCancel={onCancel}
            onComplete={onComplete}
            onError={onError}
          />
        );
      case PaymentType.SquareUp:
        return (
          <SquareUpPayment
            environment={environment}
            deliveryDistanceId={deliveryDistanceId}
            startPayment={startPayment}
            orderTotal={orderTotal}
            onCancel={onCancel}
            onComplete={onComplete}
            onError={onError}
          />
        );
      default:
        return <></>;
    }
  }

  render() {
    return <this.renderPayment />;
  }
}

export default Payment;
