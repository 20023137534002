import "./index.css";
import React, { Component } from "react";
import ModalContainer from "../../../components/ModalContainer";
import {
  OrderAvailableDate,
  OrderSelectedAvailableTime,
  OrderType,
} from "../../../types/order";
import { RestaurantService } from "../../../services";
import { Theme } from "../../../infrastructure/config";
import { AccessTime } from "@material-ui/icons";
import { Button, ButtonGroup } from "reactstrap";
import { ObjectHelper } from "../../../infrastructure/helper";

type OrderSelectTimeProps = {
  className?: string;
  show: boolean;
  asap: boolean;
  orderType: OrderType;
  onHide: () => void;
  onSelect: (scheduleTime?: OrderSelectedAvailableTime) => void;
};

type OrderSelectTimeState = {
  showLoading: boolean;
  availableDates: OrderAvailableDate[];
  selectedDate?: OrderAvailableDate;
  selectedDateIndex: number;
};
class OrderSelectTime extends Component<
  OrderSelectTimeProps,
  OrderSelectTimeState
> {
  state = {
    showLoading: true,
    availableDates: [] as OrderAvailableDate[],
    selectedDate: undefined as OrderAvailableDate | undefined,
    selectedDateIndex: 0,
  };

  componentDidMount = async () => {
    let availableDates = await this.getRestaurantScheduledOrderAvailableTimes();
    let selectedDate =
      availableDates && availableDates.length > 0
        ? availableDates[0]
        : undefined;
    this.setState({
      showLoading: false,
      availableDates: availableDates,
      selectedDate: selectedDate,
    });
  };

  getRestaurantScheduledOrderAvailableTimes = async (): Promise<
    OrderAvailableDate[]
  > => {
    const { orderType } = this.props;
    let result = await RestaurantService.getRestaurantScheduledOrderAvailableTimes(
      orderType
    );
    const { isSuccess, data } = result;
    if (isSuccess && data) {
      return data.filter(
        (item) => item.availableTimes && item.availableTimes.length > 0
      );
    } else return [] as OrderAvailableDate[];
  };

  updateSelectedDate = (index: number) => {
    const { availableDates } = this.state;
    let selectedDate =
      availableDates && availableDates.length >= index
        ? availableDates[index]
        : undefined;
    this.setState({ selectedDate: selectedDate, selectedDateIndex: index });
  };

  onSelectScheduleTime = (scheduleTime?: OrderSelectedAvailableTime) => {
    const { onSelect } = this.props;

    onSelect && onSelect(scheduleTime);
  };

  render() {
    const { asap, show, className, onHide } = this.props;
    const {
      showLoading,
      availableDates,
      selectedDate,
      selectedDateIndex,
    } = this.state;
    let availableTimes = selectedDate ? selectedDate.availableTimes : undefined;
    return (
      <ModalContainer
        show={show}
        className={(className ? `${className} ` : "") + "wa-order-select-time"}
        showHeader={true}
        onHide={onHide}
        showLoading={showLoading}
        headerText={"Schedule Order"}
      >
        <div className="wa-osa-asap-container">
          <span
            className="wa-osa-asap-text"
            style={{ color: Theme.color.descriptionDarker }}
          >
            Now
          </span>
          <ListItem
            disabled={!asap}
            label="ASAP (As soon as possible)"
            description={
              !asap ? "This option is not available for now" : undefined
            }
            onClick={() => {
              this.onSelectScheduleTime();
            }}
          />
        </div>
        {availableDates && availableDates.length > 0 && (
          <>
            <span
              className="wa-osa-asap-text"
              style={{ color: Theme.color.descriptionDarker }}
            >
              Schedule
            </span>
            <div
              style={{ backgroundColor: Theme.color.containerBackground }}
              className="wa-osa-select-day-container"
            >
              <span
                className="wa-osa-select-day-text"
                style={{ color: Theme.color.title }}
              >
                Select a day:
              </span>
              <ButtonGroup className="wa-osa-day-container">
                {availableDates.map((date, index) => (
                  <Button
                    style={
                      index === selectedDateIndex
                        ? {
                            color: Theme.color.onButton,
                            backgroundColor: Theme.color.button,
                            borderColor: Theme.color.button,
                          }
                        : {
                            color: Theme.color.passive,
                            backgroundColor: Theme.color.containerBackground,
                            borderColor: Theme.color.passive,
                          }
                    }
                    className="wa-osa-day"
                    onClick={() => this.updateSelectedDate(index)}
                    active={index === selectedDateIndex}
                    key={index}
                  >
                    {date.text}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <div className="wa-ost-times-container">
              {availableTimes ? (
                ObjectHelper.chunk(availableTimes, 2).map((times, i) => (
                  <div className="wa-ost-times-row" key={i}>
                    {times.map((time, j) => (
                      <ListItem
                        key={j}
                        label={time.text}
                        onClick={() =>
                          this.onSelectScheduleTime({
                            dayText: selectedDate ? selectedDate.text : "",
                            timeText: time.text,
                            maxCompleteTime: time.maxCompleteTime,
                            minCompleteTime: time.minCompleteTime,
                          })
                        }
                      />
                    ))}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </ModalContainer>
    );
  }
}

export default OrderSelectTime;

type ListItemProps = {
  className?: string;
  label: string;
  description?: string;
  onClick: () => void;
  disabled?: boolean;
};
class ListItem extends Component<ListItemProps> {
  render() {
    const { onClick, className, label, disabled, description } = this.props;
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) onClick();
        }}
        className={
          (className ? `${className} ` : "") +
          "wa-ost-list-item" +
          (disabled ? " disabled" : "")
        }
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        <AccessTime
          fontSize={"large"}
          htmlColor={disabled ? Theme.color.description : Theme.color.title}
        />
        <div className="wa-ost-list-item-container">
          <span
            className="wa-ost-list-item-text"
            style={{
              color: disabled ? Theme.color.description : Theme.color.title,
            }}
          >
            {label}
          </span>
          {description && (
            <span
              className="wa-ost-list-item-description"
              style={{ color: Theme.color.error }}
            >
              {description}
            </span>
          )}
        </div>
      </button>
    );
  }
}
