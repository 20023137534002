import * as React from "react";
import "./index.css";
import { Remove, Add } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import { Product } from "../../../../../types/product";

type ProductDetailInfoProps = {
  className: string;
  product: Product;
  extraTotal: number;
  quantity: number;
  onQuantityChange: (quantity: number) => void;
};

type ProductDetailInfoState = {
  quantity: number;
};

class ProductDetailInfo extends React.Component<
  ProductDetailInfoProps,
  ProductDetailInfoState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    quantity: this.props.quantity,
  };

  totalPrice = () => {
    const { product, extraTotal } = this.props;
    const { quantity } = this.state;
    if (product && product.price >= 0) {
      return (quantity * (product.price + extraTotal)).toFixed(2);
    }
  };

  totalDiscountedPrice = () => {
    const { product, extraTotal } = this.props;
    const { quantity } = this.state;
    if (product && product.price >= 0) {
      if (product.discountedPrice && product.discountedPrice < product.price) {
        return (quantity * (product.discountedPrice + extraTotal)).toFixed(2);
      } else {
        return (quantity * (product.price + extraTotal)).toFixed(2);
      }
    }
  };

  decreaseQuantity = () => {
    const { quantity } = this.state;
    const { onQuantityChange } = this.props;
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
      this.setState({ quantity: quantity - 1 });
    }
  };

  increaseQuantity = () => {
    const { quantity } = this.state;
    const { onQuantityChange } = this.props;
    if (quantity < 10) {
      onQuantityChange(quantity + 1);
      this.setState({ quantity: quantity + 1 });
    }
  };
  render() {
    const { product } = this.props;
    const { quantity } = this.state;
    return (
      <div
        className="wa-porudct-detail-container"
        style={{
          backgroundColor: Theme.color.containerBackground,
          borderColor: Theme.color.backgroundDarker,
        }}
      >
        <div
          className="wa-product-detail"
          style={{ borderColor: Theme.color.background }}
        >
          <span
            className="wa-product-detail-name"
            style={{ color: Theme.color.title }}
          >
            {product.name}
          </span>
          <span
            className="wa-product-detail-description"
            style={{ color: Theme.color.descriptionDarker }}
          >
            {product.description}
          </span>
        </div>
        <div className="wa-product-quantity-amount-container">
          <div className="wa-product-quantity-container">
            <button
              onClick={this.decreaseQuantity}
              className="wa-product-quantity-button"
              style={{
                color: Theme.color.title,
                backgroundColor: Theme.color.containerBackground,
              }}
            >
              <Remove style={{ color: Theme.color.title }} />
            </button>
            <span
              className="wa-product-quantity"
              style={{ color: Theme.color.title }}
            >
              Quantity: {quantity}
            </span>
            <button
              onClick={this.increaseQuantity}
              className="wa-product-quantity-button"
              style={{
                color: Theme.color.title,
                backgroundColor: Theme.color.containerBackground,
              }}
            >
              <Add style={{ color: Theme.color.title }} />
            </button>
          </div>
          <div className="wa-product-price-container">
            {product.discountedPrice !== undefined &&
            product.discountedPrice !== null &&
            product.discountedPrice < product.price ? (
              <>
                <span
                  className="wa-product-detail-price-subtotal"
                  style={{ color: Theme.color.title }}
                >
                  £{this.totalPrice()}
                </span>
                <span
                  className="wa-product-detail-price-total"
                  style={{ color: Theme.color.secondary }}
                >
                  £{this.totalDiscountedPrice()}
                </span>
              </>
            ) : (
              <span
                className="wa-product-detail-price-total"
                style={{ color: Theme.color.secondary }}
              >
                £{this.totalDiscountedPrice()}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductDetailInfo;
