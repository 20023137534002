import * as React from "react";
import "./index.css";
import LeftArea from "./components/LeftArea";
import RightArea from "./components/RightArea";
import Loading from "components/Loading";

type InnerLayoutProps = {
  className: string;
  hideBasket: boolean;
  showLoading: boolean;
};

class InnerLayout extends React.Component<InnerLayoutProps> {
  public static defaultProps = {
    className: "",
    hideBasket: false,
    showLoading: false,
  };

  render() {
    const { className, children, hideBasket, showLoading } = this.props;
    return (
      <div className="wa-inner-layout-container">
        <LeftArea className="wa-inner-layout-left-area d-none d-lg-block" />
        <Loading
          className={
            "wa-inner-layout-center-area" + (className ? " " + className : "")
          }
          show={showLoading}
        >
          {children}
        </Loading>
        {hideBasket ? (
          <div className="wa-inner-layout-right-area d-none d-lg-block"></div>
        ) : (
          <RightArea className="wa-inner-layout-right-area d-none d-lg-block" />
        )}
      </div>
    );
  }
}

export default InnerLayout;
