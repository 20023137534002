import * as React from "react";
import "./index.css";
import Input from "../BaseInput";

type PhoneNumberInputProps = {
  className?: string;
  invalid?: boolean;
  defaultValue?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (text: string) => void;
};

class PhoneNumberInput extends React.Component<PhoneNumberInputProps> {
  render() {
    const {
      className,
      label,
      defaultValue,
      onChange,
      invalid,
      disabled,
      placeholder,
    } = this.props;
    return (
      <Input
        maxLength={11}
        label={label}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        onChange={onChange}
        invalid={invalid}
        inputType={"tel"}
      />
    );
  }
}

export default PhoneNumberInput;
