import * as React from "react";
import "./index.css";
import { CustomerAddress } from "../../../../types/customerAddress";
import AddressDetail from "../../../partial/AddressDetail";
import ModalContainer from "../../../../components/ModalContainer";

type EditAddressProps = {
  className: string;
  show: boolean;
  address: CustomerAddress;
  onHide: () => void;
  onSave: (address: CustomerAddress) => void;
  onDelete: (id: string) => void;
};

class EditAddress extends React.Component<EditAddressProps> {
  public static defaultProps = {
    className: "",
    show: false,
  };

  render() {
    const { className, address, show, onHide, onSave, onDelete } = this.props;
    return (
      <ModalContainer
        show={show}
        headerText="Edit Address"
        showHeader={true}
        className="wa-edit-address"
        onHide={onHide}
      >
        <AddressDetail
          className={className}
          addressDetails={address.addressDescription}
          addressName={address.addressName}
          customerFullName={address.customerFullName}
          doorNumber={address.doorNumber}
          id={address.id}
          isDefault={address.isDefault}
          phoneNumber={address.phoneNumber}
          postCode={address.postCode}
          onDelete={onDelete}
          onSave={onSave}
          showDeleteButton={true}
        />
      </ModalContainer>
    );
  }
}

export default EditAddress;
