import * as React from "react";
import "./index.css";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  ROrderStore,
  RShoppingCartStore,
} from "../../../store";
import {
  CustomerStore,
  RestaurantInfoStore,
  OrderStore,
} from "../../../infrastructure/store";
import { Theme } from "../../../infrastructure/config";
import {
  Person,
  ContactPhone,
  Restore,
  Store,
  Home,
  ImportContacts,
  KeyboardReturn,
} from "@material-ui/icons";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { ShoppingCartService } from "../../../services";
import Alert from "../../../components/Alert";
import { ShoppingCart } from "types/shoppingCart";

type MenuProps = {
  className: string;
} & RCustomerStore.CustomerState &
  ROrderStore.OrderState &
  RouteComponentProps &
  typeof RShoppingCartStore.actionCreators &
  typeof RCustomerStore.actionCreators;

type MenuState = {
  showAlert: boolean;
};
class Menu extends React.Component<MenuProps, MenuState> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    showAlert: false,
  };

  onClickSignOut = () => {
    this.setState({ showAlert: true });
  };

  signOut = async () => {
    const { updateShoppingCart, signOut, signInGuest, history } = this.props;
    CustomerStore.signOut();
    signOut();
    let guestCustomer = CustomerStore.getCustomer();
    signInGuest(guestCustomer);
    let shoppingCart = await this.getShoppingCart(
      guestCustomer.id,
      guestCustomer.token.token
    );

    if (shoppingCart) updateShoppingCart(shoppingCart);

    history.replace("/");
  };

  getShoppingCart = async (
    customerId: string,
    token: string
  ): Promise<ShoppingCart | undefined> => {
    const { selectedOrderType } = this.props;

    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let shoppingCartResult = await ShoppingCartService.getShoppingCart(
      customerId,
      orderProductGroup,
      selectedOrderType,
      token
    );

    if (shoppingCartResult.isSuccess) return shoppingCartResult.data;
    else return undefined;
  };

  render() {
    const { className, customer, history } = this.props;
    const { showAlert } = this.state;
    let customerName = customer ? customer.fullName : "";
    let activePage =
      history.location.state && history.location.state.from
        ? history.location.state.from
        : history.location.pathname;
    // "";
    return (
      <div className={className + " wa-menu"}>
        <Alert
          bodyText="Do you want to sign out?"
          okButtonText="YES"
          cancelButtonText="NO"
          showCancelButton={true}
          show={showAlert}
          onPressOk={this.signOut}
          onPressCancel={() => this.setState({ showAlert: false })}
        />
        <div
          className="wa-menu-header"
          style={{
            backgroundColor: Theme.color.primary,
            color: Theme.color.onPrimary,
          }}
        >
          <span className="wa-menu-header-label">Welcome&nbsp;</span>
          <span className="wa-menu-header-value">{customerName}</span>
        </div>
        <div className="wa-menu-body">
          <MenuItem
            activePage={activePage}
            to="/"
            className="d-none d-sm-block"
            iconType="home"
            text="Home"
          />
          <MenuItem
            activePage={activePage}
            to="/profile"
            iconType="person"
            text="Profile"
          />
          <MenuItem
            activePage={activePage}
            to="/address-list"
            iconType="contact"
            text="Address List"
          />
          <MenuItem
            activePage={activePage}
            to="/order-history"
            iconType="restore"
            text="Order History"
          />
          <MenuItem
            activePage={activePage}
            to="/about-us"
            iconType="store"
            text="About Us"
          />
          <MenuItem
            activePage={activePage}
            to="/contact-us"
            iconType="contact-phone"
            text="Contact Us"
          />
          <MenuItemExternal
            activePage={activePage}
            href={RestaurantInfoStore.getWebSite()}
            iconType="return"
            text="Restaurant Page"
          />
          <div
            className="wa-menu-sign-out"
            style={{ backgroundColor: Theme.color.containerBackground }}
          >
            <input
              type="button"
              value="Sign Out"
              className="wa-menu-sign-out-button"
              onClick={this.onClickSignOut}
              style={{
                backgroundColor: Theme.color.containerBackground,
                color: Theme.color.signOut,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

type MenuItemProps = {
  activePage: string;
  target: string;
  href: string;
  className: string;
  text: string;
  iconType:
    | "person"
    | "contact-phone"
    | "restore"
    | "store"
    | "home"
    | "contact"
    | "return";
  to: string;
};

const personIcon = (
  <Person className="wa-menu-item-icon" htmlColor={Theme.color.secondary} />
);

const contactUsIcon = (
  <ContactPhone
    className="wa-menu-item-icon"
    htmlColor={Theme.color.contactUsIcon}
  />
);

const orderHistoryIcon = (
  <Restore
    className="wa-menu-item-icon"
    htmlColor={Theme.color.orderHistoryIcon}
  />
);

const backToRestaurantIcon = (
  <KeyboardReturn
    className="wa-menu-item-icon"
    htmlColor={Theme.color.orderHistoryIcon}
  />
);

const aboutUsIcon = (
  <Store className="wa-menu-item-icon" htmlColor={Theme.color.aboutUsIcon} />
);

const homeIcon = (
  <Home className="wa-menu-item-icon" htmlColor={Theme.color.error} />
);

const addressListIcon = (
  <ImportContacts
    className="wa-menu-item-icon"
    htmlColor={Theme.color.addressesIcon}
  />
);

class MenuItemExternal extends React.Component<MenuItemProps> {
  public static defaultProps = {
    className: "",
    target: undefined,
    href: undefined,
    to: undefined,
  };

  render() {
    const { className, text, iconType, target, href, activePage } = this.props;
    return (
      <a
        href={href}
        target={target}
        className={className + " wa-menu-menu-item"}
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        {iconType === "person"
          ? personIcon
          : iconType === "contact-phone"
          ? contactUsIcon
          : iconType === "restore"
          ? orderHistoryIcon
          : iconType === "store"
          ? aboutUsIcon
          : iconType === "contact"
          ? addressListIcon
          : iconType === "home"
          ? homeIcon
          : iconType === "return"
          ? backToRestaurantIcon
          : undefined}
        <span
          className="wa-menu-item-text"
          style={{ color: Theme.color.title }}
        >
          {text}
        </span>
        {activePage === href && (
          <span
            className="wa-menu-item-active"
            style={{ backgroundColor: Theme.color.primary }}
          ></span>
        )}
      </a>
    );
  }
}

class MenuItem extends React.Component<MenuItemProps> {
  public static defaultProps = {
    className: "",
    target: undefined,
    href: undefined,
  };

  render() {
    const { className, text, to, iconType, target, activePage } = this.props;
    return (
      <NavLink
        to={to}
        target={target}
        className={className + " wa-menu-menu-item"}
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        {iconType === "person"
          ? personIcon
          : iconType === "contact-phone"
          ? contactUsIcon
          : iconType === "restore"
          ? orderHistoryIcon
          : iconType === "store"
          ? aboutUsIcon
          : iconType === "contact"
          ? addressListIcon
          : iconType === "home"
          ? homeIcon
          : iconType === "return"
          ? backToRestaurantIcon
          : undefined}
        <span
          className="wa-menu-item-text"
          style={{ color: Theme.color.title }}
        >
          {text}
        </span>
        {activePage === to && (
          <span
            className="wa-menu-item-active"
            style={{ backgroundColor: Theme.color.primary }}
          ></span>
        )}
      </NavLink>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
      ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
        ROrderStore.OrderState),
    {
      ...RShoppingCartStore.actionCreators,
      ...RCustomerStore.actionCreators,
    }
  )(Menu)
);
