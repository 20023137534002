import * as React from "react";
import "./index.css";
import { withRouter, RouteComponentProps } from "react-router";
import { CustomerService } from "../../services";
import { Settings, Theme } from "../../infrastructure/config";
import {
  ErrorOutline,
  CheckCircleOutline,
  HourglassEmpty,
} from "@material-ui/icons";
import Button from "../../components/Button";
import { HomePageState } from "../../types/system";
import PageLayout from "../partial/PageLayout";

type ApproveAccountProps = {
  className?: string;
} & RouteComponentProps<{ token: string }>;

type ApproveAccountState = {
  isLoaded: boolean;
  email?: string;
  status: Status;
  message: string;
};

enum Status {
  Waiting,
  Error,
  Success,
}

class ApproveAccount extends React.Component<
  ApproveAccountProps,
  ApproveAccountState
> {
  state = {
    isLoaded: false,
    status: Status.Waiting,
    message: "Activating your account. Please wait...",
    email: undefined as string | undefined,
  };

  componentDidMount = async () => {
    const { match } = this.props;
    if (match.params.token && match.params.token !== "") {
      let approveResult = await CustomerService.approveCustomer(
        match.params.token
      );

      if (approveResult.isSuccess) {
        this.setState({
          status: Status.Success,
          message: approveResult.message,
          email: approveResult.data,
          isLoaded: true,
        });
      } else {
        this.setState({
          status: Status.Error,
          message: approveResult.message,
          isLoaded: true,
        });
      }
    } else
      this.setState({
        status: Status.Error,
        message: "Token is invalid.",
        isLoaded: true,
      });
  };

  onHomeClick = () => {
    const { history } = this.props;
    const { status, email } = this.state;

    var loadedAt = +new Date();
    setTimeout(async function () {
      if (+new Date() - loadedAt < 2000) {
        history.replace("/", {
          openModal: status === Status.Success,
          email: email,
        } as HomePageState);
      }
    }, 25);

    if (status === Status.Success) {
      // Try launching the app using URL schemes
      try {
        window.open(
          `${Settings.application.deepLink.scheme}://${
            window.location.hostname
          }/sign-in/${email ? email : ""}`,
          "_self"
        );
      } catch (error) {}
    }
  };

  render() {
    const { className } = this.props;
    const { isLoaded, message, status } = this.state;
    return (
      <PageLayout>
        <div
          className={className + " wa-approve-account"}
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          {status === Status.Error && (
            <ErrorOutline
              htmlColor={Theme.color.error}
              className={"wa-ac-icon"}
            />
          )}

          {status === Status.Success && (
            <CheckCircleOutline
              htmlColor={Theme.color.success}
              className={"wa-ac-icon"}
            />
          )}

          {status === Status.Waiting && (
            <HourglassEmpty
              htmlColor={Theme.color.title}
              className={"wa-ac-icon"}
            />
          )}

          <span
            className="wa-ac-text"
            style={
              status === Status.Success
                ? successStyle
                : status === Status.Error
                ? errorStyle
                : waitingStyle
            }
          >
            {message}
          </span>
          {isLoaded && (
            <Button
              text="HOME PAGE"
              className="wa-ac-button"
              onClick={this.onHomeClick}
              color="success"
            />
          )}
        </div>
      </PageLayout>
    );
  }
}

const successStyle: React.CSSProperties = {
  color: Theme.color.success,
};

const errorStyle: React.CSSProperties = {
  color: Theme.color.error,
};

const waitingStyle: React.CSSProperties = {
  color: Theme.color.title,
};

export default withRouter(ApproveAccount);
