import * as Customer from "./Customer";
import * as ShoppingCart from "./ShoppingCart";
import * as Main from "./Main";
import * as Order from "./Order";
import * as SignUpModal from "./Modal/signUp";
import * as SignInModal from "./Modal/signIn";
import * as ForgetPasswordModal from "./Modal/forgetPassword";
import * as AddAddressModal from "./Modal/addAddress";

// The top-level state object
export interface ApplicationState {
  customer: Customer.CustomerState | undefined;
  main: Main.MainState | undefined;
  order: Order.OrderState | undefined;
  shoppingCart: ShoppingCart.ShoppingCartState | undefined;
  signInModal: SignInModal.SignInModalState | undefined;
  signUpModal: SignUpModal.SignUpModalState | undefined;
  forgetPasswordModal: ForgetPasswordModal.ForgetPasswordModalState | undefined;
  addAddressModal: AddAddressModal.AddAddressModalState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  shoppingCart: ShoppingCart.reducer,
  customer: Customer.reducer,
  main: Main.reducer,
  order: Order.reducer,
  signInModal: SignInModal.signInReducer,
  signUpModal: SignUpModal.signUpReducer,
  forgetPasswordModal: ForgetPasswordModal.forgetPasswordReducer,
  addAddressModal: AddAddressModal.addAddressReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export {
  Customer as RCustomerStore,
  ShoppingCart as RShoppingCartStore,
  Main as RMainStore,
  Order as ROrderStore,
  SignUpModal as RSignUpModalStore,
  SignInModal as RSignInModalStore,
  ForgetPasswordModal as RForgetPasswordModalStore,
  AddAddressModal as RAddAddressModalStore,
};
