import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import { CustomerAddress } from "../../../types/customerAddress";
import AddressDetail from "../AddressDetail";
import { CustomerAddressService } from "../../../services";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "../../../store";
import Alert from "../../../components/Alert";

type AddAddressProps = {
  className: string;
  show: boolean;
  onHide: () => void;
  onSuccess: (address: CustomerAddress) => void;
} & RCustomerStore.CustomerState;

type AddAddressState = {
  showAlert: boolean;
  alertMessage: string;
};

class AddAddress extends React.Component<AddAddressProps, AddAddressState> {
  public static defaultProps = {
    className: "",
    show: false,
  };

  public state = {
    showAlert: false,
    alertMessage: "",
  };

  showAlert = (message: string) => {
    this.setState({ showAlert: true, alertMessage: message });
  };

  hideAlert = () => {
    this.setState({ showAlert: false, alertMessage: "" });
  };

  onSave = async (address: CustomerAddress) => {
    const { onSuccess, customer } = this.props;
    if (customer === undefined) return;
    let result = await CustomerAddressService.addCustomerAddress(
      {
        addressDescription: address.addressDescription,
        addressName: address.addressName,
        customerFullName: address.customerFullName,
        doorNumber: address.doorNumber,
        isDefault: address.isDefault,
        phoneNumber: address.phoneNumber,
        postCode: address.postCode,
      },
      customer.id,
      customer.token.token
    );

    if (result.isSuccess) {
      address.id = result.data;
      onSuccess(address);
    } else {
      this.showAlert(result.message);
    }
  };

  render() {
    const { className, show, onHide, customer } = this.props;
    const { alertMessage, showAlert } = this.state;
    let customerFullName = customer ? customer.fullName : "";
    let customerPhone = customer ? customer.phoneNumber : "";
    return (
      <ModalContainer
        show={show}
        className={className + " wa-add-address"}
        showHeader={true}
        onHide={onHide}
        headerText={"Add New Address"}
      >
        <Alert
          bodyText={alertMessage}
          show={showAlert}
          onPressOk={this.hideAlert}
          okButtonText={"OK"}
        />
        <AddressDetail
          addressName="HOME"
          customerFullName={customerFullName}
          phoneNumber={customerPhone}
          onSave={this.onSave}
        />
      </ModalContainer>
    );
  }
}

export default connect((state: ApplicationState) => state.customer)(AddAddress);
