import * as React from "react";
import "./index.css";
import { KeyboardArrowRight } from "@material-ui/icons";
import { Theme } from "../../../../infrastructure/config";
import OrderDetail from "../OrderDetail";
import OrderHistoryItemStatus from "../OrderHistoryItemStatus";
import { Order } from "../../../../types/order";
import { DateTime } from "../../../../infrastructure/helper";

type OrderListItemProps = {
  className: string;
  order: Order;
};

type OrderListItemState = {
  showDetail: boolean;
};

class OrderListItem extends React.Component<
  OrderListItemProps,
  OrderListItemState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    showDetail: false,
  };

  showDetail = () => {
    this.setState({ showDetail: true });
  };

  render() {
    const { className, order } = this.props;
    const { showDetail } = this.state;
    return (
      <OrderHistoryItemStatus status={order.status}>
        <a
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            this.showDetail();
          }}
          onDoubleClick={(e) => {
            e.preventDefault();
            this.showDetail();
          }}
          className={className + " wa-order-list-item"}
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          {showDetail && (
            <OrderDetail
              orderId={order.id}
              show={showDetail}
              onHide={() => this.setState({ showDetail: false })}
            />
          )}
          <div
            className="wa-oli-detail"
            style={{
              color: Theme.color.title,
              borderColor: Theme.color.background,
            }}
          >
            <div className="wa-oli-first">
              <span className="wa-oli-order-no">
                Order No: {order.orderIndex.toString().padStart(6, "0")}
              </span>
              <span className="wa-oli-order-date">
                {DateTime.dateTimeString(order.createdDateTime)}
              </span>
            </div>
            {order.orderItems && order.orderItems.length > 0 && (
              <div className="wa-oli-item-container">
                {order.orderItems.map((item, index) => (
                  <div className="wa-oli-item" key={index}>
                    <span className="wa-oli-order-name">
                      {item.productName}
                    </span>
                    <span className="wa-oli-order-quantity">
                      {item.quantity} pc(s).
                    </span>
                  </div>
                ))}
              </div>
            )}

            <div
              className="wa-oli-third"
              style={{
                color: Theme.color.secondary,
                borderColor: Theme.color.inputBorder,
              }}
            >
              <span className="wa-oli-order-total-text">Order Total</span>
              <span className="wa-oli-order-total">
                £{order.totalPrice.toFixed(2)}
              </span>
            </div>
          </div>
          <div className="wa-oli-icon-container">
            <KeyboardArrowRight
              className="wa-oli-icon"
              htmlColor={Theme.color.touchableIconLighter}
            />
          </div>
        </a>
      </OrderHistoryItemStatus>
    );
  }
}

export default OrderListItem;
