import { OrderAttribute, OrderAttributeType } from "../../types/order";
export function getAttributeValue<T>(
  attributes: OrderAttribute[],
  type: OrderAttributeType
): T | undefined {
  if (attributes === undefined || attributes.length <= 0) return undefined;

  let attribute = attributes.find((x) => x.type === type);
  if (attribute === undefined) return undefined;

  return (attribute.value as any) as T;
}

export const chunk = <T>(array: T[], chunkSize: number): T[][] => {
  var R = [] as T[][];
  for (var i = 0; i < array.length; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
};
