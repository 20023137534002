import * as React from "react";
import "./index.css";
import { ActiveTab } from "../OrderSummaryTabs";
import { Theme } from "../../../../../infrastructure/config";
import { Order, OrderType } from "../../../../../types/order";
import {
  DateTime,
  Styles,
  TextHelper,
} from "../../../../../infrastructure/helper";

type OrderSummaryDetailsProps = {
  className: string;
  activeTab: ActiveTab;
  order: Order;
};

class OrderSummaryDetails extends React.Component<OrderSummaryDetailsProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className, activeTab, order } = this.props;
    let orderStatusStyle = Styles.orderStatus(order.status);
    let orderTypeStyle = Styles.orderType(order.type);
    return order ? (
      <div
        className={className + " wa-order-summary-details"}
        style={{ backgroundColor: Theme.color.containerBackground }}
      >
        {activeTab === ActiveTab.OrderDetail && (
          <>
            <SummaryRow
              label="Order Number"
              value={
                order.orderIndex
                  ? order.orderIndex.toString().padStart(6, "0")
                  : ""
              }
            />
            <SummaryRow
              label="Order Date"
              value={DateTime.dateTimeString(order.createdDateTime)}
            />
            <SummaryRow
              label="Payment Type"
              value={TextHelper.getPaymentTypeText(
                order.paymentType,
                order.type
              )}
            />
            {order.type === OrderType.EatIn && order.restaurantTable && (
              <SummaryRow
                label="Your Table"
                value={`${order.restaurantTable.prefix}-${order.restaurantTable.name}`}
              />
            )}
            <SummaryRow
              label="Order Type"
              value={orderTypeStyle.label}
              valueColor={orderTypeStyle.color}
            />
            <SummaryRow
              label="Order Status"
              value={orderStatusStyle.label}
              valueColor={orderStatusStyle.color}
            />
            {order.note && <SummaryRow label="Order Note" value={order.note} />}
          </>
        )}
        {activeTab === ActiveTab.AddressDetail && order.deliveryAddress && (
          <>
            <SummaryRow
              label="Collector Name"
              value={order.deliveryAddress.customerFullName}
            />
            <SummaryRow
              label="Contact Phone"
              value={order.deliveryAddress.phoneNumber}
            />
            <SummaryRow
              label="Post Code"
              value={order.deliveryAddress.postCode}
            />
            <SummaryRow
              label="Doour Number"
              value={order.deliveryAddress.doorNumber}
            />
            <SummaryRow
              label="Address Details"
              value={order.deliveryAddress.addressDescription}
            />
          </>
        )}
        {activeTab === ActiveTab.AddressDetail && order.eatInAddress && (
          <>
            <SummaryRow
              label="Collector Name"
              value={order.eatInAddress.customerFullName}
            />
            <SummaryRow
              label="Contact Phone"
              value={order.eatInAddress.phoneNumber}
            />
            <SummaryRow label="Post Code" value={order.eatInAddress.postCode} />
            <SummaryRow
              label="Doour Number"
              value={order.eatInAddress.doorNumber}
            />
            <SummaryRow
              label="Address Details"
              value={order.eatInAddress.addressDescription}
            />
          </>
        )}
      </div>
    ) : (
      <></>
    );
  }
}

export default OrderSummaryDetails;

type SummaryRowProps = {
  label: string;
  value: string;
  valueColor: string;
};
class SummaryRow extends React.PureComponent<SummaryRowProps> {
  public static defaultProps = {
    valueColor: Theme.color.title,
  };

  render() {
    const { label, value, valueColor } = this.props;
    return (
      <div
        className="wa-osd-row"
        style={{ borderColor: Theme.color.background }}
      >
        <span
          className="wa-osd-label"
          style={{ color: Theme.color.description }}
        >
          {label}
        </span>
        <span className="wa-osd-value" style={{ color: valueColor }}>
          {value}
        </span>
      </div>
    );
  }
}
