import * as React from "react";
import "./index.css";
import ModalContainer from "../../../../components/ModalContainer";
import { Order } from "../../../../types/order";
import OrderSummaryBody from "../../../partial/OrderSummaryBody";
import { OrderService } from "../../../../services";
import { CustomerStore } from "../../../../infrastructure/store";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  RMainStore,
} from "../../../../store";

type OrderDetailProps = {
  className: string;
  show: boolean;
  onHide: () => void;
  orderId: string;
} & RCustomerStore.CustomerState &
  RMainStore.MainState;

type OrderDetailState = {
  order: Order;
};

class OrderDetail extends React.Component<OrderDetailProps, OrderDetailState> {
  public static defaultProps = {
    className: "",
    show: false,
    onHide: () => {},
  };

  public state = {
    order: {} as Order,
  };

  componentDidMount = async () => {
    const { isAppLoaded, onHide } = this.props;
    if (isAppLoaded) {
      let orderSummary = await this.getOrderSummary();
      if (orderSummary) {
        this.setState({ order: orderSummary });
      } else {
        onHide();
      }
    }
  };

  componentDidUpdate = async () => {
    const { isAppLoaded, onHide } = this.props;
    const { order } = this.state;
    if (isAppLoaded && (!order || !order.id)) {
      let orderSummary = await this.getOrderSummary();
      if (orderSummary) {
        this.setState({ order: orderSummary });
      } else {
        onHide();
      }
    }
  };

  getOrderSummary = async (): Promise<Order | undefined> => {
    const { orderId } = this.props;
    let customerId = CustomerStore.getId();
    let token = CustomerStore.getToken();
    let orderSummary = await OrderService.getOrderSummary(
      orderId,
      customerId,
      token
    );
    if (orderSummary.isSuccess) {
      return orderSummary.data;
    } else return undefined;
  };

  render() {
    const { className, show, onHide } = this.props;
    const { order } = this.state;
    return (
      <ModalContainer
        show={show}
        showLoading={!order || !order.id}
        onHide={onHide}
        showHeader={true}
        headerText="Order Detail"
        className={className}
      >
        {order && <OrderSummaryBody order={order} />}
      </ModalContainer>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState & RMainStore.MainState =>
    ({ ...state.customer, ...state.main } as RCustomerStore.CustomerState &
      RMainStore.MainState)
)(OrderDetail);
