import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import { Order, CreateOrder } from "../types/order";
import { ApiResponse } from "types/response";

export async function createOrder(
  data: CreateOrder,
  deliveryDistanceId: string | null,
  customerId: string,
  token: string
): Promise<ApiResponse<string>> {
  return await apiRequest<string>({
    url: `order/create?customerId=${customerId}&deliveryDistanceId=${deliveryDistanceId}`,
    options: {
      method: HttpRequestMethod.PUT,
      authToken: token,
    } as HttpRequestOptions,
    data: data,
  } as ApiRequestConfig);
}

export async function getOrderSummary(
  orderId: string,
  customerId: string,
  token: string
): Promise<ApiResponse<Order>> {
  return await apiRequest<Order>({
    url: `order/summary?customerId=${customerId}&orderId=${orderId}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getOrderList(
  customerId: string,
  token: string
): Promise<ApiResponse<Order[]>> {
  return await apiRequest<Order[]>({
    url: `order/list?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}
