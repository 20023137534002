import * as React from "react";
import "./index.css";
import { Theme } from "../../../../../infrastructure/config";
import { Order, OrderItem, OrderType } from "../../../../../types/order";

type OrderSummaryItemsProps = {
  className: string;
  order: Order;
};

class OrderSummaryItems extends React.Component<OrderSummaryItemsProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className, order } = this.props;
    return order ? (
      <div
        className={className + " wa-order-summary-items"}
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        {order.orderItems &&
          order.orderItems.map((item, index) => (
            <OrderSummaryItem orderItem={item} key={index} />
          ))}
        <OrderSummaryPrice
          label="SUBTOTAL AMOUNT"
          value={order.subTotalPrice}
        />

        {order.campaigns &&
          order.campaigns.map((campaign, index) => (
            <OrderSummaryPrice
              label={campaign.name}
              value={campaign.discountAmount}
              key={index}
            />
          ))}
        {order.type === OrderType.Delivery && (
          <OrderSummaryPrice label="DELIVERY FEE" value={order.deliveryFee} />
        )}
        <OrderSummaryPrice
          label="TOTAL AMOUNT"
          value={order.totalPrice}
          size="large"
          color={Theme.color.orderTotalAmount}
        />
      </div>
    ) : (
      <></>
    );
  }
}

export default OrderSummaryItems;
type OrderSummaryPriceProps = {
  label: string;
  value: number;
  color: string;
  size: "normal" | "large";
};

class OrderSummaryPrice extends React.PureComponent<OrderSummaryPriceProps> {
  public static defaultProps = {
    color: Theme.color.title,
    size: "normal",
  };

  render() {
    const { label, value, color, size } = this.props;
    return label && value ? (
      <div
        className={"wa-osi-price " + size}
        style={{
          color: color,
        }}
      >
        <span className="wa-osi-price-label">{label.toUpperCase()}</span>
        <span className="wa-osi-price-value">£{value.toFixed(2)}</span>
      </div>
    ) : (
      <> </>
    );
  }
}

type OrderSummaryItemProps = {
  orderItem: OrderItem;
};

class OrderSummaryItem extends React.PureComponent<OrderSummaryItemProps> {
  render() {
    const { orderItem } = this.props;
    return (
      <div
        className="wa-order-summary-item"
        style={{ borderColor: Theme.color.background }}
      >
        <div className="wa-osi-quantity-container">
          <span
            className="wa-osi-quantity"
            style={{
              backgroundColor: Theme.color.description,
              color: Theme.color.titleDarker,
            }}
          >
            {orderItem.quantity}
          </span>
        </div>
        <div className="wa-osi-detail">
          <span
            className="wa-osi-detail-name"
            style={{ color: Theme.color.title }}
          >
            {orderItem.productName}
          </span>
          {orderItem.orderItemOptions &&
          orderItem.orderItemOptions.length > 0 ? (
            <span
              className="wa-osi-detail-options"
              style={{ color: Theme.color.description }}
            >
              {orderItem.orderItemOptions
                .map((option) => {
                  if (option.quantity > 1)
                    return `${option.name}(x${option.quantity})`;
                  else return option.name;
                })
                .join(",")}
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="wa-osi-price-container">
          <span
            className="wa-osi-totalprice"
            style={{ color: Theme.color.title }}
          >
            £{orderItem.totalPrice.toFixed(2)}
          </span>
        </div>
      </div>
    );
  }
}
