import React, { Component } from "react";
import "./index.css";
import { OrderStatus } from "../../../../types/order";
import { Theme } from "../../../../infrastructure/config";
import { TextHelper } from "../../../../infrastructure/helper";

type OrderHistoryItemStatusProps = {
  status: OrderStatus;
};

class OrderHistoryItemStatus extends Component<OrderHistoryItemStatusProps> {
  render() {
    const { children, status } = this.props;
    return (
      <div
        className="wa-ohis-container"
        style={{ borderColor: getOrderStatusColor(status) }}
      >
        {displayStatuses.includes(status) && (
          <div
            style={{ backgroundColor: getOrderStatusColor(status) }}
            className="wa-ohis-status-container"
          >
            <span className="wa-ohis-text" style={{ color: Theme.color.white }}>
              {TextHelper.getOrderStatusText(status)}
            </span>
          </div>
        )}
        {children}
      </div>
    );
  }
}

export default OrderHistoryItemStatus;

const getOrderStatusColor = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.Waiting:
      return Theme.color.orderWaiting;
    case OrderStatus.ScheduledWaiting:
      return Theme.color.orderScheduled;
    case OrderStatus.Preparing:
      return Theme.color.orderPreparing;
    case OrderStatus.Completed:
      return Theme.color.orderCompleted;
    case OrderStatus.Cancelled:
      return Theme.color.orderCancelled;
    case OrderStatus.Rejected:
      return Theme.color.orderRejected;
    default:
      return Theme.color.background;
  }
};

const displayStatuses = [
  OrderStatus.Waiting,
  OrderStatus.ScheduledWaiting,
  OrderStatus.Preparing,
  OrderStatus.Completed,
  OrderStatus.Cancelled,
  OrderStatus.Rejected,
];
