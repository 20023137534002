import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import { CustomerAddress } from "../../../types/customerAddress";
import AddressList from "../AddressList";
import { Theme } from "../../../infrastructure/config";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  RAddAddressModalStore,
} from "../../../store";
import { HomeWork } from "@material-ui/icons";
import { CustomerAddressService } from "../../../services";
import Button from "../../../components/Button";

type OrderSelectAddressProps = {
  className: string;
  show: boolean;
  selectedAddress: CustomerAddress | undefined;
  onHide: () => void;
  onSelect: (address: CustomerAddress) => void;
} & RCustomerStore.CustomerState &
  typeof RAddAddressModalStore.actionCreators;

type OrderSelectAddressState = {
  showLoading: boolean;
  addressList: CustomerAddress[];
};

class OrderSelectAddress extends React.Component<
  OrderSelectAddressProps,
  OrderSelectAddressState
> {
  public static defaultProps = {
    className: "",
    show: false,
    selectedAddress: undefined,
  };

  public state = { showLoading: true, addressList: [] as CustomerAddress[] };

  componentDidMount = async () => {
    let addressList = await this.getAddressList();

    this.setState({ showLoading: false, addressList: addressList });
  };

  getAddressList = async () => {
    const { customer } = this.props;
    if (customer === undefined) return [] as CustomerAddress[];
    let addressListResult = await CustomerAddressService.getCustomerAddresses(
      customer.id,
      customer.token.token
    );

    if (addressListResult.isSuccess) return addressListResult.data;
    else return [] as CustomerAddress[];
  };
  onClickAddAddress = () => {
    const { showAddAddressModal, hideAddAddressModal, onSelect } = this.props;
    showAddAddressModal(hideAddAddressModal, (address) => {
      hideAddAddressModal();
      onSelect(address);
    });
  };

  render() {
    const { className, show, selectedAddress, onHide, onSelect } = this.props;
    const { showLoading, addressList } = this.state;
    return (
      <ModalContainer
        show={show}
        className={className + " wa-order-select-address"}
        showHeader={true}
        onHide={onHide}
        showLoading={showLoading}
        headerText={"Addresses"}
        showFooter={true}
        footerContent={
          <Button
            className="wa-osa-button"
            onClick={this.onClickAddAddress}
            text="ADD NEW ADDRESS"
          />
        }
      >
        {addressList !== undefined && addressList.length > 0 ? (
          <div className="wa-osa-container">
            <span
              className="wa-osa-title"
              style={{ color: Theme.color.descriptionDarker }}
            >
              Select an address:
            </span>
            <AddressList
              addressList={addressList}
              selectedAddress={selectedAddress}
              onSelect={onSelect}
            />
          </div>
        ) : (
          <div className="wa-osa-empty">
            <HomeWork
              fontSize="large"
              className="wa-osa-empty-icon"
              htmlColor={Theme.color.title}
            />
            <span
              style={{ color: Theme.color.title }}
              className="wa-osa-empty-title"
            >
              There is no address found
            </span>
          </div>
        )}
      </ModalContainer>
    );
  }
}

export default connect((state: ApplicationState) => state.customer, {
  ...RAddAddressModalStore.actionCreators,
})(OrderSelectAddress);
