import * as React from "react";
import "./index.css";
import { ShoppingBasketOutlined } from "@material-ui/icons";
import { Theme } from "../../../../infrastructure/config";
import { connect } from "react-redux";
import { ApplicationState, RShoppingCartStore } from "../../../../store";
import { NavLink } from "react-router-dom";

type MobileBasketProps = {
  className: string;
} & RShoppingCartStore.ShoppingCartState;

class MobileBasket extends React.Component<MobileBasketProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className, shoppingCart } = this.props;
    let itemCount =
      shoppingCart &&
      shoppingCart.shoppingCartItems &&
      shoppingCart.shoppingCartItems.length > 0
        ? shoppingCart.shoppingCartItems
            .map((item) => item.quantity)
            .reduce((prev, current) => prev + current)
        : 0;

    return (
      <NavLink
        to="/basket"
        className={className + " wa-mobile-basket"}
        style={{ backgroundColor: Theme.color.primary }}
      >
        <ShoppingBasketOutlined
          htmlColor={Theme.color.onPrimary}
          className="wa-mb-icon"
        />
        <span
          style={{
            color: Theme.color.onButton,
            backgroundColor: Theme.color.button,
          }}
          className="wa-mb-count"
        >
          {itemCount}
        </span>
      </NavLink>
    );
  }
}

export default connect((state: ApplicationState) => state.shoppingCart)(
  MobileBasket
);
