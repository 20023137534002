import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import {
  SquareUpSettings,
  ProcessPaymentResult,
  ProcessPayment,
  PaymentEnvironment,
} from "../types/payment";
import { OrderProductGroupType } from "types/order";
import { ApiResponse } from "types/response";

export async function getSquareUpSettings(
  environment: PaymentEnvironment,
  token: string
): Promise<ApiResponse<SquareUpSettings>> {
  return await apiRequest<SquareUpSettings>({
    url: `payment/square-up/settings?environment=${environment}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function processPayment(
  processPayment: ProcessPayment,
  orderProductGroup: OrderProductGroupType,
  deliveryDistanceId: string,
  customerId: string,
  token: string
): Promise<ApiResponse<ProcessPaymentResult>> {
  return await apiRequest<ProcessPaymentResult>({
    url: `payment/process?customerId=${customerId}&orderProductGroup=${orderProductGroup}&deliveryDistanceId=${deliveryDistanceId}`,
    options: {
      method: HttpRequestMethod.POST,
      authToken: token,
    } as HttpRequestOptions,
    data: processPayment,
  } as ApiRequestConfig);
}
