import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import { RestaurantService } from "services";
import { ApplicationState, RCustomerStore } from "store";
import { connect } from "react-redux";
import { RestaurantTable } from "types/restaurant";
import { Theme } from "infrastructure/config";
import { Restaurant, Info } from "@material-ui/icons";
import MenuSearch from "pages/Home/components/Menu/MenuSearch";

type OrderSelectTableProps = {
  className: string;
  show: boolean;
  onHide: () => void;
  onError: (message: string) => void;
  onSelect: (table: RestaurantTable) => void;
} & RCustomerStore.CustomerState;

type OrderSelectTableState = {
  restaurantTables: RestaurantTable[];
  filteredRestaurantTables: RestaurantTable[];
  showLoading: boolean;
  searchText: string;
};

class OrderSelectTable extends React.Component<
  OrderSelectTableProps,
  OrderSelectTableState
> {
  public static defaultProps = {
    className: "",
    show: false,
  };

  public state = {
    restaurantTables: [] as RestaurantTable[],
    showLoading: true,
    searchText: "",
    filteredRestaurantTables: [] as RestaurantTable[],
  };

  componentDidMount = async () => {
    const { onError, onHide } = this.props;
    let tableResult = await this.getRestaurantTables();
    if (!tableResult) {
      onError("An error has occurred.");
      onHide();
      this.setState({ showLoading: false });
      return;
    }
    if (!tableResult.isSuccess) {
      onError(tableResult.message);
      onHide();
      this.setState({ showLoading: false });
      return;
    }

    this.setState({
      restaurantTables: tableResult.data,
      filteredRestaurantTables: tableResult.data,
      showLoading: false,
    });
  };

  getRestaurantTables = async () => {
    const { customer } = this.props;
    if (customer === undefined) return;
    let tableResult = await RestaurantService.getRestaurantTables(
      customer.token.token
    );

    return tableResult;
  };

  onSearchTextChange = (text: string) => {
    const { restaurantTables } = this.state;
    if (!text || text.trim() === "") {
      this.setState({ filteredRestaurantTables: restaurantTables });
      return;
    }

    let filteredRestaurantTables = restaurantTables.filter(
      (table) =>
        (table.prefix + "-" + table.name)
          .toLowerCase()
          .includes(text.trim().toLocaleLowerCase()) ||
        (table.prefix + table.name)
          .toLowerCase()
          .includes(text.trim().toLocaleLowerCase())
    );

    this.setState({ filteredRestaurantTables: filteredRestaurantTables });
  };

  render() {
    const { className, show, onHide, onSelect } = this.props;
    const { showLoading, filteredRestaurantTables } = this.state;
    return (
      <ModalContainer
        show={show}
        className={className + " wa-order-select-table"}
        showHeader={true}
        onHide={onHide}
        showLoading={showLoading}
        headerText={"Select your table"}
      >
        <div
          className="wa-os-search-container"
          style={{
            backgroundColor: Theme.color.background,
          }}
        >
          <MenuSearch
            onChange={this.onSearchTextChange}
            className="wa-os-search"
          />
        </div>
        {filteredRestaurantTables.length <= 0 ? (
          <div className="wa-table-selection-empty">
            <Info
              className="wa-tse-icon"
              fontSize={"large"}
              htmlColor={Theme.color.secondary}
            />
            <span
              className="wa-tse-text"
              style={{ color: Theme.color.secondary }}
            >
              No tables found.
            </span>
          </div>
        ) : (
          <div
            className="wa-table-selection-container"
            style={{
              borderColor: Theme.color.inputBorder,
            }}
          >
            {filteredRestaurantTables.map((table, index) => (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onSelect(table);
                }}
                className="wa-table-selection"
                key={index}
                style={{
                  backgroundColor: Theme.color.containerBackground,
                  borderColor: Theme.color.inputBorder,
                }}
              >
                <Restaurant
                  fontSize={"large"}
                  className="wa-table-selection-li"
                  htmlColor={Theme.color.title}
                />
                <div
                  className="wa-table-selection-name"
                  style={{ color: Theme.color.title }}
                >
                  {table.prefix ? `${table.prefix}-${table.name}` : table.name}
                </div>
              </button>
            ))}
          </div>
        )}
      </ModalContainer>
    );
  }
}

export default connect((state: ApplicationState) => state.customer)(
  OrderSelectTable
);
