import * as React from "react";
import MenuCategory from "../MenuCategory";
import { Category } from "../../../../../types/category";

type MenuCategoryListProps = {
  className: string;
  categories: Category[];
};
type MenuCategoryListState = {
  expandedCategory: number;
};
class MenuCategoryList extends React.Component<
  MenuCategoryListProps,
  MenuCategoryListState
> {
  public static defaultProps = {
    className: "",
    categories: [] as Category[],
  };

  public state = {
    expandedCategory: -1,
  };

  onToggleCategory = (index: number) => {
    const { expandedCategory } = this.state;
    if (expandedCategory === index) {
      this.setState({ expandedCategory: -1 });
    } else {
      this.setState({ expandedCategory: index });
    }
  };

  render() {
    const { className, categories } = this.props;
    const { expandedCategory } = this.state;
    return (
      <div className={className}>
        {categories.map((category, index) => (
          <MenuCategory
            key={index}
            category={category}
            expanded={expandedCategory === index ? true : false}
            onToggle={() => this.onToggleCategory(index)}
          />
        ))}
      </div>
    );
  }
}

export default MenuCategoryList;
