import * as React from "react";
import "./index.css";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import {
  ProductOptionItem,
  SelectedOption,
} from "../../../../../types/product";

type MenuItemExtraMultipleItemProps = {
  className: string;
  item: ProductOptionItem;
  index: number;
  show: boolean;
  onSelect: (index: number) => void;
  onDeselect: (index: number) => void;
  validateQuantity: (prevQuantity: number, nextQuantity: number) => boolean;
  onQuantityChange: (index: number, newQuantity: number) => void;
};

type MenuItemExtraMultipleItemState = {
  quantity: number;
};

class MenuItemExtraMultipleItem extends React.Component<
  MenuItemExtraMultipleItemProps,
  MenuItemExtraMultipleItemState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    quantity:
      this.props.item && this.props.item.selected
        ? this.props.item.quantity === 0
          ? 1
          : this.props.item.quantity
        : 0,
  };

  onPress = (): void => {
    const {
      onSelect,
      onDeselect,
      onQuantityChange,
      validateQuantity,
      index,
      item,
    } = this.props;
    if (!item.selected) {
      if (validateQuantity(0, 1)) {
        this.setState({ quantity: 1 }, () => {
          onSelect(index);
          onQuantityChange(index, 1);
        });
      }
    } else {
      this.setState({ quantity: 0 }, () => {
        onDeselect(index);
        onQuantityChange(index, 0);
      });
    }
  };

  getItemOption = (): SelectedOption | undefined => {
    const { item } = this.props;
    const { quantity } = this.state;
    return item.selected
      ? ({ quantity: quantity, productOptionItemId: item.id } as SelectedOption)
      : undefined;
  };

  getQuantity = (): number => {
    const { quantity } = this.state;
    return quantity;
  };

  getPrice = (): number => {
    const { item } = this.props;
    return item ? item.price : 0;
  };

  getTotalPrice = (): number => {
    return this.getQuantity() * this.getPrice();
  };

  render() {
    const { className, item, show } = this.props;
    return (
      <button
        onClick={(e: React.MouseEvent) => this.onPress()}
        className={className + " wa-miemi" + (show ? "" : " wa-d-none")}
        style={{
          borderColor: Theme.color.backgroundDarker,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        <div className="wa-miemi-detail">
          <span className="wa-miemi-name" style={{ color: Theme.color.title }}>
            {item.name}
          </span>
          {item.price > 0 && (
            <span
              className="wa-miemi-price"
              style={{ color: Theme.color.primary }}
            >
              (+£{item.price.toFixed(2)})
            </span>
          )}
        </div>
        {item.selected === true && (
          <CheckBox
            className="wa-miemi-icon"
            style={{ color: Theme.color.secondary }}
          />
        )}
        {item.selected === false && (
          <CheckBoxOutlineBlank
            className="wa-miemi-icon"
            style={{ color: Theme.color.title }}
          />
        )}
      </button>
    );
  }
}

export default MenuItemExtraMultipleItem;
