import { Action, Reducer } from "redux";
import { OrderType } from "../types/order";

export interface OrderState {
  selectedOrderType: OrderType;
}

interface SelectOrderTypeAction {
  type: "SELECT_ORDER_TYPE";
  orderType: OrderType;
}

export const actionCreators = {
  selectOrderType: (orderType: OrderType) =>
    ({
      type: "SELECT_ORDER_TYPE",
      orderType: orderType,
    } as SelectOrderTypeAction),
};

type KnownAction = SelectOrderTypeAction;

const unloadedState: OrderState = {
  selectedOrderType: OrderType.None,
};

export const reducer: Reducer<OrderState> = (
  state: OrderState | undefined,
  incomingAction: Action
): OrderState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SELECT_ORDER_TYPE":
      return {
        ...state,
        selectedOrderType: action.orderType,
      };
  }

  return state;
};
