import * as React from "react";
import "./index.css";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import MenuItemExtraMultipleQuantityItem from "../MenuItemExtraMultipleQuantityItem";
import {
  ProductOption,
  ProductOptionLimitType,
  SelectedOption,
} from "../../../../../types/product";

type MenuItemExtraMultipleQuantityProps = {
  className: string;
  option: ProductOption;
  index: number;
  onSelect: (index: number, option: ProductOption) => void;
  onDeselect: (index: number, option: ProductOption) => void;
  onQuantityChange: (index: number) => void;
};

type MenuItemExtraMultipleQuantityState = {
  expanded: boolean;
  option: ProductOption;
  isValid: boolean;
};

class MenuItemExtraMultipleQuantity extends React.Component<
  MenuItemExtraMultipleQuantityProps,
  MenuItemExtraMultipleQuantityState
> {
  items: any;
  constructor(props: MenuItemExtraMultipleQuantityProps) {
    super(props);
    this.items = {};

    let optionItemsLength =
      props.option && props.option.productOptionItems
        ? props.option.productOptionItems.length
        : 0;
    this.createItemRefs(optionItemsLength);
  }

  public static defaultProps = {
    className: "",
  };

  public state = {
    expanded: false,
    option: this.props.option,
    isValid: true,
  };

  createItemRefs = (length: number): void => {
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      this.items[`item${index}`] = React.createRef();
    }
  };

  getItemOptions = (): SelectedOption[] => {
    const { option } = this.state;
    const { productOptionItems } = option;

    if (productOptionItems && productOptionItems.length > 0) {
      let itemOptions: SelectedOption[] = [];

      for (let index = 0; index < productOptionItems.length; index++) {
        let item = this.items[`item${index}`].current;
        if (item) {
          let itemOption = item.getItemOption() as SelectedOption;
          if (itemOption) itemOptions.push(itemOption);
        }
      }
      return itemOptions;
    } else {
      return [];
    }
  };

  toggle = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  onSelect = (selectedIndex: number) => {
    const { option } = this.state;
    const { onSelect, index } = this.props;
    if (
      option &&
      option.productOptionItems &&
      option.productOptionItems.length > selectedIndex
    ) {
      option.productOptionItems[selectedIndex].selected = true;
      this.setState({ option: option }, () => {
        onSelect(index, option);
      });
    }
  };

  onDeselect = (selectedIndex: number) => {
    const { option } = this.state;
    const { onDeselect, index } = this.props;
    if (
      option &&
      option.productOptionItems &&
      option.productOptionItems.length > selectedIndex
    ) {
      option.productOptionItems[selectedIndex].selected = false;

      this.setState({ option: option }, () => {
        onDeselect(index, option);
      });
    }
  };

  markAsValid = () => {
    this.setState({ isValid: true });
  };

  isValid = (): boolean => {
    const { option } = this.state;
    let isValid = true;
    let totalQuantity = this.getTotalQuantity();
    if (option.limitBy === ProductOptionLimitType.Maximum) {
      if (totalQuantity > option.limitQuantity) isValid = false;
    } else if (option.limitBy === ProductOptionLimitType.Equal) {
      if (totalQuantity !== option.limitQuantity) isValid = false;
    } else if (option.limitBy === ProductOptionLimitType.Minimum) {
      if (totalQuantity < option.limitQuantity) isValid = false;
    }

    if (totalQuantity <= 0 && option.isRequired) isValid = false;

    this.setState({ isValid: isValid });
    return isValid;
  };

  onQuantityChange = (indexItem: number): void => {
    const { onQuantityChange, index } = this.props;

    onQuantityChange(index);
  };

  validateQuantity = (prevQuantity: number, nextQuantity: number): boolean => {
    const { option } = this.state;
    let totalQuantity = this.getTotalQuantity();
    let newTotalQuantity = totalQuantity - prevQuantity + nextQuantity;
    if (nextQuantity < 0) return false;

    if (
      option.limitBy === ProductOptionLimitType.Maximum ||
      option.limitBy === ProductOptionLimitType.Equal
    ) {
      if (newTotalQuantity > option.limitQuantity) {
        return false;
      }
    }
    return true;
  };

  getQuantity = (index: number): number => {
    let item = this.items[`item${index}`].current;
    if (item) return item.getQuantity();
    else return 0;
  };

  getTotalPrice = (): number => {
    const { option } = this.state;
    const { productOptionItems } = option;
    if (!productOptionItems || productOptionItems.length <= 0) return 0;
    let totalPrice = 0;
    for (let index = 0; index < productOptionItems.length; index++) {
      let item = this.items[`item${index}`].current;
      if (item) totalPrice += item.getTotalPrice();
    }

    return totalPrice;
  };

  getTotalQuantity = (): number => {
    const { option } = this.state;
    const { productOptionItems } = option;
    if (!productOptionItems || productOptionItems.length <= 0) return 0;
    let totalQuantity = 0;
    for (let index = 0; index < productOptionItems.length; index++) {
      totalQuantity += this.getQuantity(index);
    }

    return totalQuantity;
  };

  render() {
    const { className, option } = this.props;
    const { expanded, isValid } = this.state;
    return (
      <div
        className={
          className +
          " wa-menu-item-extra-multiple-quantity" +
          (!isValid ? " invalid" : "")
        }
        style={{
          backgroundColor: Theme.color.containerBackground,
          borderColor: !isValid
            ? Theme.color.error
            : Theme.color.backgroundDarker,
        }}
      >
        <button
          onClick={this.toggle}
          className="wa-menu-item-extra-multiple-quantity-title-container"
          style={{
            backgroundColor: Theme.color.containerBackground,
          }}
        >
          <div className="wa-miemq-container">
            <span
              className="wa-menu-item-extra-quantity-multiple-title"
              style={{
                color: Theme.color.primary,
              }}
            >
              {option.name}
            </span>
            {option.limitBy === ProductOptionLimitType.Maximum ? (
              <span className="wa-miemql" style={{ color: Theme.color.error }}>
                *max {option.limitQuantity}
              </span>
            ) : option.limitBy === ProductOptionLimitType.Minimum ? (
              <span className="wa-miemql" style={{ color: Theme.color.error }}>
                *min {option.limitQuantity}
              </span>
            ) : option.limitBy === ProductOptionLimitType.Equal ? (
              <span className="wa-miemql" style={{ color: Theme.color.error }}>
                *pick {option.limitQuantity}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div
            className="wa-menu-item-extra-multiple-quantity-right-container"
            style={{ color: Theme.color.title }}
          >
            {expanded === true && <ExpandLess />}
            {expanded === false && <ExpandMore />}
          </div>
        </button>
        {option.productOptionItems &&
          option.productOptionItems.length > 0 &&
          option.productOptionItems.map((item, index) => (
            <MenuItemExtraMultipleQuantityItem
              show={expanded}
              onDeselect={this.onDeselect}
              onQuantityChange={this.onQuantityChange}
              validateQuantity={this.validateQuantity}
              ref={this.items[`item${index}`]}
              onSelect={this.onSelect}
              index={index}
              key={index}
              item={item}
            />
          ))}
      </div>
    );
  }
}

export default MenuItemExtraMultipleQuantity;
