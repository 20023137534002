export default async function httpRequest<T>(
  url = "",
  data: undefined | object = undefined,
  options: HttpRequestOptions
) {
  let optionsActual = {
    method: HttpRequestMethod.GET,
    body: undefined,
    headers: {
      "Content-Type": "application/json",
      Authorization: "",
    },
  } as HttpRequestActualOptions;

  if (data) optionsActual.body = JSON.stringify(data);
  if (options) {
    if (options.method) optionsActual.method = options.method;
    if (options.authToken)
      optionsActual.headers.Authorization = "Bearer " + options.authToken;
  }

  if (
    optionsActual.method === HttpRequestMethod.GET ||
    options.method === HttpRequestMethod.HEAD
  )
    optionsActual.body = undefined;

  const response = await fetch(url, optionsActual);
  return response.json() as Promise<T>;
}

export const HttpRequestMethod = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
  HEAD: "HEAD",
};

export type HttpRequestOptions = {
  method: string;
  authToken: string;
};

type HttpRequestActualOptions = {
  method: string;
  body: undefined | string;
  headers: {
    "Content-Type": string;
    Authorization: string;
  };
};
