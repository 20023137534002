import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import {
  CustomerAddress,
  AddCustomerAddress,
  UpdateCustomerAddress,
} from "../types/customerAddress";
import { ApiResponse } from "types/response";

export async function getDefaultCustomerAddress(
  customerId: string,
  token: string
): Promise<ApiResponse<CustomerAddress>> {
  return await apiRequest<CustomerAddress>({
    url: `customer-address/default?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getCustomerAddresses(
  customerId: string,
  token: string
): Promise<ApiResponse<CustomerAddress[]>> {
  return await apiRequest<CustomerAddress[]>({
    url: `customer-address/list?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getCustomerAddress(
  customerId: string,
  customerAddressId: string,
  token: string
): Promise<ApiResponse<CustomerAddress>> {
  return await apiRequest({
    url: `customer-address?customerAddressId=${customerAddressId}&customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function addCustomerAddress(
  addCustomerAddress: AddCustomerAddress,
  customerId: string,
  token: string
): Promise<ApiResponse<string>> {
  return await apiRequest<string>({
    url: `customer-address?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.PUT,
      authToken: token,
    } as HttpRequestOptions,
    data: addCustomerAddress,
  } as ApiRequestConfig);
}

export async function updateCustomerAddress(
  updateCustomerAddress: UpdateCustomerAddress,
  customerId: string,
  token: string
): Promise<ApiResponse<string>> {
  return await apiRequest<string>({
    url: `customer-address?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.POST,
      authToken: token,
    } as HttpRequestOptions,
    data: updateCustomerAddress,
  } as ApiRequestConfig);
}

export async function setAddressAsDefault(
  customerAddressId: string,
  customerId: string,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer-address/set-default?customerId=${customerId}&customerAddressId=${customerAddressId}`,
    options: {
      method: HttpRequestMethod.POST,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function deleteCustomerAddress(
  addressId: string,
  customerId: string,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer-address?customerId=${customerId}&addressId=${addressId}`,
    options: {
      method: HttpRequestMethod.DELETE,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}
