import * as React from "react";
import "./index.css";
import { PaymentType, PaymentEnvironment } from "../../../../types/payment";
import OrderPaymentType from "../../../../components/OrderPaymentType";
import OrderSelectPaymentType from "../.../../../../partial/OrderSelectPaymentType";
import { OrderType } from "../../../../types/order";

type PaymentTypeSelectionProps = {
  paymentType: PaymentType;
  orderType: OrderType;
  onChange: (
    paymentType: PaymentType,
    paymentEnvironment: PaymentEnvironment
  ) => void;
};

type PaymentTypeSelectionState = {
  show: boolean;
};

class PaymentTypeSelection extends React.Component<
  PaymentTypeSelectionProps,
  PaymentTypeSelectionState
> {
  public state = {
    show: false,
  };

  onChange = (
    paymentType: PaymentType,
    paymentEnvironment: PaymentEnvironment
  ) => {
    const { onChange } = this.props;
    onChange(paymentType, paymentEnvironment);
    this.setState({ show: false });
  };

  render() {
    const { paymentType, orderType } = this.props;
    const { show } = this.state;
    return (
      <>
        <OrderPaymentType
          orderType={orderType}
          paymentType={paymentType}
          onClick={() => {
            this.setState({ show: true });
          }}
        />
        {show && (
          <OrderSelectPaymentType
            show={show}
            orderType={orderType}
            onChange={this.onChange}
            onHide={() => this.setState({ show: false })}
          />
        )}
      </>
    );
  }
}

export default PaymentTypeSelection;
