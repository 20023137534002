import * as React from "react";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import "./index.css";
import { Theme } from "../../../../../infrastructure/config";
import ProductDetail from "../../../../partial/ProductDetail";
import { Product } from "../../../../../types/product";

type MenuItemProps = {
  className: string;
  product: Product;
};
type MenuItemState = {
  showDetail: boolean;
};
class MenuItem extends React.Component<MenuItemProps, MenuItemState> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    showDetail: false,
  };

  onHideDetail = () => {
    this.setState({ showDetail: false });
  };

  onClick = () => {
    this.setState({ showDetail: true });
  };

  render() {
    const { className, product } = this.props;
    const { showDetail } = this.state;
    return (
      <div
        className={className + " wa-menu-item-row border"}
        style={{ backgroundColor: Theme.color.containerBackground }}
      >
        {showDetail && (
          <ProductDetail
            id={product.id}
            show={showDetail}
            onHide={this.onHideDetail}
          />
        )}
        <div className="wa-menu-item-actions">
          <button
            onClick={this.onClick}
            className="wa-menu-item-add-button"
            style={{
              backgroundColor: Theme.color.onPrimary,
              borderColor: Theme.color.onPrimary,
            }}
          >
            <AddCircleOutlineOutlined htmlColor={Theme.color.menuItemIcon} />
          </button>
        </div>
        <div className="wa-menu-item-detail">
          <button
            onClick={this.onClick}
            className="wa-menu-item-name"
            style={{ color: Theme.color.titleDarker }}
          >
            {product.name}
          </button>
          {product.description && (
            <span
              className="wa-menu-item-description"
              style={{ color: Theme.color.descriptionDarker }}
            >
              {product.description}
            </span>
          )}
        </div>
        <div className="wa-menu-item-prices">
          {product.discountedPrice !== undefined &&
            product.discountedPrice !== null &&
            product.discountedPrice < product.price && (
              <span
                className="wa-menu-item-subtotal"
                style={{ color: Theme.color.passive }}
              >
                £{product.price.toFixed(2)}
              </span>
            )}
          <span
            className="wa-menu-item-total"
            style={{ color: Theme.color.secondary }}
          >
            £
            {product.discountedPrice !== undefined &&
            product.discountedPrice !== null &&
            product.discountedPrice < product.price
              ? product.discountedPrice.toFixed(2)
              : product.price.toFixed(2)}
          </span>
        </div>
      </div>
    );
  }
}

export default MenuItem;
