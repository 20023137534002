import { DayOfWeekType, TTL } from "../../types/date";
import * as LocalStore from "./store";
import {
  RestaurantWorkingHour,
  RestaurantInfo,
  RestaurantInfoType,
} from "../../types/restaurant";
import { RestaurantService } from "../../services";
import { Store } from "types/system";
import { OrderLimit, OrderType } from "types/order";

let restaurantInformations = {
  aboutUsTitle: "",
  aboutUsText: "",
  address: "",
  location: "",
  webSiteText: "",
  webSite: "",
  contactMailText: "",
  contactMail: "",
  phoneText: "",
  phone: "",
  deliveryTime: "",
  eatInTime: "",
  takeawayTime: "",
} as RestaurantInformations;

let restaurantOrderLimits = [] as OrderLimit[];

let restaurantWorkingTimes = [] as RestaurantWorkingHour[];

export function getWorkingTime(dayOfWeek: DayOfWeekType): string {
  if (!restaurantWorkingTimes || restaurantWorkingTimes.length <= 0)
    return "Closed";
  if (!dayOfWeek) return "Closed";

  let workingHour = restaurantWorkingTimes.find(
    (x) => x.dayOfWeek === dayOfWeek
  );

  if (!workingHour) return "Closed";

  if (workingHour.isClosed === false) {
    let workingHours = "";
    if (workingHour.openingTime) {
      let openingTime = getTimeString(
        workingHour.openingTime.hours,
        workingHour.openingTime.minutes
      );
      workingHours += openingTime;
    }
    if (workingHour.closingTime && workingHour.openingTime) {
      workingHours += " - ";
    }
    if (workingHour.closingTime) {
      let closingTime = getTimeString(
        workingHour.closingTime.hours,
        workingHour.closingTime.minutes
      );
      workingHours += closingTime;
    }

    return workingHours;
  } else return "Closed";
}

export function getOrderLimit(orderType: OrderType): OrderLimit | undefined {
  return restaurantOrderLimits && restaurantOrderLimits.length > 0
    ? restaurantOrderLimits.find((item) => item.orderType === orderType)
    : undefined;
}

export function getOrderLimitsWithDistance(orderType: OrderType): OrderLimit[] {
  return restaurantOrderLimits && restaurantOrderLimits.length > 0
    ? restaurantOrderLimits.filter(
        (item) =>
          item.orderType === orderType &&
          item.deliveryDistance !== undefined &&
          item.deliveryDistance !== null
      )
    : ([] as OrderLimit[]);
}

function getTimeString(hour: number, minute: number): string {
  let newHour = hour;
  let amPm = "am";
  if (hour >= 12) {
    if (hour === 12) {
      newHour = 12;
    } else {
      newHour = hour - 12;
    }
    amPm = "pm";
  }

  if (hour === 0) {
    newHour = 12;
  }

  return (
    newHour.toString().padStart(2, "0") +
    ":" +
    minute.toString().padStart(2, "0") +
    amPm
  );
}

export function getAboutUsTitle(): string {
  return getProp(restaurantInformations.aboutUsTitle);
}

export function getAboutUsText(): string {
  return getProp(restaurantInformations.aboutUsText);
}

export function getAddress(): string {
  return getProp(restaurantInformations.address);
}

export function getLocation(): string {
  return getProp(restaurantInformations.location);
}

export function getWebSiteText(): string {
  return getProp(restaurantInformations.webSiteText);
}

export function getWebSite(): string {
  return getProp(restaurantInformations.webSite);
}

export function getContactMailText(): string {
  return getProp(restaurantInformations.contactMailText);
}

export function getContactMail(): string {
  return getProp(restaurantInformations.contactMail);
}

export function getPhoneText(): string {
  return getProp(restaurantInformations.phoneText);
}

export function getOrderTime(orderType: OrderType): string {
  switch (orderType) {
    case OrderType.Delivery:
      return getProp(restaurantInformations.deliveryTime);
    case OrderType.Takeaway:
      return getProp(restaurantInformations.takeawayTime);
    case OrderType.EatIn:
      return getProp(restaurantInformations.eatInTime);
    default:
      return "";
  }
}

export function getPhone(): string {
  if (
    restaurantInformations.phone &&
    restaurantInformations.phone.trim() !== ""
  )
    return restaurantInformations.phone;
  else return "";
}

export async function initializeRestaurantInformations(): Promise<void> {
  let data = LocalStore.getDataWithExpiry<RestaurantInformations>(
    Store.RestaurantInfo
  );
  if (data && data.webSite) {
    restaurantInformations = data;
    return;
  }

  let informationResult = await RestaurantService.getRestaurantInformations();

  if (informationResult.isSuccess) {
    fillRestaurantInformations(informationResult.data);
  }

  LocalStore.saveDataWithExpiry(
    restaurantInformations,
    Store.RestaurantInfo,
    TTL.Day
  );
}

export async function initializeResturantWorkingTimes(): Promise<void> {
  let data = LocalStore.getDataWithExpiry<RestaurantWorkingHour[]>(
    Store.RestaurantWorkingDays
  );
  if (data && data.length > 0) {
    restaurantWorkingTimes = data;
    return;
  }

  let workingTimesResult = await RestaurantService.getRestaurantWorkingDays();
  if (workingTimesResult.isSuccess) {
    restaurantWorkingTimes = workingTimesResult.data;
  }

  LocalStore.saveDataWithExpiry(
    restaurantWorkingTimes,
    Store.RestaurantWorkingDays,
    TTL.Day
  );
}

export async function initializeRestaurantOrderLimits(): Promise<void> {
  let data = LocalStore.getDataWithExpiry<OrderLimit[]>(
    Store.RestaurantOrderLimits
  );
  if (data && data.length > 0) {
    restaurantOrderLimits = data;
    return;
  }

  let orderLimitsResult = await RestaurantService.getRestaurantOrderLimits();
  if (orderLimitsResult.isSuccess) {
    restaurantOrderLimits = orderLimitsResult.data;
  }

  LocalStore.saveDataWithExpiry(
    restaurantOrderLimits,
    Store.RestaurantOrderLimits,
    TTL.Day
  );
}

function fillRestaurantInformations(informations: RestaurantInfo[]): void {
  if (informations && informations.length > 0) {
    restaurantInformations.aboutUsTitle = getValue(
      informations,
      RestaurantInfoType.AboutUsTitle
    );
    restaurantInformations.aboutUsText = getValue(
      informations,
      RestaurantInfoType.AboutUsText
    );
    restaurantInformations.address = getValue(
      informations,
      RestaurantInfoType.Address
    );
    restaurantInformations.location = getValue(
      informations,
      RestaurantInfoType.Location
    );
    restaurantInformations.webSiteText = getValue(
      informations,
      RestaurantInfoType.WebSiteText
    );
    restaurantInformations.webSite = getValue(
      informations,
      RestaurantInfoType.WebSite
    );
    restaurantInformations.contactMailText = getValue(
      informations,
      RestaurantInfoType.ContactMailText
    );
    restaurantInformations.contactMail = getValue(
      informations,
      RestaurantInfoType.ContactMail
    );
    restaurantInformations.phoneText = getValue(
      informations,
      RestaurantInfoType.PhoneText
    );
    restaurantInformations.phone = getValue(
      informations,
      RestaurantInfoType.Phone
    );
    restaurantInformations.deliveryTime = getValue(
      informations,
      RestaurantInfoType.DeliveryTime
    );
    restaurantInformations.takeawayTime = getValue(
      informations,
      RestaurantInfoType.TakeawayTime
    );
    restaurantInformations.eatInTime = getValue(
      informations,
      RestaurantInfoType.EatInTime
    );
  }
}

function getValue(items: RestaurantInfo[], type: RestaurantInfoType) {
  let item = items.find((item) => item.infoType === type);
  if (item && item.value) return item.value;
  else return "";
}

function getProp(prop: string): string {
  if (prop && prop.trim() !== "") return prop.replace("\\n", "\n");
  else return "";
}

interface RestaurantInformations {
  aboutUsTitle: string;
  aboutUsText: string;
  address: string;
  location: string;
  webSiteText: string;
  webSite: string;
  contactMailText: string;
  contactMail: string;
  phoneText: string;
  phone: string;
  deliveryTime: string;
  eatInTime: string;
  takeawayTime: string;
}
