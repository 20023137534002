import * as React from "react";
import "./index.css";
import InnerLayout from "../partial/InnerLayout";
import {
  FullNameInput,
  EmailInput,
  PhoneNumberInput,
} from "../../components/Input";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "../../store";
import { CustomerStore } from "../../infrastructure/store";
import { withRouter, RouteComponentProps } from "react-router";
import { Theme } from "../../infrastructure/config";
import { NavLink } from "react-router-dom";
import { Lock } from "@material-ui/icons";
import Button from "../../components/Button";
import { Validator } from "../../infrastructure/helper";
import Alert from "../../components/Alert";
import { CustomerService } from "../../services";
import PageLayout from "../partial/PageLayout";

type ProfileProps = {
  className: string;
} & RCustomerStore.CustomerState &
  typeof RCustomerStore.actionCreators &
  RouteComponentProps;

type ProfileState = {
  customerFullName: string;
  isFullNameValid: boolean;
  email: string;
  phoneNumber: string;
  isPhoneNumberValid: boolean;
  showAlert: boolean;
  alertText: string;
};

class Profile extends React.Component<ProfileProps, ProfileState> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    customerFullName: CustomerStore.getFullName(),
    isFullNameValid: true,
    email: CustomerStore.getEmail(),
    phoneNumber: CustomerStore.getPhoneNumber(),
    isPhoneNumberValid: true,
    showAlert: false,
    alertText: "",
  };

  componentDidMount = () => {
    const { history } = this.props;
    if (CustomerStore.isSignedIn() === false) history.replace("/");
  };

  showAlertModal = (text: string) => {
    this.setState({
      showAlert: true,
      alertText: text,
    });
  };

  onCloseModal = () => {
    this.setState({ showAlert: false, alertText: "" });
    const { history } = this.props;
    if (history.location.state && history.location.state.from)
      history.replace(history.location.state.from);
  };

  updateCustomerInfo = () => {
    const { customerFullName, phoneNumber } = this.state;
    const { updateFullName, updatePhone } = this.props;
    updateFullName(customerFullName);
    CustomerStore.updateFullName(customerFullName);
    updatePhone(phoneNumber);
    CustomerStore.updatePhoneNumber(phoneNumber);
  };

  validateInputs = () => {
    const { customerFullName, phoneNumber } = this.state;
    let validations = {
      isFullNameValid: true,
      isPhoneNumberValid: true,
    };

    let isValid = true;

    if (!customerFullName || customerFullName.length <= 3) {
      validations.isFullNameValid = false;
      isValid = false;
    }

    if (
      !phoneNumber ||
      phoneNumber.length <= 0 ||
      !Validator.isValidPhone(phoneNumber)
    ) {
      validations.isPhoneNumberValid = false;
      isValid = false;
    }

    if (!isValid) {
      this.setState({
        isFullNameValid: validations.isFullNameValid,
        isPhoneNumberValid: validations.isPhoneNumberValid,
      });
    }

    return isValid;
  };

  onPressedUpdate = async () => {
    if (!this.validateInputs()) return;
    const { customer } = this.props;
    if (customer === undefined) return;
    const { customerFullName, phoneNumber } = this.state;
    let updateResult = await CustomerService.updatePersonalInfo(
      {
        fullName: customerFullName,
        phoneNumber: phoneNumber,
      },
      customer.id,
      customer.token.token
    );

    if (updateResult.isSuccess) {
      this.updateCustomerInfo();
      this.showAlertModal("Your profile info updated successfully");
    } else {
      this.showAlertModal(updateResult.message);
    }
  };

  render() {
    const { className } = this.props;
    const {
      customerFullName,
      isFullNameValid,
      email,
      phoneNumber,
      isPhoneNumberValid,
      showAlert,
      alertText,
    } = this.state;
    return (
      <PageLayout>
        <InnerLayout hideBasket={true} className={className + " wa-profile"}>
          <Alert
            show={showAlert}
            bodyText={alertText}
            okButtonText="OK"
            onPressOk={this.onCloseModal}
          />
          <span
            className="wa-profile-label"
            style={{ color: Theme.color.title }}
          >
            Personal details:
          </span>
          <div
            className="wa-profile-detail"
            style={{ backgroundColor: Theme.color.containerBackground }}
          >
            <FullNameInput
              label="Full Name"
              invalid={!isFullNameValid}
              defaultValue={customerFullName}
              onChange={(text) => this.setState({ customerFullName: text })}
            />
            <EmailInput
              name="em-profile"
              disabled={true}
              label="E-Mail"
              defaultValue={email}
            />
            <PhoneNumberInput
              label="Phone"
              invalid={!isPhoneNumberValid}
              defaultValue={phoneNumber}
              onChange={(text) => this.setState({ phoneNumber: text })}
            />
          </div>
          <NavLink
            to="/change-password"
            className="wa-profile-detail-cp"
            style={{
              color: Theme.color.title,
              backgroundColor: Theme.color.containerBackground,
            }}
          >
            <Lock
              className="wa-pdcp-icon"
              htmlColor={Theme.color.touchableIcon}
            />
            <span className="wa-pdcp-label">Change Password</span>
          </NavLink>

          <Button
            text="UPDATE"
            className="wa-pd-button"
            onClick={this.onPressedUpdate}
          />
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect((state: ApplicationState) => state.customer, {
    ...RCustomerStore.actionCreators,
  })(Profile)
);
