import * as React from "react";
import "./index.css";
import { Search } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";

type MenuSearchProps = {
  className: string;
  onChange: (text: string) => void;
};

class MenuSearch extends React.Component<MenuSearchProps> {
  public static defaultProps = {
    className: "",
  };

  componentDidMount = () => {
    const { onChange } = this.props;
    onChange("");
  };

  render() {
    const { className, onChange } = this.props;
    return (
      <div
        className={className + " input-group wa-menu-search-container"}
        style={{
          backgroundColor: Theme.color.containerBackground,
          color: Theme.color.passive,
          borderColor: Theme.color.passive,
        }}
      >
        <div className="wa-menu-search-icon">
          <Search />
        </div>
        <input
          type="text"
          style={{
            borderColor: Theme.color.passive,
            color: Theme.color.passive,
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          maxLength={20}
          className="wa-menu-search-input"
          placeholder="Search"
        />
      </div>
    );
  }
}

export default MenuSearch;
