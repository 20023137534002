import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import { PaymentType, PaymentEnvironment } from "../../../types/payment";
import OrderPaymentType from "../../../components/OrderPaymentType";
import { OrderType } from "../../../types/order";
import { RestaurantPaymentType } from "types/restaurant";
import { RestaurantService } from "services";
import { SystemStatus } from "types/system";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "store";

type OrderSelectPaymentTypeProps = {
  className: string;
  show: boolean;
  orderType: OrderType;
  onHide: () => void;
  onChange: (
    paymentType: PaymentType,
    paymentEnvironment: PaymentEnvironment
  ) => void;
} & RCustomerStore.CustomerState;

type OrderSelectPaymentTypeState = {
  showLoading: boolean;
  paymentTypes: RestaurantPaymentType[];
};

class OrderSelectPaymentType extends React.Component<
  OrderSelectPaymentTypeProps,
  OrderSelectPaymentTypeState
> {
  public state = {
    paymentTypes: [] as RestaurantPaymentType[],
    showLoading: true,
  };

  public static defaultProps = {
    className: "",
    show: false,
  };

  componentDidMount = async () => {
    let paymentTypes = await this.getPaymentTypes();
    this.setState({ showLoading: false, paymentTypes: paymentTypes });
  };

  getPaymentTypes = async (): Promise<RestaurantPaymentType[]> => {
    const { orderType, customer } = this.props;
    if (!customer || !customer.id) return [] as RestaurantPaymentType[];
    let orderTypeResult = await RestaurantService.getRestaurantPaymentTypes(
      orderType,
      customer.token.token
    );
    if (orderTypeResult.isSuccess)
      return orderTypeResult.data.filter(
        (item) => item.status === SystemStatus.Active
      );
    else return [] as RestaurantPaymentType[];
  };

  render() {
    const { className, orderType, show, onHide, onChange } = this.props;
    const { paymentTypes, showLoading } = this.state;
    const activePaymentTypes = {
      squareUp: paymentTypes.find(
        (item) => item.paymentType === PaymentType.SquareUp
      ),
      cash: paymentTypes.find((item) => item.paymentType === PaymentType.Cash),
      creditCard: paymentTypes.find(
        (item) => item.paymentType === PaymentType.CreditCard
      ),
    };
    return (
      <ModalContainer
        show={show}
        className={className + " wa-order-select-payment-type"}
        showHeader={true}
        onHide={onHide}
        showLoading={showLoading}
        headerText={"Payment Type"}
      >
        {activePaymentTypes.squareUp && (
          <>
            <span className="wa-ospt-title">Online:</span>
            <OrderPaymentType
              orderType={orderType}
              className="wa-ospt-item"
              paymentType={PaymentType.SquareUp}
              onClick={() =>
                onChange(
                  PaymentType.SquareUp,
                  activePaymentTypes.squareUp
                    ? activePaymentTypes.squareUp.paymentEnvironment
                    : PaymentEnvironment.None
                )
              }
            />
          </>
        )}
        {(activePaymentTypes.cash || activePaymentTypes.creditCard) && (
          <>
            <span className="wa-ospt-title">Other:</span>
            {activePaymentTypes.cash && (
              <OrderPaymentType
                orderType={orderType}
                className="wa-ospt-item"
                paymentType={PaymentType.Cash}
                onClick={() =>
                  onChange(
                    PaymentType.Cash,
                    activePaymentTypes.cash
                      ? activePaymentTypes.cash.paymentEnvironment
                      : PaymentEnvironment.None
                  )
                }
              />
            )}
            {activePaymentTypes.creditCard && (
              <OrderPaymentType
                orderType={orderType}
                className="wa-ospt-item"
                paymentType={PaymentType.CreditCard}
                onClick={() =>
                  onChange(
                    PaymentType.CreditCard,
                    activePaymentTypes.creditCard
                      ? activePaymentTypes.creditCard.paymentEnvironment
                      : PaymentEnvironment.None
                  )
                }
              />
            )}
          </>
        )}
      </ModalContainer>
    );
  }
}

export default connect((state: ApplicationState) => state.customer)(
  OrderSelectPaymentType
);
