import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import {
  RestaurantWorkingHour,
  RestaurantInfo,
  RestaurantTable,
  RestaurantOrderType,
  RestaurantPaymentType,
  RestaurantStatus,
  RestaurantOrderTypeStatus,
} from "../types/restaurant";
import { ApiResponse } from "../types/response";
import { OrderType, OrderLimit, OrderAvailableDate } from "../types/order";

export async function getRestaurantStatus(): Promise<
  ApiResponse<RestaurantStatus>
> {
  return await apiRequest<RestaurantStatus>({
    url: `restaurant/status`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantOrderTypeStatus(
  orderType: OrderType
): Promise<ApiResponse<RestaurantOrderTypeStatus>> {
  return await apiRequest<RestaurantOrderTypeStatus>({
    url: `restaurant/order-type-status?orderType=${orderType}`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantScheduledOrderAvailableTimes(
  orderType: OrderType
) {
  return await apiRequest<OrderAvailableDate[]>({
    url: `restaurant/scheduled-order-available-times?orderType=${orderType}`,
    options: {
      method: HttpRequestMethod.GET,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getTodaysOrderTypeWorkingHours(orderType: OrderType) {
  return await apiRequest<RestaurantWorkingHour>({
    url: `restaurant/todays-order-type-working-hours?orderType=${orderType}`,
    options: {
      method: HttpRequestMethod.GET,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function checkOrderAsapAvailable(orderType: OrderType) {
  return await apiRequest<boolean>({
    url: `restaurant/check-order-asap-available?orderType=${orderType}`,
    options: {
      method: HttpRequestMethod.GET,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantPaymentTypes(
  orderType: OrderType,
  token: string
): Promise<ApiResponse<RestaurantPaymentType[]>> {
  return await apiRequest<RestaurantPaymentType[]>({
    url: `restaurant/payment-types?orderType=${orderType}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantOrderTypes(): Promise<
  ApiResponse<RestaurantOrderType[]>
> {
  return await apiRequest<RestaurantOrderType[]>({
    url: `restaurant/order-types`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantInformations(): Promise<
  ApiResponse<RestaurantInfo[]>
> {
  return await apiRequest<RestaurantInfo[]>({
    url: `restaurant/info-list`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantWorkingDays(): Promise<
  ApiResponse<RestaurantWorkingHour[]>
> {
  return await apiRequest<RestaurantWorkingHour[]>({
    url: `restaurant/working-times`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantTable(
  token: string,
  code: string
): Promise<ApiResponse<RestaurantTable>> {
  return await apiRequest<RestaurantTable>({
    url: `restaurant/table?code=${code}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantTables(
  token: string
): Promise<ApiResponse<RestaurantTable[]>> {
  return await apiRequest<RestaurantTable[]>({
    url: `restaurant/table-list`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getRestaurantOrderLimits(): Promise<
  ApiResponse<OrderLimit[]>
> {
  return await apiRequest<OrderLimit[]>({
    url: `restaurant/order-limits`,
    options: {
      method: HttpRequestMethod.GET,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}
