import * as React from "react";
import "./index.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Theme } from "../../infrastructure/config";
import Loading from "../Loading";

type ModalContainerProps = {
  className: string;
  show: boolean;
  onHide: () => void;
  headerText: string;
  showHeader: boolean;
  footerContent: React.ReactNode;
  showFooter: boolean;
  showLoading: boolean;
};

class ModalContainer extends React.Component<ModalContainerProps> {
  public static defaultProps = {
    className: "",
    show: false,
    onHide: () => {},
    headerText: "",
    showHeader: false,
    showFooter: false,
    footerContent: undefined,
    showLoading: false,
  };

  render() {
    const {
      className,
      children,
      show,
      onHide,
      showHeader,
      headerText,
      showFooter,
      footerContent,
      showLoading,
    } = this.props;
    const closeBtn = (
      <button
        style={{ color: Theme.color.onPrimary }}
        className="wa-mc"
        onDoubleClick={(e) => {
          e.preventDefault();
          onHide();
        }}
        onClick={(e) => {
          e.preventDefault();
          onHide();
        }}
      >
        &times;
      </button>
    );
    return (
      <Modal
        centered={true}
        isOpen={show}
        contentClassName="wa-mc-content"
        scrollable={true}
        toggle={onHide}
        fade={false}
      >
        {showHeader && (
          <ModalHeader
            className="wa-mc-header"
            style={{
              backgroundColor: Theme.color.primary,
              color: Theme.color.onPrimary,
            }}
            toggle={onHide}
            close={closeBtn}
          >
            {headerText}
          </ModalHeader>
        )}
        <ModalBody
          className={"wa-mc-body " + className}
          style={{ backgroundColor: Theme.color.background }}
        >
          <Loading className="wa-mc-loading" show={showLoading}>
            {children}
          </Loading>
        </ModalBody>
        {showFooter && (
          <ModalFooter
            className="wa-mc-footer"
            style={{ backgroundColor: Theme.color.background }}
          >
            {footerContent}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default ModalContainer;
