import * as React from "react";
import "./index.css";
import {
  AccountBalance,
  AccountBalanceWallet,
  KeyboardArrowRight,
  CreditCard,
} from "@material-ui/icons";
import { Theme } from "../../infrastructure/config";

type PaymentTypeItemProps = {
  className: string;
  title: string;
  description: string;
  iconType: PaymentIconType;
  onClick: () => void;
};

export enum PaymentIconType {
  AccountBalance,
  AccountBalanceWallet,
  CreditCard,
}

class PaymentTypeItem extends React.Component<PaymentTypeItemProps> {
  public static defaultProps = {
    className: "",
    description: "",
    onClick: () => {},
  };

  renderIcon = () => {
    const { iconType } = this.props;
    switch (iconType) {
      case PaymentIconType.AccountBalance:
        return (
          <AccountBalance
            fontSize={"large"}
            className="wa-pt-selection-li"
            htmlColor={Theme.color.title}
          />
        );
      case PaymentIconType.AccountBalanceWallet:
        return (
          <AccountBalanceWallet
            fontSize={"large"}
            className="wa-pt-selection-li"
            htmlColor={Theme.color.title}
          />
        );
      case PaymentIconType.CreditCard:
        return (
          <CreditCard
            fontSize={"large"}
            className="wa-pt-selection-li"
            htmlColor={Theme.color.title}
          />
        );
    }
  };
  render() {
    const { className, title, description, onClick } = this.props;
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className={className + " wa-pt-selection"}
        style={{
          backgroundColor: Theme.color.containerBackground,
          borderColor: Theme.color.inputBorder,
        }}
      >
        <this.renderIcon />
        <div className="wa-pt-selection-body">
          <span
            className="wa-pt-selection-title"
            style={{
              color: description ? Theme.color.title : Theme.color.secondary,
            }}
          >
            {title}
          </span>
          <span
            className="wa-pt-selection-detail"
            style={{ color: Theme.color.title }}
          >
            {description}
          </span>
        </div>
        <KeyboardArrowRight
          fontSize={"large"}
          className="wa-pt-selection-ri"
          htmlColor={Theme.color.touchableIcon}
        />
      </button>
    );
  }
}

export default PaymentTypeItem;
