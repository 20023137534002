import * as React from "react";
import "./index.css";
import { Product, SelectedOption } from "../../../types/product";
import ProductDetailBody from "./components/ProductDetailBody";
import { ShoppingCartService, ProductService } from "../../../services";
import {
  ShoppingCartItemOption,
  ShoppingCart,
} from "../../../types/shoppingCart";
import { OrderStore } from "../../../infrastructure/store";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  ROrderStore,
  RShoppingCartStore,
} from "../../../store";

type ProductDetailProps = {
  className: string;
  show: boolean;
  id: string;
  onHide: () => void;
} & RCustomerStore.CustomerState &
  ROrderStore.OrderState &
  typeof RCustomerStore.actionCreators &
  typeof RShoppingCartStore.actionCreators;

type ProductDetailState = {
  product: Product;
  quantity: number;
  selectedExtras: SelectedOption[];
};

class ProductDetail extends React.Component<
  ProductDetailProps,
  ProductDetailState
> {
  public static defaultProps = {
    className: "",
    show: false,
  };

  public state = {
    product: {} as Product,
    quantity: 1,
    selectedExtras: [] as SelectedOption[],
  };

  componentDidMount = async () => {
    const { show, onHide } = this.props;
    if (!show) return;
    let product = await this.getProduct();
    if (product) {
      this.setState({
        product: product,
      });
    } else {
      onHide();
    }
  };

  getProduct = async (): Promise<Product | undefined> => {
    const { id, selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let productResult = await ProductService.getProduct(orderProductGroup, id);

    if (productResult.isSuccess) return productResult.data;
    else return undefined;
  };

  onChangeQuantity = (quantity: number) => {
    this.setState({ quantity: quantity });
  };

  onChangeExtras = (selectedExtras: SelectedOption[]) => {
    this.setState({ selectedExtras: selectedExtras });
  };

  addToShoppingCart = async () => {
    const { onHide } = this.props;
    onHide();
    const { product, quantity, selectedExtras } = this.state;
    const { customer, updateShoppingCart, selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let addResult = await ShoppingCartService.addToShoppingCart(
      {
        productId: product.id,
        quantity: quantity,
        shoppingCartItemOptions: selectedExtras.map(
          (x) =>
            ({
              productOptionItemId: x.productOptionItemId,
              quantity: x.quantity,
            } as ShoppingCartItemOption)
        ),
        orderProductGroup: orderProductGroup,
      },
      customer === undefined ? "" : customer.id,
      customer === undefined ? "" : customer.token.token
    );

    if (addResult.isSuccess) {
      let shoppingCart = await this.getShoppingCart(
        customer === undefined ? "" : customer.id,
        customer === undefined ? "" : customer.token.token
      );

      if (shoppingCart) updateShoppingCart(shoppingCart);
    }
  };

  getShoppingCart = async (
    customerId: string,
    token: string
  ): Promise<ShoppingCart | undefined> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let shoppingCartResult = await ShoppingCartService.getShoppingCart(
      customerId,
      orderProductGroup,
      selectedOrderType,
      token
    );

    if (shoppingCartResult.isSuccess) return shoppingCartResult.data;
    else return undefined;
  };

  render() {
    const { className, onHide, show } = this.props;
    const { product, quantity } = this.state;

    return product !== undefined &&
      product !== null &&
      product.price !== undefined ? (
      <ProductDetailBody
        buttonText={"ADD TO BASKET"}
        headerText={"Product Details"}
        show={show}
        className={className}
        onHide={onHide}
        product={product}
        quantity={quantity}
        onChangeQuantity={this.onChangeQuantity}
        onChangeExtras={this.onChangeExtras}
        onSubmit={this.addToShoppingCart}
      />
    ) : (
      <></>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
    ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
      ROrderStore.OrderState),
  {
    ...RCustomerStore.actionCreators,
    ...RShoppingCartStore.actionCreators,
  }
)(ProductDetail);
