import ReactPixel from "react-facebook-pixel";
import { Settings } from "../config";

export default class FacebookPixel {
  private trackPageview: boolean = true;
  private email: string = "";

  private options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  isActive() {
    return Settings.marketing.facebookPixel.isActive;
  }

  notTrackPageView() {
    this.trackPageview = false;
  }

  setEmail(email: string) {
    this.email = email;
  }

  track() {
    if (!this.isActive()) return;
    this.init();

    if (this.trackPageview) ReactPixel.pageView();
  }

  private advancedMatching(): ReactPixel.AdvancedMatching {
    return {
      em: this.email,
    } as ReactPixel.AdvancedMatching;
  }

  private init() {
    ReactPixel.init(
      Settings.marketing.facebookPixel.id,
      this.advancedMatching(),
      this.options
    );
  }
}
