import React from "react";
import "./index.css";

import SelectOrderTypeButton from "../select-order-type-button";
import Icon from "react-icons-kit";
import { ic_restaurant } from "react-icons-kit/md/ic_restaurant";

export interface EatInButtonProps {}

class EatInButton extends React.Component<EatInButtonProps> {
  public render() {
    return <SelectOrderTypeButton text='Eat In' icon={<Icon icon={ic_restaurant} size={22} />} />;
  }
}

export default EatInButton;
