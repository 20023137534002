import * as React from "react";
import "./index.css";
import Basket from "../partial/InnerLayout/components/RightArea/Basket";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PageLayout from "../partial/PageLayout";

type ShoppingCartProps = {
  className: string;
};

class ShoppingCart extends React.Component<ShoppingCartProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;
    return (
      <PageLayout>
        <div className={className + " wa-shopping-cart"}>
          <Basket
            showEmptyBasket={true}
            className="wa-sc-basket"
            useFullHeight={true}
            innerContainerClassName="wa-full-height"
          />
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(connect()(ShoppingCart));
