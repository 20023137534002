import * as React from "react";
import { Route, Switch } from "react-router";
import {
  Home,
  NotFound,
  ApproveBasket,
  OrderSummary,
  ApproveAccount,
  ResetPassword,
  ShoppingCart,
  Menu,
  Profie,
  AddressList,
  OrderHistory,
  AboutUs,
  ContactUs,
  ChangePassword,
  SelectOrderTypePage,
  LandingPageEatInTableSelected,
} from "./pages";
import Layout from "./components/Layout";
import {
  CustomerStore,
  ApplicationStore,
  RestaurantInfoStore,
  OrderStore,
} from "./infrastructure/store";
import {
  RCustomerStore,
  RMainStore,
  ROrderStore,
  ApplicationState,
} from "./store";
import "./custom.css";
import { connect } from "react-redux";
import { Settings } from "./infrastructure/config";
import { Redirect } from "react-router-dom";

type AppProps = RCustomerStore.CustomerState &
  RMainStore.MainState & // ... state we've requested from the Redux store
  ROrderStore.OrderState &
  typeof RCustomerStore.actionCreators &
  typeof RMainStore.actionCreators &
  typeof ROrderStore.actionCreators;

class App extends React.Component<AppProps> {
  componentDidMount = async () => {
    await RestaurantInfoStore.initializeRestaurantInformations();
    await RestaurantInfoStore.initializeResturantWorkingTimes();
    await RestaurantInfoStore.initializeRestaurantOrderLimits();
    CustomerStore.initializeCustomerData();
    ApplicationStore.initializeAppSettings();
    await this.initializeOrderSettings();
    if (ApplicationStore.getVersion() !== Settings.application.version) {
      this.props.signOut();
      CustomerStore.signOut();
      ApplicationStore.setVersion(Settings.application.version);
      ApplicationStore.saveAppSettings();
    }

    if (!CustomerStore.isSignedIn() && !CustomerStore.isGuestSignedIn()) {
      let authResult = await CustomerStore.authanticateGuestCustomer();

      if (!authResult.isSuccess || !authResult.data) {
        this.props.signOut();
        this.props.loadCompleted();
      } else {
        this.props.signInGuest(authResult.data);
        this.props.loadCompleted();
      }
    } else {
      if (CustomerStore.isSignedIn())
        this.props.signIn(CustomerStore.getCustomer());
      else if (CustomerStore.isGuestSignedIn())
        this.props.signInGuest(CustomerStore.getCustomer());
      else this.props.signOut();
      this.props.loadCompleted();
    }
  };

  initializeOrderSettings = async (): Promise<void> => {
    await OrderStore.initializeOrderSettings();
    const { selectOrderType } = this.props;
    selectOrderType(OrderStore.getOrderType());
  };

  render() {
    const { selectedOrderType, isAppLoaded } = this.props;
    return (
      <>
        {!selectedOrderType && Settings.welcomePage.isActive ? (
          <>
            <Route component={SelectOrderTypePage} />
            <Redirect to="/" />
          </>
        ) : (
          <Layout>
            {isAppLoaded && (
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  path="/approve-account/:token?"
                  component={ApproveAccount}
                />
                <Route
                  path="/reset-password/:token?"
                  component={ResetPassword}
                />
                <Route path="/basket" component={ShoppingCart} />
                <Route path="/menu" component={Menu} />
                <Route path="/profile" component={Profie} />
                <Route path="/address-list" component={AddressList} />
                <Route path="/order-history" component={OrderHistory} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/contact-us" component={ContactUs} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/approve" component={ApproveBasket} />
                <Route
                  path="/order-summary/:orderId?"
                  component={OrderSummary}
                />
                <Route
                  path="/eat-in/:tableCode?"
                  component={LandingPageEatInTableSelected}
                />
                <Route component={NotFound} />
                {/* <Route path='/fetch-data/:startDateIndex?' component={FetchData} /> */}
              </Switch>
            )}
          </Layout>
        )}
      </>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState &
    RMainStore.MainState &
    ROrderStore.OrderState =>
    ({
      ...state.customer,
      ...state.main,
      ...state.order,
    } as RCustomerStore.CustomerState &
      RMainStore.MainState &
      ROrderStore.OrderState),
  {
    ...RCustomerStore.actionCreators,
    ...RMainStore.actionCreators,
    ...ROrderStore.actionCreators,
  }
)(App);
