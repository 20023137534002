import * as React from "react";
import "./index.css";
import { Theme, Settings } from "../../infrastructure/config";
import OrderTypeSelection from "./components/OrderTypeSelection";
import AddressSelection from "./components/AddressSelection";
import ApproveBasketSchedule from "./components/ApproveBasketSchedule";
import PaymentTypeSelection from "./components/PaymentTypeSelection";
import OrderNote from "./components/OrderNote";
import PhoneNumber from "./components/PhoneNumber";
import {
  OrderType,
  CreateOrder,
  OrderProductGroupType,
  OrderSelectedAvailableTime,
  OrderAttribute,
  OrderAttributeType,
} from "../../types/order";
import { PaymentType, PaymentEnvironment } from "../../types/payment";
import { CustomerAddress } from "../../types/customerAddress";
import {
  CustomerAddressService,
  RestaurantService,
  OrderService,
  DeliveryLocationService,
} from "../../services";
import {
  ApplicationState,
  RCustomerStore,
  RMainStore,
  ROrderStore,
  RShoppingCartStore,
} from "../../store";
import { connect } from "react-redux";
import {
  CustomerStore,
  OrderStore,
  RestaurantInfoStore,
} from "../../infrastructure/store";
import OrderSummarySticky from "../partial/OrderSummarySticky";
import { withRouter, RouteComponentProps } from "react-router";
import Payment from "../../components/Payment";
import SelectTable from "./components/SelectTable";
import Alert from "../../components/Alert";
import { Validator, TextHelper } from "../../infrastructure/helper";
import {
  RestaurantStatusType,
  RestaurantTable,
  RestaurantOrderType,
  RestaurantStatus,
  RestaurantOrderTypeStatus,
  RestaurantOrderTypeStatusType,
} from "../../types/restaurant";
import { ApplicationSource } from "../../types/system";
import { DeliveryDistance } from "../../types/address";
import PageLayout from "../partial/PageLayout";

type ApproveBasketProps = {
  className: string;
} & RCustomerStore.CustomerState &
  RMainStore.MainState &
  RShoppingCartStore.ShoppingCartState &
  ROrderStore.OrderState &
  typeof ROrderStore.actionCreators &
  typeof RCustomerStore.actionCreators &
  RouteComponentProps;

type ApproveBasketState = {
  orderType: OrderType;
  selectedAddress: CustomerAddress;
  paymentType: PaymentType;
  orderNote: string;
  collcetionAddress: boolean;
  startPayment: boolean;
  deliveryDistanceMessage: string;
  showAlert: boolean;
  showBusyAlert: boolean;
  alertText: string;
  busyAlertText: string;
  redirect: boolean;
  activeOrderTypes: RestaurantOrderType[];
  paymentEnvironment: PaymentEnvironment;
  orderTotal: number;
  eatInAddress: CustomerAddress;
  restaurantTable?: RestaurantTable;
  deliveryDistance: DeliveryDistance;
  asSoonAsPossible: boolean;
  scheduleTime?: OrderSelectedAvailableTime;
  phoneNumber?: string;
};

class ApproveBasket extends React.Component<
  ApproveBasketProps,
  ApproveBasketState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    orderType: OrderType.None,
    selectedAddress: {} as CustomerAddress,
    paymentType: PaymentType.None,
    restaurantTable: {} as RestaurantTable,
    deliveryDistance: {} as DeliveryDistance,
    eatInAddress: {} as CustomerAddress,
    activeOrderTypes: [] as RestaurantOrderType[],
    paymentEnvironment: PaymentEnvironment.None,
    orderTotal: 0.0,
    deliveryDistanceMessage: "",
    collcetionAddress: false,
    orderNote: "",
    startPayment: false,
    showAlert: false,
    alertText: "",
    redirect: false,
    showBusyAlert: false,
    busyAlertText: "",
    asSoonAsPossible: false,
    scheduleTime: undefined as OrderSelectedAvailableTime | undefined,
    phoneNumber: undefined as string | undefined,
  };

  componentDidMount = async () => {
    const { isAppLoaded, isSignedIn, history, shoppingCart } = this.props;
    if (isAppLoaded === false) return;
    if (isSignedIn === false) {
      history.replace("/");
      return;
    }
    if (
      !shoppingCart ||
      !shoppingCart.shoppingCartItems ||
      shoppingCart.shoppingCartItems.length <= 0
    ) {
      history.replace("/");
      return;
    }

    let orderTypes = this.getRestaurantOrderTypes();
    let orderType = this.getOrderType();
    let selectedTable = OrderStore.getTable();
    var asapResult = await this.checkAsSoonAsPossible(orderType);
    this.setState({
      activeOrderTypes: orderTypes,
      restaurantTable: selectedTable,
      orderType: orderType,
      asSoonAsPossible: asapResult,
      scheduleTime: undefined,
    });
    await this.getDefaultAddress();
  };

  componentDidUpdate = (prevProps: ApproveBasketProps) => {
    const { isAppLoaded, isSignedIn, history, customer, shoppingCart } =
      this.props;

    const { eatInAddress, orderType, selectedAddress, activeOrderTypes } =
      this.state;

    if (isAppLoaded === false) return;
    if (isSignedIn === false) {
      history.replace("/");
      return;
    }

    if (
      !shoppingCart ||
      !shoppingCart.shoppingCartItems ||
      shoppingCart.shoppingCartItems.length <= 0
    ) {
      history.replace("/");
      return;
    }

    if (prevProps.customer !== customer) {
      if (
        selectedAddress === undefined ||
        !selectedAddress.id ||
        (orderType === OrderType.EatIn && (!eatInAddress || !eatInAddress.id))
      )
        this.getDefaultAddress();
      if (activeOrderTypes === undefined || activeOrderTypes.length <= 0) {
        let orderTypes = this.getRestaurantOrderTypes();
        let orderType = this.getOrderType();
        let selectedTable = OrderStore.getTable();
        this.setState({
          activeOrderTypes: orderTypes,
          restaurantTable: selectedTable,
          orderType: orderType,
        });
      }
    }
  };

  checkAsSoonAsPossible = async (orderType: OrderType) => {
    let asapResult = await RestaurantService.checkOrderAsapAvailable(orderType);
    const { isSuccess, data } = asapResult;
    if (isSuccess && data) {
      return true;
    } else return false;
  };

  getRestaurantOrderTypes = (): RestaurantOrderType[] => {
    let activeOrderTypes = OrderStore.getActiveOrderTypes();
    let orderProductGroup = OrderStore.getOrderProductGroup();
    let orderTypes = activeOrderTypes.filter((item) =>
      orderProductGroup === OrderProductGroupType.DeliveryTakeaway
        ? item.orderType === OrderType.Delivery ||
          item.orderType === OrderType.Takeaway
        : item.orderType === OrderType.EatIn
    );
    return orderTypes;
  };

  getOrderType = (): OrderType => {
    let orderType = OrderStore.getOrderType();

    return orderType;
  };

  onChangeOrderType = async (type: OrderType) => {
    const { selectOrderType } = this.props;
    OrderStore.setOrderType(type);
    selectOrderType(type);
    var asapResult = await this.checkAsSoonAsPossible(type);
    this.setState({
      orderType: type,
      paymentType: PaymentType.None,
      asSoonAsPossible: asapResult,
    });
  };

  getDefaultAddress = async () => {
    const { customer } = this.props;
    if (customer === undefined) return;
    let addressResult = await CustomerAddressService.getDefaultCustomerAddress(
      customer.id,
      customer.token.token
    );

    if (addressResult.isSuccess) {
      let data = addressResult.data;
      this.setState({ selectedAddress: data, eatInAddress: data });
      await this.handleAddressChange(data);
      this.handleEatInAddressChange(data);
    }
  };

  showAlertModal = (text: string, redirect: boolean = false) => {
    this.setState({ showAlert: true, alertText: text, redirect: redirect });
  };

  showBusyAlertModal = (text: string) => {
    this.setState({ showBusyAlert: true, busyAlertText: text });
  };

  hideBusyAlertModal = () => {
    this.setState({ showBusyAlert: false, busyAlertText: "" });
  };

  onApproveBusyModal = () => {
    this.setState({
      showBusyAlert: false,
      busyAlertText: "",
      startPayment: true,
    });
  };

  onCloseModal = () => {
    const { redirect } = this.state;
    const { history } = this.props;
    if (redirect) {
      this.setState({ showAlert: false, alertText: "", redirect: false });
      history.push("/profile", { from: history.location.pathname });
    } else this.setState({ showAlert: false, alertText: "" });
  };

  handleAddressChange = async (address: CustomerAddress) => {
    const { customer } = this.props;
    if (customer === undefined || !address) return;

    let deliveryDistanceResult =
      await DeliveryLocationService.CheckDeliveryDistance(
        address.postCode,
        customer.token.token
      );

    if (deliveryDistanceResult.isSuccess) {
      this.setState({
        selectedAddress: address,
        deliveryDistance: deliveryDistanceResult.data,
        deliveryDistanceMessage: deliveryDistanceResult.message,
      });
    } else {
      this.showAlertModal(deliveryDistanceResult.message);
    }
  };

  handleEatInAddressChange = (address: CustomerAddress) => {
    this.setState({ eatInAddress: address });
  };

  getScheduleAttributes = (): OrderAttribute[] => {
    const { scheduleTime } = this.state;
    if (!scheduleTime) return [];
    else
      return [
        {
          type: OrderAttributeType.MaxOrderScheduleDateTime,
          value: scheduleTime.maxCompleteTime,
        },
        {
          type: OrderAttributeType.MinOrderScheduleDateTime,
          value: scheduleTime.minCompleteTime,
        },
      ];
  };

  onCompletePayment = async (orderId?: string, paymentHistoryId?: string) => {
    this.setState({ startPayment: false });
    const {
      orderType,
      restaurantTable,
      orderNote,
      paymentType,
      selectedAddress,
      deliveryDistance,
      eatInAddress,
      paymentEnvironment,
      phoneNumber,
    } = this.state;
    const { customer, selectedOrderType, updatePhone } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    if (
      customer === undefined ||
      (orderType === OrderType.Delivery &&
        (selectedAddress === undefined || !selectedAddress.id)) ||
      (orderType === OrderType.Delivery &&
        (deliveryDistance === undefined || !deliveryDistance.id)) ||
      (orderType === OrderType.EatIn &&
        (eatInAddress === undefined || !eatInAddress.id))
    )
      return;

    let orderResult = await OrderService.createOrder(
      {
        note: orderNote,
        type: orderType,
        paymentHistoryId: paymentHistoryId,
        orderId: orderId,
        source: ApplicationSource.Web,
        orderAttributes: this.getScheduleAttributes(),
        paymentType: paymentType,
        phoneNumber: phoneNumber,
        deliveryAddressId:
          orderType === OrderType.Delivery ? selectedAddress.id : undefined,
        restaurantTableId: restaurantTable ? restaurantTable.id : undefined,
        eatInAddressId:
          orderType === OrderType.EatIn ? eatInAddress.id : undefined,
        paymentEnvironment: paymentEnvironment,
        orderProductGroup: orderProductGroup,
      } as CreateOrder,
      orderType === OrderType.Delivery ? deliveryDistance.id : "",
      customer.id,
      customer.token.token
    );

    if (orderResult.isSuccess) {
      if (phoneNumber) {
        CustomerStore.updatePhoneNumber(phoneNumber);
        updatePhone(phoneNumber);
      }
      this.props.history.replace(`order-summary/${orderResult.data}`);
    } else {
      this.showAlertModal(orderResult.message);
    }
  };

  onTableSelect = (restaurantTable: RestaurantTable) => {
    OrderStore.setTable(restaurantTable);
    this.setState({ restaurantTable: restaurantTable });
  };

  onErrorPayment = () => {
    this.setState({ startPayment: false });
  };

  onCancelPayment = () => {
    this.setState({ startPayment: false });
  };

  // onPhoneChange = (phone: string) => {
  //   phone.length === 11 ? this.setState({ phoneNumber: phone }) : this.setState({ phoneNumber: "" });
  // };

  validateInputs = (): { message: string; isSuccess: boolean } => {
    const {
      orderType,
      restaurantTable,
      selectedAddress,
      paymentType,
      deliveryDistance,
      deliveryDistanceMessage,
      collcetionAddress,
      eatInAddress,
      asSoonAsPossible,
      scheduleTime,
      phoneNumber,
    } = this.state;

    if (
      orderType === OrderType.Delivery &&
      (!deliveryDistance ||
        !deliveryDistance.id ||
        !deliveryDistance.isAvailable)
    ) {
      return {
        message:
          deliveryDistanceMessage ||
          "Please, make sure all the information you have given is appropriate.",
        isSuccess: false,
      };
    }

    if (
      orderType === OrderType.EatIn &&
      (!restaurantTable || !restaurantTable.id)
    ) {
      return { message: "Please select a table", isSuccess: false };
    }

    if (
      orderType !== OrderType.Takeaway &&
      orderType !== OrderType.Delivery &&
      orderType !== OrderType.EatIn
    ) {
      return { message: "Please select an order type.", isSuccess: false };
    }

    if (
      orderType === OrderType.Delivery &&
      (selectedAddress === undefined || !selectedAddress.id)
    ) {
      return { message: "Please select a delivery address.", isSuccess: false };
    }

    if (orderType === OrderType.EatIn && (!eatInAddress || !eatInAddress.id)) {
      return { message: "Please select an address", isSuccess: false };
    }

    if (
      paymentType !== PaymentType.CreditCard &&
      paymentType !== PaymentType.Cash &&
      paymentType !== PaymentType.SquareUp
    ) {
      return { message: "Please select a payment type.", isSuccess: false };
    }

    if (!asSoonAsPossible && !scheduleTime)
      return {
        isSuccess: false,
        message: "Please, select a time.",
      };

    if (
      orderType === OrderType.Takeaway &&
      !Validator.isValidPhone(CustomerStore.getPhoneNumber()) &&
      (!phoneNumber || !Validator.isValidPhone(phoneNumber))
    ) {
      return {
        isSuccess: false,
        message: "Please, insert a valid phone number.",
      };
    }

    if (orderType === OrderType.Takeaway && !collcetionAddress) {
      return {
        message: "Please confirm your collection address.",
        isSuccess: false,
      };
    }

    return { message: "", isSuccess: true };
  };

  onSubmit = async () => {
    const { orderType } = this.state;
    let validationResult = this.validateInputs();
    if (validationResult.isSuccess === false) {
      this.showAlertModal(validationResult.message);
      this.setState({ startPayment: false });
    } else {
      let restaurantStatus = await this.getRestaurantStatus();
      if (
        restaurantStatus &&
        restaurantStatus.status === RestaurantStatusType.Closed
      ) {
        this.showAlertModal(
          restaurantStatus.statusMessage
            ? restaurantStatus.statusMessage.message
            : `Sorry, we can not take any orders for now.`
        );
        return;
      }

      let restaurantOrderTypeStatus = await this.getOrderTypeStatus();
      if (
        restaurantOrderTypeStatus &&
        restaurantOrderTypeStatus.status ===
          RestaurantOrderTypeStatusType.Closed
      ) {
        this.showAlertModal(
          restaurantOrderTypeStatus.statusMessage
            ? restaurantOrderTypeStatus.statusMessage.message
            : `Sorry, we can not take any ${TextHelper.getOrderTypeText(
                orderType
              ).toLowerCase()} orders for now.`
        );
        return;
      }

      if (
        restaurantOrderTypeStatus &&
        restaurantOrderTypeStatus.status === RestaurantOrderTypeStatusType.Busy
      ) {
        this.showBusyAlertModal(
          (restaurantOrderTypeStatus.statusMessage
            ? restaurantOrderTypeStatus.statusMessage.message
            : `We are very busy for the ${TextHelper.getOrderTypeText(
                orderType
              )} orders for now`) + ". Do you wish to continue?"
        );
        return;
      }

      if (
        restaurantStatus &&
        restaurantStatus.status === RestaurantStatusType.Busy
      ) {
        this.showBusyAlertModal(
          (restaurantStatus.statusMessage
            ? restaurantStatus.statusMessage.message
            : `We are very busy for now`) + ". Do you wish to continue?"
        );
        return;
      }

      if (
        restaurantStatus &&
        restaurantStatus.status === RestaurantStatusType.Open &&
        restaurantOrderTypeStatus &&
        restaurantOrderTypeStatus.status === RestaurantOrderTypeStatusType.Open
      ) {
        this.setState({ startPayment: true });
      }
    }
  };

  getRestaurantStatus = async (): Promise<RestaurantStatus | undefined> => {
    let result = await RestaurantService.getRestaurantStatus();
    if (result.isSuccess) return result.data;
    else return undefined;
  };

  getOrderTypeStatus = async (): Promise<
    RestaurantOrderTypeStatus | undefined
  > => {
    const { orderType } = this.state;
    if (
      orderType !== OrderType.Delivery &&
      orderType !== OrderType.EatIn &&
      orderType !== OrderType.Takeaway
    )
      return undefined;
    let orderTypeStatusResult =
      await RestaurantService.getRestaurantOrderTypeStatus(orderType);

    const { data } = orderTypeStatusResult;
    return data;
  };

  onSelectScheduleTime = (scheduleTime?: OrderSelectedAvailableTime) => {
    this.setState({ scheduleTime: scheduleTime });
  };

  render() {
    const { className } = this.props;
    const {
      orderType,
      paymentType,
      selectedAddress,
      startPayment,
      showAlert,
      orderTotal,
      alertText,
      activeOrderTypes,
      deliveryDistanceMessage,
      deliveryDistance,
      eatInAddress,
      paymentEnvironment,
      restaurantTable,
      showBusyAlert,
      busyAlertText,
      asSoonAsPossible,
      scheduleTime,
    } = this.state;
    return (
      <PageLayout>
        <div className="wa-approve-basket">
          {activeOrderTypes && activeOrderTypes.length > 0 ? (
            <>
              <Alert
                show={showAlert}
                okButtonText="OK"
                bodyText={alertText}
                onPressOk={this.onCloseModal}
              />
              <Alert
                show={showBusyAlert}
                okButtonText="YES"
                bodyText={busyAlertText}
                onPressOk={this.onApproveBusyModal}
                cancelButtonText="NO"
                onPressCancel={this.hideBusyAlertModal}
                showCancelButton={true}
              />
              <div className={className + " wa-ab-body"}>
                <span
                  className="wa-ab-title"
                  style={{ color: Theme.color.onBackground }}
                >
                  Order Type:
                </span>
                <div className="wa-ab-section">
                  <OrderTypeSelection
                    defaultType={orderType}
                    orderTypes={activeOrderTypes}
                    onChange={this.onChangeOrderType}
                  />
                </div>
                {orderType === OrderType.Delivery && (
                  <>
                    <span
                      className="wa-ab-title"
                      style={{ color: Theme.color.onBackground }}
                    >
                      Delivery Address:
                    </span>
                    <div className="wa-ab-section">
                      <AddressSelection
                        message={deliveryDistanceMessage}
                        onChangeAddress={this.handleAddressChange}
                        selectedAddress={selectedAddress}
                      />
                    </div>
                  </>
                )}

                {orderType === OrderType.EatIn && (
                  <>
                    <span
                      className="wa-ab-title"
                      style={{ color: Theme.color.onBackground }}
                    >
                      Table:
                    </span>
                    <SelectTable
                      selectedTable={restaurantTable}
                      onError={this.showAlertModal}
                      onChange={this.onTableSelect}
                    />
                  </>
                )}

                <span
                  className="wa-ab-title"
                  style={{ color: Theme.color.onBackground }}
                >
                  Payment type:
                </span>
                <div className="wa-ab-section">
                  <PaymentTypeSelection
                    orderType={orderType}
                    onChange={(paymentType, paymentEnvironment) => {
                      this.setState({
                        paymentType: paymentType,
                        paymentEnvironment: paymentEnvironment,
                      });
                    }}
                    paymentType={paymentType}
                  />
                </div>

                {orderType === OrderType.EatIn && (
                  <>
                    <span
                      className="wa-ab-title"
                      style={{ color: Theme.color.onBackground }}
                    >
                      Contact Address:
                    </span>
                    <div className="wa-ab-section">
                      <AddressSelection
                        message=""
                        onChangeAddress={this.handleEatInAddressChange}
                        selectedAddress={eatInAddress}
                      />
                    </div>
                  </>
                )}

                {(orderType === OrderType.Delivery ||
                  orderType === OrderType.Takeaway) && (
                  <ApproveBasketSchedule
                    onSelectScheduleTime={this.onSelectScheduleTime}
                    scheduleTime={scheduleTime}
                    orderType={orderType}
                    asap={asSoonAsPossible}
                  />
                )}

                {orderType === OrderType.Takeaway &&
                  !Validator.isValidPhone(CustomerStore.getPhoneNumber()) && (
                    <PhoneNumber
                      onChange={(phoneNumber) =>
                        this.setState({ phoneNumber: phoneNumber })
                      }
                    />
                  )}
                <OrderNote
                  onChange={(text) => this.setState({ orderNote: text })}
                />
                {orderType === OrderType.Takeaway && (
                  <>
                    <span
                      className="wa-ab-title"
                      style={{ color: Theme.color.onBackground }}
                    >
                      Please confirm your collection address:
                    </span>
                    <div
                      style={{
                        backgroundColor: Theme.color.containerBackground,
                        color: Theme.color.title,
                      }}
                      className="wa-ab-section wa-address-checkbox-wrapper wa-order-note"
                    >
                      <label
                        className="wa-address-checkbox"
                        htmlFor="address-1"
                      >
                        <input
                          onChange={(e) =>
                            this.setState({
                              collcetionAddress: e.target.checked,
                            })
                          }
                          id="address-1"
                          type="checkbox"
                        />
                        <span className="wa-address-checkbox-label">
                          {Settings.restaurant.name},{" "}
                          {RestaurantInfoStore.getAddress()}
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
              <Payment
                deliveryDistanceId={
                  orderType === OrderType.Delivery ? deliveryDistance.id : ""
                }
                orderTotal={orderTotal}
                paymentType={paymentType}
                startPayment={startPayment}
                onComplete={this.onCompletePayment}
                onError={this.onErrorPayment}
                onCancel={this.onCancelPayment}
                environment={paymentEnvironment}
              />
              <OrderSummarySticky
                orderType={orderType}
                deliveryDistanceId={
                  orderType === OrderType.Delivery ? deliveryDistance.id : ""
                }
                buttonText="PLACE ORDER"
                onSubmit={this.onSubmit}
                onOrderFetched={(total) => this.setState({ orderTotal: total })}
                onError={this.showAlertModal}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState &
      RMainStore.MainState &
      RShoppingCartStore.ShoppingCartState &
      ROrderStore.OrderState =>
      ({
        ...state.customer,
        ...state.main,
        ...state.shoppingCart,
        ...state.order,
      } as RCustomerStore.CustomerState &
        RMainStore.MainState &
        RShoppingCartStore.ShoppingCartState &
        ROrderStore.OrderState),
    { ...ROrderStore.actionCreators, ...RCustomerStore.actionCreators }
  )(ApproveBasket)
);
