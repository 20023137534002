import * as React from "react";
import "./index.css";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Theme } from "../../infrastructure/config";

type AlertProps = {
  className: string;
  show: boolean;
  bodyText: string;
  okButtonText: string;
  cancelButtonText: string;
  showCancelButton: boolean;
  onPressHide: () => void;
  onPressOk: () => void;
  onPressCancel: () => void;
};

class Alert extends React.Component<AlertProps> {
  public static defaultProps = {
    className: "",
    show: false,
    showHeader: false,
    headerText: "",
    showCancelButton: false,
    cancelButtonText: "",
    onPressCancel: () => {},
    showHideButton: false,
    onPressHide: () => {},
  };

  render() {
    const {
      className,
      show,
      okButtonText,
      bodyText,
      cancelButtonText,
      showCancelButton,
      onPressHide,
      onPressOk,
      onPressCancel,
    } = this.props;
    return (
      <Modal
        isOpen={show}
        scrollable={false}
        toggle={onPressHide}
        centered={true}
        contentClassName={className + " wa-alert"}
        fade={false}
      >
        <ModalBody
          className="wa-alert-body"
          style={{
            backgroundColor: Theme.color.containerBackground,
            color: Theme.color.title,
          }}
        >
          {bodyText}
        </ModalBody>
        <ModalFooter className="wa-alert-footer">
          {showCancelButton && (
            <button
              className="wa-alert-action cancel"
              style={{
                color: Theme.color.title,
              }}
              onClick={(e) => {
                e.preventDefault();
                onPressCancel();
              }}
            >
              {cancelButtonText}
            </button>
          )}
          <button
            className="wa-alert-action ok"
            style={{
              color: Theme.color.secondary,
            }}
            onClick={(e) => {
              e.preventDefault();
              onPressOk();
            }}
          >
            {okButtonText}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default Alert;
