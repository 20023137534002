import React from "react";
import "./index.css";

import SelectOrderTypeButton from "../select-order-type-button";

export interface ReservationButtonProps {}

class ReservationButton extends React.Component<ReservationButtonProps> {
  public render() {
    return <SelectOrderTypeButton text="Reservation" />;
  }
}

export default ReservationButton;
