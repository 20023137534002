import * as React from "react";
import "./index.css";
import { ShoppingBasket } from "@material-ui/icons";
import { Theme } from "../../../../../../infrastructure/config";

type EmptyBasketProps = {
  className: string;
};

class EmptyBasket extends React.Component<EmptyBasketProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;
    return (
      <div
        className={className + " wa-empty-basket-container"}
        style={{
          color: Theme.color.passive,
          backgroundColor: Theme.color.containerBackground,
        }}
      >
        <ShoppingBasket className="wa-empty-basket-icon" />
        <span className="wa-empty-basket-text">
          It seems like no items in your basket
        </span>
      </div>
    );
  }
}

export default EmptyBasket;
