import { Action, Reducer } from "redux";
import { ShoppingCart } from "../types/shoppingCart";

export interface ShoppingCartState {
  shoppingCart: ShoppingCart | undefined;
  isShoppingCartLoaded: boolean;
}

interface ShoppingCartUpdateAction {
  type: "SHOPPING_CART_UPDATE";
  shoppingCart: ShoppingCart;
}

type KnownAction = ShoppingCartUpdateAction;

export const actionCreators = {
  updateShoppingCart: (shoppingCart: ShoppingCart) =>
    ({
      type: "SHOPPING_CART_UPDATE",
      shoppingCart: shoppingCart,
    } as ShoppingCartUpdateAction),
};

const unloadedState: ShoppingCartState = {
  shoppingCart: {} as ShoppingCart,
  isShoppingCartLoaded: false,
};

export const reducer: Reducer<ShoppingCartState> = (
  state: ShoppingCartState | undefined,
  incomingAction: Action
): ShoppingCartState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SHOPPING_CART_UPDATE":
      return {
        shoppingCart: action.shoppingCart,
        isShoppingCartLoaded: true,
      };
  }

  return state;
};
