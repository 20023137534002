import { Action, Reducer } from "redux";
export interface SignInModalState {
  showSignIn: boolean;
  email: string;
  onHideSignIn: () => void;
  onSuccessSignIn: (email: string) => void;
}

export interface ShowModalAction {
  type: "SHOW_SIGNIN_MODAL";
  onHide: () => {};
  onSuccess: (email: string) => void;
  email: string;
}
interface HideModalAction {
  type: "HIDE_SIGNIN_MODAL";
}

type KnownAction = ShowModalAction | HideModalAction;

export const actionCreators = {
  showSignInModal: (
    onHide: () => void,
    onSuccess: (email: string) => void,
    email: string
  ) =>
    ({
      type: "SHOW_SIGNIN_MODAL",
      onHide,
      onSuccess,
      email,
    } as ShowModalAction),
  hideSignInModal: () => ({ type: "HIDE_SIGNIN_MODAL" } as HideModalAction),
};

const unloadedSignInState: SignInModalState = {
  showSignIn: false,
  onHideSignIn: () => {},
  onSuccessSignIn: () => {},
  email: "",
};

export const signInReducer: Reducer<SignInModalState> = (
  state: SignInModalState | undefined,
  incomingAction: Action
): SignInModalState => {
  if (state === undefined) {
    return unloadedSignInState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SHOW_SIGNIN_MODAL":
      return {
        showSignIn: true,
        onHideSignIn: action.onHide,
        onSuccessSignIn: action.onSuccess,
        email: action.email,
      } as SignInModalState;
    case "HIDE_SIGNIN_MODAL":
      return {
        showSignIn: false,
        onHideSignIn: () => {},
        onSuccessSignIn: () => {},
        email: "",
      } as SignInModalState;
  }

  return state;
};
