import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import { Campaign } from "../types/campaign";
import { OrderProductGroupType } from "types/order";

export async function getActiveCampaigns(
  orderProductGroup: OrderProductGroupType
) {
  return await apiRequest<Campaign[]>({
    url: `campaign/active?orderProductGroup=${orderProductGroup}`,
    options: {
      method: HttpRequestMethod.GET,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}
