import { Customer } from "../../types/customer";
import { TTL } from "../../types/date";

export function saveData<T>(data: T, key: string): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  } catch {
    return false;
  }
}
export function saveDataWithExpiry<T>(data: T, key: string, ttl: TTL) {
  const now = new Date();
  try {
    const item = {
      data: data,
      expiry: now.getTime() + ttl,
    } as StoreData<T>;
    localStorage.setItem(key, JSON.stringify(item));
    return true;
  } catch {
    return false;
  }
}

export function getData<T>(key: string): T {
  try {
    let data = localStorage.getItem(key);
    return JSON.parse(data === null ? "" : data) as T;
  } catch {
    return {} as T;
  }
}

export function getDataWithExpiry<T>(key: string): T {
  try {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return {} as T;
    }
    const item = JSON.parse(itemStr === null ? "" : itemStr) as StoreData<T>;
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return {} as T;
    }
    return item.data;
  } catch {
    return {} as T;
  }
}

interface StoreData<T> {
  data: T;
  expiry: number;
}

export interface AuthResult {
  data?: Customer;
  isSuccess: boolean;
  message: string;
}
