export function isFunction(functionToCheck: Function): boolean {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export function isValidEmail(email: string): boolean {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

export function isValidPostCode(postcode: string): boolean {
  if (
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/.test(
      postcode
    )
  ) {
    return true;
  }
  return false;
}

export function isValidPhone(phone: string): boolean {
  if (/^(0044|0|\+?44)[12378]\d{8,9}$/.test(phone)) {
    return true;
  }
  return false;
}

export function isValidPassword(password: string): boolean {
  if (
    password &&
    typeof password === "string" &&
    password !== "" &&
    password.trim() !== "" &&
    password.length > 3
  ) {
    return true;
  } else {
    return false;
  }
}
