import { DayOfWeekType, TimeSpan } from "../date";
import { OrderType } from "types/order";
import { PaymentType, PaymentEnvironment } from "types/payment";
import { SystemStatus } from "types/system";
import { StatusMessage } from "types/common";

export enum RestaurantStatusType {
  None = 0,
  Initial = 1,
  Open = 2,
  Busy = 3,
  Closed = 4,
}

export interface RestaurantInfo {
  infoType: RestaurantInfoType;
  value: string;
}

export interface RestaurantWorkingHour {
  isClosed: boolean;
  openingTime: TimeSpan;
  closingTime: TimeSpan;
  dayOfWeek: DayOfWeekType;
}

export enum RestaurantInfoType {
  None = 0,
  AboutUsTitle = 1,
  AboutUsText = 2,
  Address = 3,
  Location = 4,
  WebSiteText = 5,
  WebSite = 6,
  ContactMailText = 7,
  ContactMail = 8,
  PhoneText = 9,
  Phone = 10,
  DeliveryTime = 11,
  TakeawayTime = 12,
  EatInTime = 13,
}

export interface RestaurantTable {
  id: string;
  code: string;
  name: string;
  prefix: string;
}

export interface RestaurantOrderType {
  orderType: OrderType;
  isDefault: boolean;
  status: SystemStatus;
  minCompleteTime: number;
  maxCompleteTime: number;
}

export interface RestaurantPaymentType {
  orderType: OrderType;
  paymentType: PaymentType;
  paymentEnvironment: PaymentEnvironment;
  status: SystemStatus;
}

export interface RestaurantStatus {
  status: RestaurantStatusType;
  statusMessage: StatusMessage;
}

export interface RestaurantOrderTypeStatus {
  status: RestaurantOrderTypeStatusType;
  statusMessage: StatusMessage;
}

export enum RestaurantOrderTypeStatusType {
  None = 0,
  Open = 1,
  Busy = 2,
  Closed = 3,
}
