import * as React from "react";
import "./index.css";
import {
  EmailInput,
  PasswordInput,
  FullNameInput,
} from "../../../components/Input";
import { Validator } from "../../../infrastructure/helper";
import { CustomerService } from "../../../services";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button";

type SignUpBodyProps = {
  className: string;
  onSuccess: (email: string) => void;
};

type SignUpBodyState = {
  fullName: string;
  isFullNameValid: boolean;
  email: string;
  isEmailValid: boolean;
  password: string;
  isPasswordValid: boolean;
  showAlert: boolean;
  alertMessage: string;
  isSuccess: boolean;
};

class SignUpBody extends React.Component<SignUpBodyProps, SignUpBodyState> {
  public static defaultProps = {
    className: "",
    onSuccess: () => {},
  };

  public state = {
    fullName: "",
    isFullNameValid: true,
    email: "",
    isEmailValid: true,
    password: "",
    isPasswordValid: true,
    showAlert: false,
    alertMessage: "",
    isSuccess: false,
  };

  onEmailTextChange = (value: string) => {
    if (!this.state.isEmailValid)
      this.setState({ isEmailValid: true, email: value });
    else this.setState({ email: value });
  };

  onPasswordTextChange = (value: string) => {
    if (!this.state.isPasswordValid)
      this.setState({ isPasswordValid: true, password: value });
    else this.setState({ password: value });
  };

  onFullNameTextChange = (value: string) => {
    if (!this.state.isFullNameValid)
      this.setState({ isFullNameValid: true, fullName: value });
    else this.setState({ fullName: value });
  };

  showAlert = (message: string) => {
    this.setState({ showAlert: true, alertMessage: message });
  };

  hideAlert = () => {
    const { isSuccess, email } = this.state;
    const { onSuccess } = this.props;
    if (isSuccess) {
      onSuccess(email);
      this.setState({
        showAlert: false,
        alertMessage: "",
        email: "",
        password: "",
        fullName: "",
      });
    } else {
      this.setState({
        showAlert: false,
        alertMessage: "",
      });
    }
  };

  onSignUpPressed = async () => {
    if (!this.validateInputs()) return;
    const { email, password, fullName } = this.state;
    let registerResult = await CustomerService.registerCustomer({
      email: email.toLowerCase(),
      password: password,
      fullName: fullName,
    });

    if (registerResult.isSuccess) {
      this.setState({ isSuccess: true });
    }

    this.showAlert(registerResult.message);
  };

  validateInputs = () => {
    let validations = {
      isEmailValid: true,
      isPasswordValid: true,
      isFullNameValid: true,
    };
    const { email, password, fullName } = this.state;
    if (!email || email.trim() === "" || !Validator.isValidEmail(email)) {
      validations.isEmailValid = false;
    }

    if (!password || password.trim() === "" || password.length < 3) {
      validations.isPasswordValid = false;
    }

    if (!fullName || fullName.trim() === "" || fullName.length < 3) {
      validations.isFullNameValid = false;
    }

    if (
      !validations.isEmailValid ||
      !validations.isPasswordValid ||
      !validations.isFullNameValid
    )
      this.setState({
        isFullNameValid: validations.isFullNameValid,
        isEmailValid: validations.isEmailValid,
        isPasswordValid: validations.isPasswordValid,
      });

    return (
      validations.isEmailValid &&
      validations.isPasswordValid &&
      validations.isFullNameValid
    );
  };

  render() {
    const { className } = this.props;
    const {
      fullName,
      isFullNameValid,
      email,
      isEmailValid,
      password,
      isPasswordValid,
      showAlert,
      alertMessage,
    } = this.state;
    return (
      <div className={className + " wa-sign-up-body"}>
        <Alert
          show={showAlert}
          bodyText={alertMessage}
          okButtonText={"OK"}
          onPressOk={this.hideAlert}
        />
        <FullNameInput
          label="Full Name"
          invalid={!isFullNameValid}
          defaultValue={fullName}
          onChange={this.onFullNameTextChange}
        />
        <EmailInput
          name="em-sign-up"
          label="E-mail"
          invalid={!isEmailValid}
          defaultValue={email}
          onChange={this.onEmailTextChange}
        />
        <PasswordInput
          name="pw-sign-up"
          label="Password"
          invalid={!isPasswordValid}
          defaultValue={password}
          onChange={this.onPasswordTextChange}
        />
        <Button text="Sign-Up" onClick={this.onSignUpPressed} />
      </div>
    );
  }
}

export default SignUpBody;
