import * as React from "react";
import "./index.css";
import { Theme } from "../../infrastructure/config";
import { Delete } from "@material-ui/icons";
import Icon from "react-icons-kit";
import { motorcycle } from "react-icons-kit/fa/motorcycle";
import { shoppingBag } from "react-icons-kit/fa/shoppingBag";
import { cutlery } from "react-icons-kit/fa/cutlery";

type ButtonProps = {
  className: string;
  onClick: () => void;
  text: string;
  color:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "delivery"
    | "eatin"
    | "takeaway";
  disabled: boolean;
  iconType?: "trash" | "motorcycle" | "cutlery" | "shoppingBag";
};

const iconStyles = {
  marginTop: "-3px",
  marginRight: "5px",
} as React.CSSProperties;

const primaryStyle: React.CSSProperties = {
  backgroundColor: Theme.color.button,
  color: Theme.color.onButton,
};

const secondaryStyle: React.CSSProperties = {
  backgroundColor: Theme.color.buttonSecondary,
  color: Theme.color.onButtonSecondary,
};

const successStyle: React.CSSProperties = {
  backgroundColor: Theme.color.success,
  color: Theme.color.onButton,
};

const errorStyle: React.CSSProperties = {
  backgroundColor: Theme.color.error,
  color: Theme.color.onButton,
};

const deliveryStyle: React.CSSProperties = {
  backgroundColor: Theme.color.deliveryButton,
  color: Theme.color.onDeliveryButton,
};

const takeawayStyle: React.CSSProperties = {
  backgroundColor: Theme.color.takeawayButton,
  color: Theme.color.onTakeawayButton,
};

const eatInStyle: React.CSSProperties = {
  backgroundColor: Theme.color.eatInButton,
  color: Theme.color.onEatInButton,
};

class Button extends React.Component<ButtonProps> {
  public static defaultProps = {
    className: "",
    color: "primary",
    disabled: false,
    iconType: undefined,
    text: "",
  };

  render() {
    const { className, text, color, disabled, iconType, onClick } = this.props;
    return (
      <button
        type="button"
        className={className + " btn wa-button"}
        value={text}
        onClick={onClick}
        disabled={disabled}
        style={
          color === "primary"
            ? primaryStyle
            : color === "secondary"
            ? secondaryStyle
            : color === "error"
            ? errorStyle
            : color === "delivery"
            ? deliveryStyle
            : color === "eatin"
            ? eatInStyle
            : color === "takeaway"
            ? takeawayStyle
            : successStyle
        }
      >
        {iconType === "trash" && <Delete fontSize="small" />}
        {iconType === "motorcycle" && (
          <Icon
            size={19}
            style={text ? iconStyles : undefined}
            icon={motorcycle}
          />
        )}
        {iconType === "cutlery" && (
          <Icon
            size={16}
            style={text ? iconStyles : undefined}
            icon={cutlery}
          />
        )}
        {iconType === "shoppingBag" && (
          <Icon
            size={16}
            style={text ? iconStyles : undefined}
            icon={shoppingBag}
          />
        )}
        {text ? <span>{text}</span> : <></>}
      </button>
    );
  }
}

export default Button;
