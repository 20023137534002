import * as React from "react";
import "./index.css";
import { Category } from "../../../../../types/category";
import MenuCategoryHeader from "../MenuCategoryHeader";
import MenuItem from "../MenuItem";

type MenuCategoryProps = {
  className: string;
  category: Category;
  expanded: boolean;
  onToggle: () => void;
};

class MenuCategory extends React.Component<MenuCategoryProps> {
  public static defaultProps = {
    className: "",
    expanded: false,
  };

  render() {
    const { className, category, expanded, onToggle } = this.props;
    return (
      <div className={className + " wa-menu-category"}>
        <MenuCategoryHeader
          text={category.name}
          expanded={expanded}
          onPress={onToggle}
        />
        {expanded &&
          category.products.map((product, index) => (
            <MenuItem product={product} key={index} />
          ))}
      </div>
    );
  }
}

export default MenuCategory;
