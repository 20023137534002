import * as React from "react";
import "./index.css";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";

type MenuCategoryHeaderProps = {
  className: string;
  text: string;
  expanded: boolean;
  onPress: () => void;
};

class MenuCategoryHeader extends React.Component<MenuCategoryHeaderProps> {
  public static defaultProps = {
    className: "",
    expanded: false,
  };

  render() {
    const { className, text, expanded, onPress } = this.props;
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          onPress();
        }}
        className={
          (className ? `${className} ` : "") + "wa-menu-category-header"
        }
        style={{
          backgroundColor: Theme.color.containerBackground,
          borderColor: Theme.color.description,
        }}
      >
        <span
          className="wa-menu-category"
          style={{
            color: Theme.color.primaryLighter,
          }}
        >
          {text}
        </span>
        {expanded === false && (
          <ExpandMore
            style={{
              color: Theme.color.primary,
            }}
            className="wa-menu-category-icon"
          />
        )}
        {expanded === true && (
          <ExpandLess
            style={{
              color: Theme.color.primary,
            }}
            className="wa-menu-category-icon"
          />
        )}
      </button>
    );
  }
}

export default MenuCategoryHeader;
