import * as React from "react";
import "./index.css";
import { ButtonGroup, Button } from "reactstrap";
import { OrderType } from "../../../../types/order";
import { Theme } from "../../../../infrastructure/config";
import { RestaurantOrderType } from "types/restaurant";
import { SystemStatus } from "types/system";

type OrderTypeSelectionProps = {
  className: string;
  defaultType: OrderType;
  orderTypes: RestaurantOrderType[];
  onChange: (type: OrderType) => void;
};

type OrderTypeSelectionState = {
  type: OrderType;
};

class OrderTypeSelection extends React.Component<
  OrderTypeSelectionProps,
  OrderTypeSelectionState
> {
  public static defaultProps = {
    className: "",
    onChange: () => {},
    defaultType: OrderType.Delivery,
  };

  public state = {
    type: this.props.defaultType,
  };

  onSelect = (type: OrderType) => {
    this.setState({ type: type });
    const { onChange } = this.props;
    onChange(type);
  };

  render() {
    const { className, orderTypes } = this.props;
    const { type } = this.state;

    let renderOrderTypes = {
      delivery: orderTypes
        ? orderTypes.find(
            (item) =>
              item.status === SystemStatus.Active &&
              item.orderType === OrderType.Delivery
          )
        : undefined,
      eatIn: orderTypes
        ? orderTypes.find(
            (item) =>
              item.status === SystemStatus.Active &&
              item.orderType === OrderType.EatIn
          )
        : undefined,
      takeaway: orderTypes
        ? orderTypes.find(
            (item) =>
              item.status === SystemStatus.Active &&
              item.orderType === OrderType.Takeaway
          )
        : undefined,
    };
    return (
      <ButtonGroup className={className + "wa-ab-order-type"}>
        {renderOrderTypes.delivery && (
          <Button
            className={
              "wa-ab-order-type-button" +
              (type === OrderType.Delivery ? " active" : "")
            }
            onClick={() => this.onSelect(OrderType.Delivery)}
            style={
              type === OrderType.Delivery
                ? {
                    color: Theme.color.onDeliveryButton,
                    backgroundColor: Theme.color.deliveryButton,
                    borderColor: Theme.color.deliveryButton,
                  }
                : {
                    color: Theme.color.passive,
                    backgroundColor: Theme.color.containerBackground,
                    borderColor: Theme.color.passive,
                  }
            }
          >
            Delivery
          </Button>
        )}
        {renderOrderTypes.takeaway && (
          <Button
            className={
              "wa-ab-order-type-button" +
              (type === OrderType.Takeaway ? " active" : "")
            }
            onClick={() => this.onSelect(OrderType.Takeaway)}
            style={
              type === OrderType.Takeaway
                ? {
                    color: Theme.color.onTakeawayButton,
                    backgroundColor: Theme.color.takeawayButton,
                    borderColor: Theme.color.takeawayButton,
                  }
                : {
                    color: Theme.color.passive,
                    backgroundColor: Theme.color.containerBackground,
                    borderColor: Theme.color.passive,
                  }
            }
          >
            Takeaway
          </Button>
        )}
        {renderOrderTypes.eatIn && (
          <Button
            className={
              "wa-ab-order-type-button" +
              (type === OrderType.EatIn ? " active" : "")
            }
            onClick={() => this.onSelect(OrderType.EatIn)}
            style={
              type === OrderType.EatIn
                ? {
                    color: Theme.color.onEatInButton,
                    backgroundColor: Theme.color.eatInButton,
                    borderColor: Theme.color.eatInButton,
                  }
                : {
                    color: Theme.color.passive,
                    backgroundColor: Theme.color.containerBackground,
                    borderColor: Theme.color.passive,
                  }
            }
          >
            Eat In
          </Button>
        )}
      </ButtonGroup>
    );
  }
}

export default OrderTypeSelection;
