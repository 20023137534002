import React from "react";
import "./index.css";

export interface SelectOrderTypeButtonProps {
  children?: JSX.Element | string | React.ReactElement;
  className?: React.HTMLAttributes<HTMLDivElement>;
  style?: React.HTMLAttributes<HTMLButtonElement>;
  subText?: string;
  text: React.ReactElement | string;
  icon?: React.ReactElement;
}

class SelectOrderTypeButton extends React.Component<
  SelectOrderTypeButtonProps
> {
  public render() {
    const { style, className, text, icon } = this.props;

    const $text = Array.isArray(text) ? text.join("/") : text;

    return (
      <button className={`${className} wa-order-type-button`} style={style}>
        {icon && <div className="wa-order-type-button-icon">{icon}</div>}
        <div className="wa-order-type-button-text">{$text}</div>
      </button>
    );
  }
}

export default SelectOrderTypeButton;
