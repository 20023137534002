import * as React from "react";
import "./index.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import Button from "../../components/Button";
import { Theme } from "../../infrastructure/config";
import PageLayout from "../partial/PageLayout";

type NotFoundProps = RouteComponentProps<{}>;

class NotFound extends React.PureComponent<NotFoundProps> {
  onClickReturnHome = () => {
    const { history } = this.props;
    history.push("/");
  };
  public render() {
    return (
      <PageLayout>
        <div className="wa-not-found">
          <span className="wa-nf-title" style={{ color: Theme.color.primary }}>
            404
          </span>
          <span className="wa-nf-text" style={{ color: Theme.color.title }}>
            Not Found
          </span>
          <Button
            text="HOME PAGE"
            className="wa-nf-button"
            onClick={this.onClickReturnHome}
          />
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(connect()(NotFound));
