import * as React from "react";
import "./index.css";
import { CustomerAddress } from "../../../../../types/customerAddress";
import { Home, CheckCircle } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";

type AddressListItemProps = {
  className: string;
  address: CustomerAddress;
  onSelect: (address: CustomerAddress) => void;
  isSelected: boolean;
};

class AddressListItem extends React.Component<AddressListItemProps> {
  public static defaultProps = {
    className: "",
    onSelect: () => {},
    isSelected: false,
  };

  render() {
    const { className, address, isSelected, onSelect } = this.props;
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          onSelect(address);
        }}
        className={(className ? `${className} ` : "") + "wa-address-list-item"}
        style={{ borderColor: Theme.color.backgroundDarker }}
      >
        <Home
          className="wa-ali-li"
          fontSize={"large"}
          htmlColor={Theme.color.title}
        />
        <div className="wa-ali-body">
          <span
            className="wa-ali-address-name"
            style={{ color: Theme.color.title }}
          >
            {address.addressName}
          </span>
          <span
            className="wa-ali-post-code"
            style={{ color: Theme.color.title }}
          >
            {address.postCode}
          </span>
          <span
            className="wa-ali-item"
            style={{ color: Theme.color.description }}
          >
            {address.customerFullName}
          </span>
          <span
            className="wa-ali-item"
            style={{ color: Theme.color.description }}
          >
            {address.doorNumber}
          </span>
          <span
            className="wa-ali-item"
            style={{ color: Theme.color.description }}
          >
            {address.addressDescription}
          </span>
          <span
            className="wa-ali-item"
            style={{ color: Theme.color.description }}
          >
            {address.phoneNumber}
          </span>
        </div>
        <div className="wa-ali-ri-container">
          {isSelected === true && (
            <CheckCircle
              className={"wa-ali-ri"}
              fontSize={"large"}
              htmlColor={Theme.color.secondary}
            />
          )}
        </div>
      </button>
    );
  }
}

export default AddressListItem;
