import * as React from "react";
import { Product, SelectedOption } from "../../../types/product";
import ProductDetailBody from "../ProductDetail/components/ProductDetailBody";
import { ShoppingCartService } from "../../../services";
import {
  ShoppingCartItemOption,
  ShoppingCart,
  ShoppingCartItem,
} from "../../../types/shoppingCart";
import { OrderStore } from "../../../infrastructure/store";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  ROrderStore,
  RShoppingCartStore,
} from "../../../store";

type ShoppingCartItemDetailProps = {
  className: string;
  show: boolean;
  id: string;
  onHide: () => void;
} & RCustomerStore.CustomerState &
  ROrderStore.OrderState &
  typeof RCustomerStore.actionCreators &
  typeof RShoppingCartStore.actionCreators;

type ShoppingCartItemDetailState = {
  product: Product;
  quantity: number;
  selectedExtras: SelectedOption[];
};

class ShoppingCartItemDetail extends React.Component<
  ShoppingCartItemDetailProps,
  ShoppingCartItemDetailState
> {
  public static defaultProps = {
    className: "",
    show: false,
  };

  public state = {
    product: {} as Product,
    quantity: 1,
    selectedExtras: [] as SelectedOption[],
  };

  componentDidMount = async () => {
    const { id, show, onHide, customer } = this.props;
    if (!show || customer === undefined) return;
    let shoppingCartItem = await this.getShoppingCartItem(
      customer.id,
      id,
      customer.token.token
    );

    if (!shoppingCartItem) {
      onHide();
      return;
    }

    this.setState({
      product: shoppingCartItem.product,
      quantity: shoppingCartItem.quantity,
      selectedExtras: shoppingCartItem.shoppingCartItemOptions.map((item) => ({
        productOptionItemId: item.productOptionItemId,
        quantity: item.quantity,
      })),
    });
  };

  getShoppingCartItem = async (
    customerId: string,
    shoppingCartItemId: string,
    token: string
  ): Promise<ShoppingCartItem | undefined> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let shoppingCartItemResult = await ShoppingCartService.getShoppingCartItem(
      customerId,
      shoppingCartItemId,
      orderProductGroup,
      token
    );

    if (shoppingCartItemResult.isSuccess) {
      return shoppingCartItemResult.data;
    } else return undefined;
  };

  onChangeQuantity = (quantity: number) => {
    this.setState({ quantity: quantity });
  };

  onChangeExtras = (selectedExtras: SelectedOption[]) => {
    this.setState({ selectedExtras: selectedExtras });
  };

  updateShoppingCart = async () => {
    const { quantity, selectedExtras } = this.state;
    const {
      customer,
      updateShoppingCart,
      onHide,
      id,
      selectedOrderType,
    } = this.props;
    if (customer === undefined) return;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let updateResult = await ShoppingCartService.updateShoppingCartItem(
      customer.id,
      {
        shoppingCartItemId: id,
        quantity: quantity,
        shoppingCartItemOptions: selectedExtras.map(
          (x) =>
            ({
              productOptionItemId: x.productOptionItemId,
              quantity: x.quantity,
            } as ShoppingCartItemOption)
        ),
        orderProductGroup: orderProductGroup,
      },
      customer.token.token
    );

    if (updateResult.isSuccess) {
      let shoppingCart = await this.getShoppingCart(
        customer.id,
        customer.token.token
      );
      if (shoppingCart) updateShoppingCart(shoppingCart);
      onHide();
    }
  };

  getShoppingCart = async (
    customerId: string,
    token: string
  ): Promise<ShoppingCart | undefined> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let shoppingCartResult = await ShoppingCartService.getShoppingCart(
      customerId,
      orderProductGroup,
      selectedOrderType,
      token
    );

    if (shoppingCartResult.isSuccess) return shoppingCartResult.data;
    else return undefined;
  };

  onDelete = async () => {
    const {
      customer,
      updateShoppingCart,
      onHide,
      id,
      selectedOrderType,
    } = this.props;
    if (customer === undefined) return;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let removeResult = await ShoppingCartService.removeFromShoppingCart(
      id,
      customer.id,
      orderProductGroup,
      customer.token.token
    );

    if (removeResult.isSuccess) {
      let shoppingCart = await this.getShoppingCart(
        customer.id,
        customer.token.token
      );
      if (shoppingCart) updateShoppingCart(shoppingCart);
      onHide();
    }
  };

  render() {
    const { className, onHide, show } = this.props;
    const { product, quantity } = this.state;

    return product !== undefined &&
      product !== null &&
      product.price !== undefined ? (
      <ProductDetailBody
        buttonText={"UPDATE"}
        headerText={"Basket Item"}
        showDeleteButton={true}
        show={show}
        className={className}
        onHide={onHide}
        product={product}
        quantity={quantity}
        onChangeQuantity={this.onChangeQuantity}
        onChangeExtras={this.onChangeExtras}
        onSubmit={this.updateShoppingCart}
        onDelete={this.onDelete}
      />
    ) : (
      <></>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
    ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
      ROrderStore.OrderState),
  {
    ...RCustomerStore.actionCreators,
    ...RShoppingCartStore.actionCreators,
  }
)(ShoppingCartItemDetail);
