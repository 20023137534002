import * as React from "react";
import "./index.css";
import InnerLayout from "../partial/InnerLayout";
import EmptyOrderList from "./components/EmptyOrderList";
import OrderListItem from "./components/OrderListItem";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore, RMainStore } from "../../store";
import { Order } from "../../types/order";
import { OrderService } from "../../services";
import Loading from "../../components/Loading";
import PageLayout from "../partial/PageLayout";

type OrderHistoryProps = {
  className: string;
} & RCustomerStore.CustomerState &
  RMainStore.MainState &
  RouteComponentProps;

type OrderHistoryState = {
  orderList: Order[];
  isLoaded: boolean;
};

class OrderHistory extends React.Component<
  OrderHistoryProps,
  OrderHistoryState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    orderList: [] as Order[],
    isLoaded: false,
  };

  componentDidMount = async () => {
    const { isAppLoaded, isSignedIn, history } = this.props;

    if (isAppLoaded === false) return;

    if (isSignedIn === false) {
      history.replace("/");
      return;
    }

    const { isLoaded } = this.state;
    if (!isLoaded) {
      let orderList = await this.getOrderList();
      this.setState({ orderList: orderList, isLoaded: true });
    }
  };

  componentDidUpdate = async () => {
    const { isAppLoaded, isSignedIn, history } = this.props;

    if (isAppLoaded === false) return;

    if (isSignedIn === false) history.replace("/");

    const { isLoaded } = this.state;
    if (!isLoaded) {
      let orderList = await this.getOrderList();
      this.setState({ orderList: orderList, isLoaded: true });
    }
  };

  getOrderList = async (): Promise<Order[]> => {
    const { customer } = this.props;
    if (customer === undefined) return [] as Order[];
    let orderList = await OrderService.getOrderList(
      customer.id,
      customer.token.token
    );

    if (orderList.isSuccess) {
      return orderList.data;
    } else return [] as Order[];
  };

  render() {
    const { className } = this.props;
    const { isLoaded, orderList } = this.state;
    return (
      <PageLayout>
        <InnerLayout
          hideBasket={true}
          className={className + " wa-order-history"}
        >
          <Loading className="wa-order-history-container" show={!isLoaded}>
            {orderList && orderList.length > 0 ? (
              <div className="wa-order-history-list">
                {orderList.map((order, index) => (
                  <OrderListItem order={order} key={index} />
                ))}
              </div>
            ) : (
              <EmptyOrderList className="" />
            )}
          </Loading>
        </InnerLayout>
      </PageLayout>
    );
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & RMainStore.MainState =>
      ({ ...state.customer, ...state.main } as RCustomerStore.CustomerState &
        RMainStore.MainState)
  )(OrderHistory)
);
