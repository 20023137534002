export enum PaymentType {
  None = 0,
  Cash = 1,
  CreditCard = 2,
  SquareUp = 3,
}

export enum PaymentStatus {
  None = 0,
  Waiting = 1,
  Pending = 2,
  Completed = 3,
  Cancelled = 4,
  Refund = 5,
}

export enum PaymentEnvironment {
  None = 0,
  Sandbox = 1,
  Production = 2,
}

export interface SquareUpSettings {
  applicationId: string;
  locationId: string;
  accessToken: string;
  environment: PaymentEnvironment;
}

export interface ProcessPayment {
  token: string;
  verificationData: string;
  paymentType: PaymentType;
  environment: PaymentEnvironment;
}

export interface ProcessPaymentResult {
  orderId: string;
  paymentHistoryId: string;
}
