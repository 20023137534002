import React, { Component } from "react";
import Banner from "../Banner";
import {
  RestaurantStatus,
  RestaurantStatusType,
} from "../../../../types/restaurant";
import { RestaurantService } from "../../../../services";
import { Theme } from "../../../../infrastructure/config";

type RestaurantStatusBannerProps = {};
type RestaurantStatusBannerState = {
  restaurantStatus?: RestaurantStatus;
};

class RestaurantStatusBanner extends Component<
  RestaurantStatusBannerProps,
  RestaurantStatusBannerState
> {
  state = {
    restaurantStatus: undefined as RestaurantStatus | undefined,
  };

  componentDidMount = async () => {
    await this.getRestaurantStatus();
  };

  getRestaurantStatus = async (): Promise<void> => {
    let restaurantStatusResult = await RestaurantService.getRestaurantStatus();
    const { data } = restaurantStatusResult;
    this.setState({
      restaurantStatus: data,
    });
  };

  render() {
    const { restaurantStatus } = this.state;
    return restaurantStatus && restaurantStatus.statusMessage ? (
      <Banner
        text={restaurantStatus.statusMessage.message}
        backgroundColor={
          restaurantStatus.status === RestaurantStatusType.Busy
            ? Theme.color.restaurantBusy
            : restaurantStatus.status === RestaurantStatusType.Closed
            ? Theme.color.restaurantClosed
            : Theme.color.secondary
        }
      />
    ) : (
      <></>
    );
  }
}

export default RestaurantStatusBanner;
