import * as React from "react";
import "./index.css";
import {
  FullNameInput,
  TextInput,
  PhoneNumberInput,
  PostCodeInput,
} from "../../../components/Input";
import Button from "../../../components/Button";
import { CustomerAddress } from "../../../types/customerAddress";
import { Validator } from "../../../infrastructure/helper";
import Alert from "../../../components/Alert";

type AddressDetailProps = {
  className: string;
  addressName: string;
  customerFullName: string;
  phoneNumber: string;
  postCode: string;
  doorNumber: string;
  addressDetails: string;
  id: string;
  isDefault: boolean;
  showDeleteButton: boolean;
  onSave: (address: CustomerAddress) => void;
  onDelete: (id: string) => void;
};

type AddressDetailState = {
  isAddressNameValid: boolean;
  addressName: string;
  isCustomerFullNameValid: boolean;
  customerFullName: string;
  isPhoneNumberValid: boolean;
  phoneNumber: string;
  isPostCodeValid: boolean;
  postCode: string;
  isDoorNumberValid: boolean;
  doorNumber: string;
  isAddressDetailsValid: boolean;
  addressDetails: string;
  isDefault: boolean;
  showAlert: boolean;
  alertText: string;
};

class AddressDetail extends React.Component<
  AddressDetailProps,
  AddressDetailState
> {
  public static defaultProps = {
    className: "",
    addressName: "",
    customerFullName: "",
    phoneNumber: "",
    postCode: "",
    doorNumber: "",
    addressDetails: "",
    id: "",
    isDefault: true,
    showDeleteButton: false,
    onDelete: () => {},
  };

  public state = {
    isAddressNameValid: true,
    addressName: this.props.addressName,
    isCustomerFullNameValid: true,
    customerFullName: this.props.customerFullName,
    isPhoneNumberValid: true,
    phoneNumber: this.props.phoneNumber,
    isPostCodeValid: true,
    postCode: this.props.postCode,
    isDoorNumberValid: true,
    doorNumber: this.props.doorNumber,
    isAddressDetailsValid: true,
    addressDetails: this.props.addressDetails,
    isDefault: this.props.isDefault,
    showAlert: false,
    alertText: "F",
  };

  onAddressNameChange = (value: string) => {
    const { isAddressNameValid } = this.state;
    let actualValue = value ? value.trim() : "";
    if (!isAddressNameValid)
      this.setState({ addressName: actualValue, isAddressNameValid: true });
    else this.setState({ addressName: actualValue });
  };

  onCustomerFullNameChange = (value: string) => {
    const { isCustomerFullNameValid } = this.state;
    let actualValue = value ? value.trim() : "";
    if (!isCustomerFullNameValid)
      this.setState({
        customerFullName: actualValue,
        isCustomerFullNameValid: true,
      });
    else this.setState({ customerFullName: actualValue });
  };

  onPhoneNumberChange = (value: string) => {
    const { isPhoneNumberValid } = this.state;
    let actualValue = value ? value.trim() : "";
    if (!isPhoneNumberValid)
      this.setState({ phoneNumber: actualValue, isPhoneNumberValid: true });
    else this.setState({ phoneNumber: actualValue });
  };

  showAlertModal = () => {
    this.setState({
      alertText: "Do you want to delete your address?",
      showAlert: true,
    });
  };

  hideAlertModal = () => {
    this.setState({ alertText: "", showAlert: false });
  };

  onPostCodeChange = (value: string) => {
    const { isPostCodeValid } = this.state;
    let actualValue = value ? value.trim() : "";
    if (!isPostCodeValid)
      this.setState({ postCode: actualValue, isPostCodeValid: true });
    else this.setState({ postCode: actualValue });
  };

  onDoorNumberChange = (value: string) => {
    const { isDoorNumberValid } = this.state;
    let actualValue = value ? value.trim() : "";
    if (!isDoorNumberValid)
      this.setState({ doorNumber: actualValue, isDoorNumberValid: true });
    else this.setState({ doorNumber: actualValue });
  };

  onAddressDetailsChange = (value: string) => {
    const { isAddressDetailsValid } = this.state;
    let actualValue = value ? value.trim() : "";
    if (!isAddressDetailsValid)
      this.setState({
        addressDetails: actualValue,
        isAddressDetailsValid: true,
      });
    else this.setState({ addressDetails: actualValue });
  };

  onSave = () => {
    if (this.validateInputs() === false) return;

    const { onSave, id } = this.props;
    const {
      addressDetails,
      addressName,
      postCode,
      doorNumber,
      phoneNumber,
      customerFullName,
      isDefault,
    } = this.state;
    onSave({
      addressName: addressName,
      addressDescription: addressDetails,
      postCode: postCode,
      doorNumber: doorNumber,
      phoneNumber: phoneNumber,
      customerFullName: customerFullName,
      isDefault: isDefault,
      id: id,
    });
  };

  onDelete = () => {
    const { onDelete, id } = this.props;
    onDelete(id);
  };

  validateInputs = () => {
    let validations = {
      isAddressNameValid: true,
      isCustomerFullNameValid: true,
      isPhoneNumberValid: true,
      isPostCodeValid: true,
      isDoorNumberValid: true,
      isAddressDetailsValid: true,
    };
    let isValid = true;
    const {
      addressName,
      customerFullName,
      phoneNumber,
      postCode,
      doorNumber,
      addressDetails,
    } = this.state;
    if (
      !addressName ||
      addressName.trim() === "" ||
      addressName.trim().length < 2
    ) {
      validations.isAddressNameValid = false;
      isValid = false;
    }

    if (
      !customerFullName ||
      customerFullName.trim() === "" ||
      customerFullName.trim().length < 3
    ) {
      validations.isCustomerFullNameValid = false;
      isValid = false;
    }

    if (
      !phoneNumber ||
      phoneNumber.trim() === "" ||
      !Validator.isValidPhone(phoneNumber)
    ) {
      validations.isPhoneNumberValid = false;
      isValid = false;
    }

    if (
      !postCode ||
      postCode.trim() === "" ||
      !Validator.isValidPostCode(postCode)
    ) {
      validations.isPostCodeValid = false;
      isValid = false;
    }

    if (
      !doorNumber ||
      doorNumber.trim() === "" ||
      doorNumber.trim().length < 1
    ) {
      validations.isDoorNumberValid = false;
      isValid = false;
    }

    if (
      !addressDetails ||
      addressDetails.trim() === "" ||
      addressDetails.trim().length < 3
    ) {
      validations.isAddressDetailsValid = false;
      isValid = false;
    }

    if (!isValid)
      this.setState({
        isAddressNameValid: validations.isAddressNameValid,
        isCustomerFullNameValid: validations.isCustomerFullNameValid,
        isPhoneNumberValid: validations.isPhoneNumberValid,
        isPostCodeValid: validations.isPostCodeValid,
        isDoorNumberValid: validations.isDoorNumberValid,
        isAddressDetailsValid: validations.isAddressDetailsValid,
      });

    return isValid;
  };

  render() {
    const { className, showDeleteButton } = this.props;
    const {
      isAddressNameValid,
      addressName,
      isCustomerFullNameValid,
      customerFullName,
      isPhoneNumberValid,
      phoneNumber,
      isPostCodeValid,
      postCode,
      isDoorNumberValid,
      doorNumber,
      isAddressDetailsValid,
      addressDetails,
      showAlert,
      alertText,
    } = this.state;
    return (
      <div className={className + " wa-address-detail"}>
        <Alert
          show={showAlert}
          bodyText={alertText}
          okButtonText="YES"
          onPressOk={this.onDelete}
          cancelButtonText="NO"
          onPressCancel={this.hideAlertModal}
          showCancelButton={true}
        />
        <TextInput
          invalid={!isAddressNameValid}
          defaultValue={addressName}
          onChange={this.onAddressNameChange}
          maxLength={25}
          label="Address name*"
        />
        <FullNameInput
          invalid={!isCustomerFullNameValid}
          defaultValue={customerFullName}
          onChange={this.onCustomerFullNameChange}
          label="Full name*"
        />
        <PhoneNumberInput
          invalid={!isPhoneNumberValid}
          defaultValue={phoneNumber}
          onChange={this.onPhoneNumberChange}
          label="Mobile phone*"
        />
        <PostCodeInput
          invalid={!isPostCodeValid}
          defaultValue={postCode}
          onChange={this.onPostCodeChange}
          label="Post code*"
        />
        <TextInput
          invalid={!isDoorNumberValid}
          defaultValue={doorNumber}
          maxLength={20}
          onChange={this.onDoorNumberChange}
          label="Door number*"
        />
        <TextInput
          invalid={!isAddressDetailsValid}
          defaultValue={addressDetails}
          maxLength={20}
          onChange={this.onAddressDetailsChange}
          label="Address details*"
        />
        <div className="wa-address-detail-buttons">
          <Button
            onClick={this.onSave}
            text="SAVE"
            className="wa-adb-save"
            color="success"
          />
          {showDeleteButton && (
            <Button
              onClick={this.showAlertModal}
              color="error"
              className="wa-adb-delete"
              iconType="trash"
            />
          )}
        </div>
      </div>
    );
  }
}

export default AddressDetail;
