import * as React from "react";
import "./index.css";
import MenuComp from "../partial/Menu";
import { connect } from "react-redux";
import { CustomerStore } from "../../infrastructure/store";
import { withRouter, RouteComponentProps } from "react-router";
import PageLayout from "../partial/PageLayout";

type MenuProps = {
  className: string;
} & RouteComponentProps;

class Menu extends React.Component<MenuProps> {
  public static defaultProps = {
    className: "",
  };

  componentDidMount = () => {
    const { history } = this.props;
    if (CustomerStore.isSignedIn() === false) history.replace("/");
  };

  render() {
    const { className } = this.props;
    return (
      <PageLayout>
        <div className={className + " wa-menu-page"}>
          <MenuComp />
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(connect()(Menu));
