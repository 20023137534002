import * as React from "react";
import MenuItem from "../MenuItem";
import MenuItemHeader from "../MenuItemHeader";
import MenuSearch from "../MenuSearch";
import { Category } from "../../../../../types/category";

type MenuListProps = {
  className: string;
  categories: Category[];
  onSearchTextChange: (text: string) => void;
};

class MenuList extends React.Component<MenuListProps> {
  public static defaultProps = {
    className: "",
    categories: [] as Category[],
  };

  render() {
    const { className, categories, onSearchTextChange } = this.props;
    return (
      <div className={className}>
        <MenuSearch onChange={onSearchTextChange} />
        {categories &&
          categories.map((category, index) => {
            return (
              <div key={index}>
                <MenuItemHeader text={category.name} />
                {category.products &&
                  category.products.map((product, pIndex) => {
                    return <MenuItem product={product} key={pIndex} />;
                  })}
              </div>
            );
          })}
      </div>
    );
  }
}

export default MenuList;
