import * as React from "react";
import "./index.css";
import { CheckCircle } from "@material-ui/icons";
import { Theme } from "../../../../infrastructure/config";

type OrderSummaryMessageProps = {
  className: string;
};

class OrderSummaryMessage extends React.Component<OrderSummaryMessageProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;
    return (
      <div
        className={className + " wa-order-summary-message"}
        style={{
          backgroundColor: Theme.color.containerBackground,
          color: Theme.color.secondary,
          borderColor: Theme.color.backgroundDarker,
        }}
      >
        <CheckCircle className="wa-osm-icon" />
        <span className="wa-osm-text">
          We have received your order. Thank you.
        </span>
      </div>
    );
  }
}

export default OrderSummaryMessage;
