export enum ApplicationSource {
  None = 0,
  Web = 1,
  Android = 2,
  IOS = 3,
}

export interface BreadCrumb {
  url: string;
  name: string;
}

export enum SystemStatus {
  None = 0,
  Active = 1,
  Passive = 2,
}

export enum Store {
  Customer = "Customer",
  GuestCustomer = "GuestCustomer",
  AppSettings = "AppSettings",
  OrderSettings = "OrderSettings",
  RestaurantInfo = "RestaurantInfo",
  RestaurantWorkingDays = "RestaurantWorkingDays",
  RestaurantOrderLimits = "RestaurantOrderLimits",
}

export interface HomePageState {
  openModal: boolean;
  email?: string;
}
