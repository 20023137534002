import * as React from "react";
import "./index.css";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Remove,
  Add,
} from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import {
  ProductOptionItem,
  SelectedOption,
} from "../../../../../types/product";

type MenuItemExtraMultipleQuantityItemProps = {
  className: string;
  item: ProductOptionItem;
  index: number;
  show: boolean;
  onSelect: (index: number) => void;
  onDeselect: (index: number) => void;
  validateQuantity: (prevQuantity: number, nextQuantity: number) => boolean;
  onQuantityChange: (index: number, newQuantity: number) => void;
};

type MenuItemExtraMultipleQuantityItemState = {
  quantity: number;
};

class MenuItemExtraMultipleQuantityItem extends React.Component<
  MenuItemExtraMultipleQuantityItemProps,
  MenuItemExtraMultipleQuantityItemState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    quantity:
      this.props.item && this.props.item.selected
        ? this.props.item.quantity === 0
          ? 1
          : this.props.item.quantity
        : 0,
  };

  onPress = (): void => {
    const { onSelect, onDeselect, index, validateQuantity, item } = this.props;
    if (!item.selected) {
      if (validateQuantity(0, 1)) {
        onSelect(index);
        this.onQuantityIncrease();
      }
    } else {
      onDeselect(index);
      this.resetQuantity();
    }
  };

  onQuantityIncrease = (): void => {
    const { onQuantityChange, index, validateQuantity } = this.props;
    const { quantity } = this.state;
    let newQuantity = quantity + 1;
    if (validateQuantity(quantity, newQuantity)) {
      this.setState({ quantity: newQuantity }, () => {
        onQuantityChange(index, newQuantity);
      });
    }
  };

  onQuantityDecrease = (): void => {
    const { onQuantityChange, index } = this.props;
    const { quantity } = this.state;
    let newQuantity = quantity > 2 ? quantity - 1 : 1;
    this.setState({ quantity: newQuantity }, () => {
      onQuantityChange(index, newQuantity);
    });
  };

  resetQuantity = (): void => {
    const { onQuantityChange, index } = this.props;
    this.setState({ quantity: 0 });
    onQuantityChange(index, 0);
  };

  getItemOption = (): SelectedOption | undefined => {
    const { item } = this.props;
    const { quantity } = this.state;
    return item.selected
      ? ({ quantity: quantity, productOptionItemId: item.id } as SelectedOption)
      : undefined;
  };

  getQuantity = (): number => {
    const { quantity } = this.state;
    return quantity;
  };

  getPrice = (): number => {
    const { item } = this.props;
    return item ? item.price : 0;
  };

  getTotalPrice = (): number => {
    return this.getQuantity() * this.getPrice();
  };

  render() {
    const { className, item, show } = this.props;
    const { quantity } = this.state;
    return (
      <div
        className={"wa-miemqi-container" + (show ? "" : " wa-d-none")}
        style={{ borderColor: Theme.color.backgroundDarker }}
      >
        <button
          onClick={(e: React.MouseEvent) => this.onPress()}
          className={className + " wa-miemqi"}
          style={{
            borderColor: Theme.color.backgroundDarker,
            backgroundColor: Theme.color.containerBackground,
          }}
        >
          <div className="wa-miemqi-detail">
            <span
              className="wa-miemqi-name"
              style={{ color: Theme.color.title }}
            >
              {item.name}
            </span>
            {item.price > 0 && (
              <span
                className="wa-miemqi-price"
                style={{ color: Theme.color.primary }}
              >
                (+£{item.price.toFixed(2)})
              </span>
            )}
          </div>
          {item.selected === true && (
            <CheckBox
              className="wa-miemqi-icon"
              style={{ color: Theme.color.secondary }}
            />
          )}
          {item.selected === false && (
            <CheckBoxOutlineBlank
              className="wa-miemqi-icon"
              style={{ color: Theme.color.title }}
            />
          )}
        </button>
        {quantity > 0 && (
          <div className="wa-miemqi-quantity-container">
            <button
              onClick={this.onQuantityDecrease}
              className="wa-miemqi-quantity-minus"
              style={{
                color: Theme.color.title,
                backgroundColor: Theme.color.containerBackground,
              }}
            >
              <Remove style={{ color: Theme.color.title }} />
            </button>
            <span
              className="wa-miemqi-quantity"
              style={{
                color: Theme.color.title,
                backgroundColor: Theme.color.backgroundDarker,
              }}
            >
              x{quantity}
            </span>
            <button
              onClick={this.onQuantityIncrease}
              className="wa-miemqi-quantity-plus"
              style={{
                color: Theme.color.title,
                backgroundColor: Theme.color.containerBackground,
              }}
            >
              <Add style={{ color: Theme.color.title }} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default MenuItemExtraMultipleQuantityItem;
