import * as React from "react";
import "./index.css";
import { Theme } from "../../../../infrastructure/config";
import { Subject } from "@material-ui/icons";
import AllergyText from "../AllergyText";

type OrderNoteProps = {
  className?: string;
  onChange: (text: string) => void;
};

type OrderNoteState = {
  show: boolean;
};

class OrderNote extends React.Component<OrderNoteProps, OrderNoteState> {
  state = {
    show: false,
  };

  render() {
    const { show } = this.state;
    const { className, onChange } = this.props;
    return (
      <>
        {show && (
          <>
            <span
              className="wa-ab-title"
              style={{ color: Theme.color.onBackground }}
            >
              Notes:
            </span>
            <div className="wa-ab-section">
              <div
                className={className + " wa-order-note"}
                style={{
                  backgroundColor: Theme.color.containerBackground,
                  borderColor: Theme.color.inputBorder,
                }}
              >
                <Subject
                  fontSize="large"
                  className="wa-order-note-icon"
                  htmlColor={Theme.color.title}
                />
                <textarea
                  placeholder={"Enter note about your order"}
                  onChange={(e) => onChange(e.target.value)}
                  className="wa-order-note-input"
                  maxLength={200}
                  rows={3}
                  style={{
                    color: Theme.color.title,
                    borderColor: Theme.color.inputBorder,
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className="wa-on-actions-wrapper">
          {!show && (
            <button
              onClick={(e) => {
                e.preventDefault();
                this.setState({ show: true });
              }}
              style={{ color: "#0288D1" }}
              className={className + " wa-add-note-text"}
            >
              Add note
            </button>
          )}
          <AllergyText />
        </div>
      </>
    );
  }
}

export default OrderNote;
