import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import SignInBody from "../SignInBody";

type SignInModalProps = {
  className: string;
  show: boolean;
  defaultEmail: string;
  onHide: () => void;
  onSuccess: (email: string) => void;
};

class SignInModal extends React.Component<SignInModalProps> {
  public static defaultProps = {
    className: "",
    show: false,
    onSuccess: () => {},
    defaultEmail: "",
  };

  render() {
    const { className, show, onHide, onSuccess, defaultEmail } = this.props;
    return (
      <ModalContainer
        show={show}
        onHide={onHide}
        showHeader={true}
        headerText={"Sign In"}
        className={className + " wa-sign-in-modal"}
      >
        <div className="wa-center">
          <SignInBody
            onSuccess={(email) => {
              onSuccess(email);
              onHide();
            }}
            defaultEmail={defaultEmail}
            onHide={onHide}
            className="wa-sim-body"
          />
        </div>
      </ModalContainer>
    );
  }
}

export default SignInModal;
