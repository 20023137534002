import { PaymentType, PaymentStatus, PaymentEnvironment } from "../payment";
import { Customer } from "../customer";
import { CustomerAddress } from "../customerAddress";
import { RestaurantTable } from "../restaurant";
import { ApplicationSource } from "types/system";
import { DeliveryDistance } from "types/address";

export enum OrderType {
  None = 0,
  Takeaway = 1,
  Delivery = 2,
  EatIn = 3,
}

export enum OrderStatus {
  None = 0,
  Waiting = 1,
  Preparing = 2,
  Cancelled = 3,
  Rejected = 4,
  Shipped = 5,
  Returned = 6,
  Completed = 7,
  ScheduledWaiting = 8,
}

export interface Order {
  id: string;
  orderIndex: number;
  createdDateTime: string;
  type: OrderType;
  paymentType: PaymentType;
  status: OrderStatus;
  paymentStatus: PaymentStatus;
  totalPrice: number;
  deliveryFee: number;
  subTotalPrice: number;
  note: string;
  customer: Customer;
  deliveryAddress: CustomerAddress;
  eatInAddress: CustomerAddress;
  restaurantTable: RestaurantTable;
  orderItems: OrderItem[];
  campaigns: OrderCampaign[];
  orderAttributes: OrderAttribute[];
}

export interface OrderItem {
  id: string;
  quantity: number;
  productName: string;
  productDescription: string;
  subTotalPrice: number;
  totalPrice: number;
  displayDescription: string;
  price: number;
  discountedPrice?: number;
  orderItemOptions: OrderItemOption[];
}

export interface OrderItemOption {
  name: string;
  price: number;
  quantity: number;
}

export interface OrderCampaign {
  name: string;
  discountAmount: number;
}

export interface CreateOrder {
  source: ApplicationSource;
  paymentHistoryId?: string;
  orderId?: string;
  type: OrderType;
  restaurantTableId?: string;
  note: string;
  paymentType: PaymentType;
  paymentEnvironment: PaymentEnvironment;
  deliveryAddressId?: string;
  eatInAddressId?: string;
  phoneNumber?: string;
  orderProductGroup: OrderProductGroupType;
  orderAttributes: OrderAttribute[];
}

export interface OrderLimit {
  deliveryDistance?: DeliveryDistance;
  minOrderAmount: number;
  orderType: OrderType;
}

export enum OrderProductGroupType {
  None = 0,
  DeliveryTakeaway = 1,
  EatIn = 2,
}

export enum OrderAttributeType {
  None = 0,
  MinOrderCompleteTime = 1,
  MaxOrderCompleteTime = 2,
  MinOrderScheduleDateTime = 3,
  MaxOrderScheduleDateTime = 4,
}

export interface OrderAttribute {
  type: OrderAttributeType;
  value: string;
}

export interface OrderAvailableDate {
  text: string;
  id: string;
  availableTimes: OrderAvailableTime[];
}

export interface OrderAvailableTime {
  text: string;
  minCompleteTime: string;
  maxCompleteTime: string;
}

export interface OrderSelectedAvailableTime {
  dayText?: string;
  timeText: string;
  minCompleteTime: string;
  maxCompleteTime: string;
}
