import * as React from "react";
import { PaymentEnvironment } from "types/payment";

type CreditCardPaymentProps = {
  startPayment: boolean;
  onComplete: (orderId?: string, paymentHistoryId?: string) => void;
  onError: () => void;
  onCancel: () => void;
  environment: PaymentEnvironment;
};

class CreditCardPayment extends React.Component<CreditCardPaymentProps> {
  constructor(props: CreditCardPaymentProps) {
    super(props);
    this.startPayment = this.startPayment.bind(this);
    this.dummyRender = this.dummyRender.bind(this);
  }

  startPayment = () => {
    const { startPayment, onComplete } = this.props;
    if (startPayment === true) {
      onComplete();
    }

    return <></>;
  };

  dummyRender() {
    this.startPayment();
    return <></>;
  }

  render() {
    return <this.dummyRender />;
  }
}

export default CreditCardPayment;
