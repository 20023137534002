import * as React from "react";
import "./index.css";
import OrderSummaryTabs, { ActiveTab } from "./components/OrderSummaryTabs";
import { Theme } from "../../../infrastructure/config";
import { Order, OrderAttributeType, OrderStatus } from "../../../types/order";
import OrderSummaryDetails from "./components/OrderSummaryDetails";
import OrderSummaryItems from "./components/OrderSummaryItems";
import OrderSummaryCompleteTime from "./components/OrderSummaryCompleteTime";
import { DateTime, ObjectHelper } from "infrastructure/helper";

type OrderSummaryBodyProps = {
  className: string;
  order: Order;
};

type OrderSummaryState = {
  activeTab: ActiveTab;
};

class OrderSummaryBody extends React.Component<
  OrderSummaryBodyProps,
  OrderSummaryState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    activeTab: ActiveTab.OrderDetail,
  };

  render() {
    const { className, order } = this.props;
    const { activeTab } = this.state;
    return (
      order && (
        <div
          className={className + " wa-order-summary-body"}
          style={{ borderColor: Theme.color.backgroundDarker }}
        >
          {order.status === OrderStatus.Preparing && (
            <OrderSummaryCompleteTime
              maxCompleteTime={DateTime.convertDate(
                ObjectHelper.getAttributeValue(
                  order.orderAttributes,
                  OrderAttributeType.MaxOrderCompleteTime
                )
              )}
              minCompleteTime={DateTime.convertDate(
                ObjectHelper.getAttributeValue(
                  order.orderAttributes,
                  OrderAttributeType.MinOrderCompleteTime
                )
              )}
              orderType={order.type}
            />
          )}
          {(order.status === OrderStatus.Waiting ||
            order.status === OrderStatus.ScheduledWaiting) && (
            <OrderSummaryCompleteTime
              scheduled={true}
              orderType={order.type}
              maxCompleteTime={DateTime.convertDate(
                ObjectHelper.getAttributeValue(
                  order.orderAttributes,
                  OrderAttributeType.MaxOrderScheduleDateTime
                )
              )}
              minCompleteTime={DateTime.convertDate(
                ObjectHelper.getAttributeValue(
                  order.orderAttributes,
                  OrderAttributeType.MinOrderScheduleDateTime
                )
              )}
            />
          )}
          <OrderSummaryTabs
            orderType={order.type}
            activeTab={activeTab}
            onChange={(activeTab) => this.setState({ activeTab: activeTab })}
          />
          <OrderSummaryDetails order={order} activeTab={activeTab} />
          <OrderSummaryItems order={order} className=" wa-osb-osi" />
        </div>
      )
    );
  }
}

export default OrderSummaryBody;
