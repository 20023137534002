import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import { Category } from "../types/category";
import { Product } from "../types/product";
import { OrderProductGroupType } from "types/order";
import { ApiResponse } from "types/response";

export async function getProducts(
  orderProductGroup: OrderProductGroupType
): Promise<ApiResponse<Category[]>> {
  return await apiRequest<Category[]>({
    url: `product/list?orderProductGroup=${orderProductGroup}`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getProduct(
  orderProductGroup: OrderProductGroupType,
  id: string
): Promise<ApiResponse<Product>> {
  return await apiRequest<Product>({
    url: `product?productId=${id}&orderProductGroup=${orderProductGroup}`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}
