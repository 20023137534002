import * as React from "react";
import "./index.css";
import ModalContainer from "../../../components/ModalContainer";
import SignUpBody from "../../partial/SignUpBody";

type SignUpProps = {
  className: string;
  show: boolean;
  onHide: () => void;
  onSuccess: (email: string) => void;
};

class SignUp extends React.Component<SignUpProps> {
  public static defaultProps = {
    className: "",
    show: false,
    onSuccess: () => {},
  };

  render() {
    const { className, show, onHide, onSuccess } = this.props;
    return (
      <ModalContainer
        show={show}
        onHide={onHide}
        showHeader={true}
        headerText={"Sign Up"}
        className={className + " wa-sign-up"}
      >
        <div className="wa-center">
          <SignUpBody onSuccess={onSuccess} className="wa-su-body" />
        </div>
      </ModalContainer>
    );
  }
}

export default SignUp;
