import {
  Customer,
  CustomerRegister,
  CustomerUpdatePaswordWithToken,
  CustomerUpdatePasword,
  UpdateCustomerInfo,
} from "../types/customer";
import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestOptions,
  HttpRequestMethod,
} from "../infrastructure/helper/httpRequest";
import { ApiResponse } from "types/response";

export async function authenticateCustomer(
  email: string,
  password: string
): Promise<ApiResponse<Customer>> {
  return await apiRequest<Customer>({
    url: `customer/authenticate?email=${email}&password=${password}`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function authenticateGuestCustomer(
  guestCustomerId: string
): Promise<ApiResponse<Customer>> {
  return await apiRequest<Customer>({
    url: `customer/authenticate-guest?customerId=${
      guestCustomerId === undefined ? "" : guestCustomerId
    }`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function registerCustomer(
  customerRegister: CustomerRegister
): Promise<ApiResponse<string>> {
  return await apiRequest<string>({
    url: `customer/register`,
    options: { method: HttpRequestMethod.PUT } as HttpRequestOptions,
    data: customerRegister,
  } as ApiRequestConfig);
}

export async function approveCustomer(eci: string): Promise<ApiResponse<any>> {
  return await apiRequest<string>({
    url: `customer/approve?eci=${eci}`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function forgetPassword(email: string): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer/forget-password?email=${email}`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function sendVerificationEmail(
  email: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer/send-verification-email?email=${email}`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function forgetPasswordValidate(
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer/forget-password/validate?token=${token}`,
    options: { method: HttpRequestMethod.GET } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function forgetPasswordUpdate(
  updatePassword: CustomerUpdatePaswordWithToken,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer/forget-password/update?token=${token}`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
    data: updatePassword,
  } as ApiRequestConfig);
}

export async function updatePassword(
  updatePassword: CustomerUpdatePasword,
  customerId: string,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest({
    url: `customer/update-password?customerId=${customerId}&token=${token}`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
    data: updatePassword,
  } as ApiRequestConfig);
}

export async function updatePersonalInfo(
  updateCustomerInfo: UpdateCustomerInfo,
  customerId: string,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `customer/personal-info?customerId=${customerId}&token=${token}`,
    options: { method: HttpRequestMethod.POST } as HttpRequestOptions,
    data: updateCustomerInfo,
  } as ApiRequestConfig);
}
