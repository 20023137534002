import * as React from "react";
import "./index.css";
import { ButtonGroup, Button } from "reactstrap";
import { Theme } from "../../../../../infrastructure/config";
import { OrderType } from "../../../../../types/order";

export enum ActiveTab {
  OrderDetail,
  AddressDetail,
}

type OrderSummaryTabsProps = {
  className: string;
  activeTab: ActiveTab;
  orderType: OrderType;
  onChange: (activeTab: ActiveTab) => void;
};

const getTabStyle = (
  activeTab: ActiveTab,
  thisTab: ActiveTab
): React.CSSProperties => {
  return {
    color: activeTab === thisTab ? Theme.color.primary : Theme.color.passive,
    borderColor:
      activeTab === thisTab
        ? Theme.color.primary
        : Theme.color.backgroundDarker,
    backgroundColor: Theme.color.containerBackground,
  };
};
class OrderSummaryTabs extends React.Component<OrderSummaryTabsProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className, orderType, activeTab, onChange } = this.props;
    return (
      <ButtonGroup className={className + " wa-order-summary-tabs"}>
        <Button
          onClick={() => {
            this.setState({ activeTab: ActiveTab.OrderDetail });
            onChange(ActiveTab.OrderDetail);
          }}
          className={
            "wa-ost-tab" +
            (activeTab === ActiveTab.OrderDetail ? " active" : "")
          }
          style={getTabStyle(activeTab, ActiveTab.OrderDetail)}
        >
          Order Details
        </Button>
        {(orderType === OrderType.Delivery ||
          orderType === OrderType.EatIn) && (
          <Button
            onClick={() => {
              this.setState({ activeTab: ActiveTab.AddressDetail });
              onChange(ActiveTab.AddressDetail);
            }}
            style={getTabStyle(activeTab, ActiveTab.AddressDetail)}
            className={
              "wa-ost-tab" +
              (activeTab === ActiveTab.AddressDetail ? " active" : "")
            }
          >
            {orderType === OrderType.Delivery
              ? "Delivery Address"
              : orderType === OrderType.EatIn
              ? "Contact Address"
              : "Customer Address"}
          </Button>
        )}
      </ButtonGroup>
    );
  }
}

export default OrderSummaryTabs;
