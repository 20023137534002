import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import { DeliveryDistance } from "../types/address";
import { ApiResponse } from "types/response";

export async function CheckDeliveryDistance(
  postCode: string | undefined,
  token: string
): Promise<ApiResponse<DeliveryDistance>> {
  return await apiRequest<DeliveryDistance>({
    url: `delivery-location/check?postCode=${postCode}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}
