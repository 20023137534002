import moment from "moment";

export function convertDate(date?: string): Date | undefined {
  if (!date) return undefined;
  try {
    return moment(date).utc(false).toDate();
  } catch {
    return undefined;
  }
}

export function getUTCDate(): Date {
  return new Date(new Date().toUTCString());
}

export function dateTimeString(
  date: string,
  customFormat: string = "DD.MM.YYYY HH:mm"
) {
  let dateObject = typeof date === "string" ? convertDate(date) : date;
  return dateObject ? `${moment(dateObject).format(customFormat)}` : "";
}

export function dateString(date: Date) {
  return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${date.getFullYear()}`;
}
