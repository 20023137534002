import { Action, Reducer } from "redux";
export interface ForgetPasswordModalState {
  showForgetPassword: boolean;
  onHideForgetPassword: () => void;
  onSuccessForgetPassword: (email: string) => void;
}

interface ShowModalAction {
  type: "SHOW_FORGETPASSWORD_MODAL";
  onHide: () => {};
  onSuccess: (email: string) => void;
}
interface HideModalAction {
  type: "HIDE_FORGETPASSWORD_MODAL";
}

type KnownAction = ShowModalAction | HideModalAction;

export const actionCreators = {
  showForgetPasswordModal: (
    onHide: () => void,
    onSuccess: (email: string) => void
  ) =>
    ({
      type: "SHOW_FORGETPASSWORD_MODAL",
      onHide,
      onSuccess,
    } as ShowModalAction),
  hideForgetPasswordModal: () =>
    ({ type: "HIDE_FORGETPASSWORD_MODAL" } as HideModalAction),
};

const unloadedForgetPasswordState: ForgetPasswordModalState = {
  showForgetPassword: false,
  onHideForgetPassword: () => {},
  onSuccessForgetPassword: () => {},
};

export const forgetPasswordReducer: Reducer<ForgetPasswordModalState> = (
  state: ForgetPasswordModalState | undefined,
  incomingAction: Action
): ForgetPasswordModalState => {
  if (state === undefined) {
    return unloadedForgetPasswordState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SHOW_FORGETPASSWORD_MODAL":
      return {
        showForgetPassword: true,
        onHideForgetPassword: action.onHide,
        onSuccessForgetPassword: action.onSuccess,
      } as ForgetPasswordModalState;
    case "HIDE_FORGETPASSWORD_MODAL":
      return {
        showForgetPassword: false,
        onHideForgetPassword: () => {},
        onSuccessForgetPassword: () => {},
      } as ForgetPasswordModalState;
  }

  return state;
};
