import { Action, Reducer } from "redux";
export interface SignUpModalState {
  showSignUp: boolean;
  onHideSignUp: () => void;
  onSuccessSignUp: (email: string) => void;
}

interface ShowModalAction {
  type: "SHOW_SIGNUP_MODAL";
  onHide: () => {};
  onSuccess: (email: string) => void;
}
interface HideModalAction {
  type: "HIDE_SIGNUP_MODAL";
}

type KnownAction = ShowModalAction | HideModalAction;

export const actionCreators = {
  showSignUpModal: (onHide: () => void, onSuccess: (email: string) => void) =>
    ({
      type: "SHOW_SIGNUP_MODAL",
      onHide,
      onSuccess,
    } as ShowModalAction),
  hideSignUpModal: () => ({ type: "HIDE_SIGNUP_MODAL" } as HideModalAction),
};

const unloadedSignUpState: SignUpModalState = {
  showSignUp: false,
  onHideSignUp: () => {},
  onSuccessSignUp: () => {},
};

export const signUpReducer: Reducer<SignUpModalState> = (
  state: SignUpModalState | undefined,
  incomingAction: Action
): SignUpModalState => {
  if (state === undefined) {
    return unloadedSignUpState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SHOW_SIGNUP_MODAL":
      return {
        showSignUp: true,
        onHideSignUp: action.onHide,
        onSuccessSignUp: action.onSuccess,
      } as SignUpModalState;
    case "HIDE_SIGNUP_MODAL":
      return {
        showSignUp: false,
        onHideSignUp: () => {},
        onSuccessSignUp: () => {},
      } as SignUpModalState;
  }

  return state;
};
