import * as React from "react";
import "./index.css";
import Loading from "../../components/Loading";
import { withRouter, RouteComponentProps } from "react-router";
import { CustomerService } from "../../services";
import { Theme } from "../../infrastructure/config";
import { ErrorOutline, HourglassEmpty } from "@material-ui/icons";
import Button from "../../components/Button";
import { PasswordInput } from "../../components/Input";
import Alert from "../../components/Alert";
import PageLayout from "../partial/PageLayout";

type ResetPasswordProps = {
  className: string;
} & RouteComponentProps<{ token: string }>;

type ResetPasswordState = {
  status: Status;
  errorMessage: string;
  isPasswordValid: boolean;
  password: string;
  isPasswordAgainValid: boolean;
  passwordAgain: string;
  showAlert: boolean;
  alertMessage: string;
  updateStatus: Status;
};

enum Status {
  Waiting,
  Error,
  Success,
}

class ResetPassword extends React.Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  public static defaultProps = {
    className: "",
  };

  public state = {
    status: Status.Waiting,
    errorMessage: "Validationg your token. Please wait...",
    isPasswordValid: true,
    password: "",
    isPasswordAgainValid: true,
    passwordAgain: "",
    showAlert: false,
    alertMessage: "",
    updateStatus: Status.Waiting,
  };

  componentDidMount = async () => {
    const { match } = this.props;
    if (match.params.token && match.params.token !== "") {
      let validateResult = await CustomerService.forgetPasswordValidate(
        match.params.token
      );

      if (validateResult.isSuccess) {
        this.setState({ status: Status.Success, errorMessage: "" });
      } else {
        this.setState({
          status: Status.Error,
          errorMessage: validateResult.message,
        });
      }
    } else {
      this.setState({
        status: Status.Error,
        errorMessage: "Token is invalid.",
      });
    }
  };

  onHomeClick = () => {
    const { history } = this.props;
    history.replace("/");
  };

  onPasswordTextChange = (value: string) => {
    if (!this.state.isPasswordValid)
      this.setState({ isPasswordValid: true, password: value });
    else this.setState({ password: value });
  };

  onPasswordAgainTextChange = (value: string) => {
    if (!this.state.isPasswordAgainValid)
      this.setState({ isPasswordAgainValid: true, passwordAgain: value });
    else this.setState({ passwordAgain: value });
  };

  showAlert = (message: string) => {
    this.setState({ showAlert: true, alertMessage: message });
  };

  hideAlert = () => {
    this.setState({ showAlert: false, alertMessage: "" });
    const { updateStatus } = this.state;
    const { history } = this.props;
    if (updateStatus === Status.Success) {
      history.replace("/");
    }
  };

  onResetPressed = async () => {
    if (!this.validateInputs()) return;
    const { match } = this.props;
    const { password, passwordAgain } = this.state;

    let forgetResult = await CustomerService.forgetPasswordUpdate(
      {
        newPassword: password,
        newPasswordAgain: passwordAgain,
      },
      match.params.token
    );

    if (forgetResult.isSuccess) {
      this.setState({ updateStatus: Status.Success });
    }

    this.showAlert(forgetResult.message);
  };

  validateInputs = () => {
    let validations = {
      isPasswordValid: true,
      isPasswordAgainValid: true,
    };

    const { password, passwordAgain } = this.state;
    if (!password || password.trim() === "" || password.length < 3) {
      validations.isPasswordValid = false;
    }

    if (
      !passwordAgain ||
      passwordAgain.trim() === "" ||
      passwordAgain.length < 3
    ) {
      validations.isPasswordAgainValid = false;
    }

    if (!validations.isPasswordValid || !validations.isPasswordAgainValid)
      this.setState({
        isPasswordAgainValid: validations.isPasswordAgainValid,
        isPasswordValid: validations.isPasswordValid,
      });

    return validations.isPasswordValid && validations.isPasswordAgainValid;
  };

  render() {
    const { className } = this.props;
    const {
      status,
      errorMessage,
      isPasswordAgainValid,
      isPasswordValid,
      password,
      passwordAgain,
      showAlert,
      alertMessage,
    } = this.state;
    return (
      <PageLayout>
        <Loading show={status === Status.Waiting} className={className}>
          {(status === Status.Waiting || status === Status.Error) && (
            <div className="wa-rp-message-container">
              {status === Status.Error && (
                <ErrorOutline
                  htmlColor={Theme.color.error}
                  className={"wa-rp-icon"}
                />
              )}
              {status === Status.Waiting && (
                <HourglassEmpty
                  htmlColor={Theme.color.title}
                  className={"wa-rp-icon"}
                />
              )}

              <span
                className="wa-rp-text"
                style={status === Status.Error ? errorStyle : waitingStyle}
              >
                {errorMessage}
              </span>
              <Button
                text="HOME PAGE"
                className="wa-rp-button"
                onClick={this.onHomeClick}
                color="success"
              />
            </div>
          )}
          {status === Status.Success && (
            <div className="wa-rp-input-container">
              <div className="wa-rp-input-inner-container">
                <span
                  className="wa-rp-label"
                  style={{ color: Theme.color.title }}
                >
                  Reset your password:
                </span>
                <Alert
                  show={showAlert}
                  bodyText={alertMessage}
                  okButtonText={"OK"}
                  onPressOk={this.hideAlert}
                />
                <PasswordInput
                  name="pw-reset-password"
                  label="Password"
                  invalid={!isPasswordValid}
                  defaultValue={password}
                  onChange={this.onPasswordTextChange}
                />
                <PasswordInput
                  name="pw-reset-password-again"
                  label="Password (Again)"
                  invalid={!isPasswordAgainValid}
                  defaultValue={passwordAgain}
                  onChange={this.onPasswordAgainTextChange}
                />
                <Button
                  text="Reset your password"
                  onClick={this.onResetPressed}
                />
              </div>
            </div>
          )}
        </Loading>
      </PageLayout>
    );
  }
}

const errorStyle: React.CSSProperties = {
  color: Theme.color.error,
};

const waitingStyle: React.CSSProperties = {
  color: Theme.color.title,
};

export default withRouter(ResetPassword);
