import * as React from "react";
import "./index.css";
import { CustomerAddress } from "../../../types/customerAddress";
import AddressListItem from "./components/AddressListItem";
import { Theme } from "../../../infrastructure/config";

type AddressListProps = {
  className: string;
  selectedAddress: CustomerAddress | undefined;
  addressList: CustomerAddress[];
  onSelect: (address: CustomerAddress) => void;
};

class AddressList extends React.Component<AddressListProps> {
  public static defaultProps = {
    className: "",
    onSelect: () => {},
    selectedAddress: undefined,
  };

  render() {
    const { className, selectedAddress, addressList, onSelect } = this.props;
    return (
      <div
        className={className + " wa-address-list"}
        style={{ borderColor: Theme.color.backgroundDarker }}
      >
        {addressList &&
          addressList.map((address, index) => (
            <AddressListItem
              isSelected={selectedAddress && selectedAddress.id === address.id}
              onSelect={onSelect}
              address={address}
              key={index}
            />
          ))}
      </div>
    );
  }
}
export default AddressList;
