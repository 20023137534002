import * as React from "react";
import "./index.css";
import { Validator } from "../../../infrastructure/helper";
import { CustomerService, ShoppingCartService } from "../../../services";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
import { EmailInput, PasswordInput } from "../../../components/Input";
import { OrderStore } from "../../../infrastructure/store";
import { connect } from "react-redux";
import {
  ApplicationState,
  RCustomerStore,
  ROrderStore,
  RShoppingCartStore,
  RForgetPasswordModalStore,
  RSignInModalStore,
  RSignUpModalStore,
} from "../../../store";
import { Theme } from "../../../infrastructure/config";
import { ShoppingCart } from "types/shoppingCart";

type SignInBodyProps = {
  className: string;
  defaultEmail: string;
  onSuccess: (email: string) => void;
  onHide: () => void;
} & typeof RCustomerStore.actionCreators &
  typeof RShoppingCartStore.actionCreators &
  typeof RSignUpModalStore.actionCreators &
  typeof RForgetPasswordModalStore.actionCreators &
  typeof RSignInModalStore.actionCreators &
  RCustomerStore.CustomerState &
  ROrderStore.OrderState;

type SignInBodyState = {
  email: string;
  isEmailValid: boolean;
  password: string;
  isPasswordValid: boolean;
  showAlert: boolean;
  alertMessage: string;
  disabledSignIn: boolean;
};

class SignInBody extends React.Component<SignInBodyProps, SignInBodyState> {
  public static defaultProps = {
    className: "",
    defaultEmail: "",
    onSuccess: () => {},
    onHide: () => {},
  };

  public state = {
    email: this.props.defaultEmail,
    isEmailValid: true,
    password: "",
    isPasswordValid: true,
    showAlert: false,
    alertMessage: "",
    disabledSignIn: false,
  };

  onEmailTextChange = (value: string) => {
    if (!this.state.isEmailValid)
      this.setState({ isEmailValid: true, email: value });
    else this.setState({ email: value });
  };

  onPasswordTextChange = (value: string) => {
    if (!this.state.isPasswordValid)
      this.setState({ isPasswordValid: true, password: value });
    else this.setState({ password: value });
  };

  showAlert = (message: string) => {
    this.setState({ showAlert: true, alertMessage: message });
  };

  hideAlert = () => {
    this.setState({ showAlert: false, alertMessage: "" });
  };

  validateInputs = () => {
    let validations = {
      isEmailValid: true,
      isPasswordValid: true,
    };
    const { email, password } = this.state;
    if (!email || email.trim() === "" || !Validator.isValidEmail(email)) {
      validations.isEmailValid = false;
    }

    if (!password || password.trim() === "" || password.length < 3) {
      validations.isPasswordValid = false;
    }

    if (!validations.isEmailValid || !validations.isPasswordValid)
      this.setState({
        isEmailValid: validations.isEmailValid,
        isPasswordValid: validations.isPasswordValid,
      });

    return validations.isEmailValid && validations.isPasswordValid;
  };

  onSignInPressed = async () => {
    if (!this.validateInputs()) return;
    this.setState({ disabledSignIn: true });
    const { email, password } = this.state;
    const {
      onSuccess,
      signIn,
      customer,
      updateShoppingCart,
      selectedOrderType,
    } = this.props;
    let authResult = await CustomerService.authenticateCustomer(
      email.toLowerCase(),
      password
    );

    if (!authResult.isSuccess) {
      this.showAlert(authResult.message);
      this.setState({ disabledSignIn: false });
      return;
    }
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let newCustomer = authResult.data;
    let migrateResult = await ShoppingCartService.migrateShoppingCart(
      customer === undefined ? "" : customer.id,
      newCustomer.id,
      orderProductGroup,
      newCustomer.token.token
    );

    signIn(newCustomer);
    onSuccess(email);
    this.setState({
      email: "",
      password: "",
      disabledSignIn: false,
    });

    if (migrateResult) {
      let shoppingCart = await this.getShoppingCart(
        newCustomer.id,
        newCustomer.token.token
      );

      if (shoppingCart) updateShoppingCart(shoppingCart);
    }
  };

  getShoppingCart = async (
    customerId: string,
    token: string
  ): Promise<ShoppingCart | undefined> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    let shoppingCartResult = await ShoppingCartService.getShoppingCart(
      customerId,
      orderProductGroup,
      selectedOrderType,
      token
    );

    if (shoppingCartResult.isSuccess) return shoppingCartResult.data;
    else return undefined;
  };

  render() {
    const {
      className,
      onHide,
      showForgetPasswordModal,
      showSignUpModal,
      hideForgetPasswordModal,
      hideSignUpModal,
      showSignInModal,
      hideSignInModal,
      onSuccess,
    } = this.props;
    const {
      email,
      isEmailValid,
      password,
      isPasswordValid,
      showAlert,
      alertMessage,
      disabledSignIn,
    } = this.state;
    return (
      <div className={className + " wa-sign-in-body"}>
        <Alert
          show={showAlert}
          bodyText={alertMessage}
          okButtonText={"OK"}
          onPressOk={this.hideAlert}
        />
        <EmailInput
          name="em-sign-in-2"
          label="E-mail"
          invalid={!isEmailValid}
          defaultValue={email}
          onChange={this.onEmailTextChange}
        />
        <PasswordInput
          name="pw-sign-in-2"
          label="Password"
          invalid={!isPasswordValid}
          defaultValue={password}
          onChange={this.onPasswordTextChange}
        />
        <div className="wa-sib-center">
          <a
            href="#0"
            className="wa-sib-forget-pass"
            onClick={(e) => {
              e.preventDefault();
              onHide();
              showForgetPasswordModal(
                () => {
                  hideForgetPasswordModal();
                  showSignInModal(hideSignInModal, onSuccess, email);
                },
                (email) => {
                  hideForgetPasswordModal();
                  showSignInModal(hideSignInModal, onSuccess, email);
                }
              );
            }}
            style={{ color: Theme.color.forgetPassword }}
          >
            Forget Password
          </a>
          <div className="wa-sib-remember-me-container">
            <input
              className=""
              type="checkbox"
              id="rememberMe2"
              defaultChecked={true}
            />
            <label className="wa-sib-remember-me-label" htmlFor="rememberMe2">
              Remember Me
            </label>
          </div>
        </div>
        <Button
          text="Sign-In"
          onClick={this.onSignInPressed}
          disabled={disabledSignIn}
        />
        <span
          className="wa-sib-create-account"
          style={{ backgroundColor: Theme.color.backgroundDarker }}
        >
          Don't you have an account?
          <a
            href="#0"
            className="wa-sib-sign-up"
            onClick={(e) => {
              e.preventDefault();
              onHide();
              showSignUpModal(
                () => {
                  hideSignUpModal();
                  showSignInModal(hideSignInModal, onSuccess, email);
                },
                (email) => {
                  hideSignUpModal();
                  showSignInModal(hideSignInModal, onSuccess, email);
                }
              );
            }}
            style={{ color: Theme.color.primary }}
          >
            Create Now!
          </a>
        </span>
      </div>
    );
  }
}

export default connect(
  (
    state: ApplicationState
  ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
    ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
      ROrderStore.OrderState),
  {
    ...RCustomerStore.actionCreators,
    ...RShoppingCartStore.actionCreators,
    ...RSignInModalStore.actionCreators,
    ...RSignUpModalStore.actionCreators,
    ...RForgetPasswordModalStore.actionCreators,
  }
)(SignInBody);
