import * as React from "react";
import "./index.css";
import { Theme } from "../../../../../infrastructure/config";
import {
  OrderStore,
  RestaurantInfoStore,
} from "../../../../../infrastructure/store";
import {
  LocationOn,
  Language,
  Mail,
  Phone,
  AccessTime,
} from "@material-ui/icons";
import { DayOfWeekType } from "types/date";
import { SystemStatus } from "types/system";
import { OrderType } from "types/order";
import OrderLimits from "./components/OrderLimits";

type MenuInfoProps = {
  className: string;
};

class MenuInfo extends React.Component<MenuInfoProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;
    let orderTypes = OrderStore.getActiveOrderTypes();
    let renderOrderTypes = {
      delivery: orderTypes
        ? orderTypes.find(
            (item) =>
              item.status === SystemStatus.Active &&
              item.orderType === OrderType.Delivery
          )
        : undefined,
      eatIn: orderTypes
        ? orderTypes.find(
            (item) =>
              item.status === SystemStatus.Active &&
              item.orderType === OrderType.EatIn
          )
        : undefined,
      takeaway: orderTypes
        ? orderTypes.find(
            (item) =>
              item.status === SystemStatus.Active &&
              item.orderType === OrderType.Takeaway
          )
        : undefined,
    };
    return (
      <div className={className + " wa-menu-info"}>
        <span
          className="wa-mi-label"
          style={{ color: Theme.color.onBackground }}
        >
          Order Times
        </span>
        <div
          className="wa-mi-container wa-mi-ot"
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          {renderOrderTypes.delivery && (
            <div
              className="wa-mi-ot-container"
              style={{ color: Theme.color.title }}
            >
              <div className="wa-mi-ot-label-container">
                <AccessTime
                  className="wa-mi-ot-icon"
                  htmlColor={Theme.color.deliveryButton}
                />
                <span
                  className="wa-mi-ot-label"
                  style={{ color: Theme.color.deliveryButton }}
                >
                  Delivery
                </span>
              </div>
              <span className="wa-mi-ot-value">
                {OrderStore.getOrderCompleteTime(OrderType.Delivery)}
              </span>
            </div>
          )}
          {renderOrderTypes.takeaway && (
            <div
              className="wa-mi-ot-container"
              style={{ color: Theme.color.title }}
            >
              <div className="wa-mi-ot-label-container">
                <AccessTime
                  className="wa-mi-ot-icon"
                  htmlColor={Theme.color.takeawayButton}
                />
                <span
                  className="wa-mi-ot-label"
                  style={{ color: Theme.color.takeawayButton }}
                >
                  Takeaway
                </span>
              </div>
              <span className="wa-mi-ot-value">
                {OrderStore.getOrderCompleteTime(OrderType.Takeaway)}
              </span>
            </div>
          )}
          {renderOrderTypes.eatIn && (
            <div
              className="wa-mi-ot-container"
              style={{ color: Theme.color.title }}
            >
              <div className="wa-mi-ot-label-container">
                <AccessTime
                  className="wa-mi-ot-icon"
                  htmlColor={Theme.color.eatInButton}
                />
                <span
                  className="wa-mi-ot-label"
                  style={{ color: Theme.color.eatInButton }}
                >
                  Eat In
                </span>
              </div>
              <span className="wa-mi-ot-value">
                {OrderStore.getOrderCompleteTime(OrderType.EatIn)}
              </span>
            </div>
          )}
        </div>
        <span
          className="wa-mi-label"
          style={{ color: Theme.color.onBackground }}
        >
          Order Limits
        </span>
        <div
          className="wa-mi-container"
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          <OrderLimits />
        </div>
        <span
          className="wa-mi-label"
          style={{ color: Theme.color.onBackground }}
        >
          Working Hours
        </span>
        <div
          className="wa-mi-container"
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          <WorkingHours
            dayOfWeek="Sunday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Sunday
            )}
          />
          <WorkingHours
            dayOfWeek="Monday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Monday
            )}
          />
          <WorkingHours
            dayOfWeek="Tuesday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Tuesday
            )}
          />
          <WorkingHours
            dayOfWeek="Wednesday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Wednesday
            )}
          />
          <WorkingHours
            dayOfWeek="Thursday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Thursday
            )}
          />
          <WorkingHours
            dayOfWeek="Friday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Friday
            )}
          />
          <WorkingHours
            dayOfWeek="Saturday"
            workingHours={RestaurantInfoStore.getWorkingTime(
              DayOfWeekType.Saturday
            )}
          />
        </div>
        <span
          className="wa-mi-label"
          style={{ color: Theme.color.onBackground }}
        >
          Contact Us
        </span>
        <div
          className="wa-mi-container"
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          <ContactUs
            iconType="location"
            link={
              "https://maps.google.com/?q=" + RestaurantInfoStore.getLocation()
            }
            target="_blank"
            label={RestaurantInfoStore.getAddress()}
          />
          <ContactUs
            iconType="world"
            link={RestaurantInfoStore.getWebSite()}
            target="_blank"
            label={RestaurantInfoStore.getWebSiteText()}
          />
          <ContactUs
            iconType="mail"
            link={"mailto:" + RestaurantInfoStore.getContactMail()}
            label={RestaurantInfoStore.getContactMailText()}
          />
          <ContactUs
            iconType="phone"
            link={"tel:" + RestaurantInfoStore.getPhone()}
            label={RestaurantInfoStore.getPhoneText()}
          />
        </div>
      </div>
    );
  }
}

export default MenuInfo;

type WorkingHoursProps = {
  dayOfWeek:
    | "Sunday"
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday";
  workingHours: string;
};

class WorkingHours extends React.Component<WorkingHoursProps> {
  render() {
    const { dayOfWeek, workingHours } = this.props;
    return (
      <div className="wa-mi-wh-row">
        <span className="wa-mi-wh-label" style={{ color: Theme.color.title }}>
          {dayOfWeek}
        </span>
        <span className="wa-mi-wh-value" style={{ color: Theme.color.title }}>
          : {workingHours}
        </span>
      </div>
    );
  }
}

type ContactUsProps = {
  iconType: "location" | "world" | "mail" | "phone";
  target: "_blank" | undefined;
  link: string;
  label: string;
};
class ContactUs extends React.Component<ContactUsProps> {
  public static defaultProps = {
    target: undefined,
  };

  render() {
    const { iconType, link, label, target } = this.props;
    return (
      <div className="wa-mi-cu-row">
        <div className="wa-mi-cu-icon-container">
          {iconType === "location" ? (
            <LocationOn
              className="wa-mi-cu-icon"
              htmlColor={Theme.color.secondary}
            />
          ) : iconType === "world" ? (
            <Language
              className="wa-mi-cu-icon"
              htmlColor={Theme.color.secondary}
            />
          ) : iconType === "mail" ? (
            <Mail className="wa-mi-cu-icon" htmlColor={Theme.color.secondary} />
          ) : (
            <Phone
              className="wa-mi-cu-icon"
              htmlColor={Theme.color.secondary}
            />
          )}
        </div>
        <a
          href={link}
          target={target}
          style={{ color: Theme.color.descriptionDarker }}
          rel="noopener noreferrer"
          className="wa-mi-cu-link"
        >
          {label}
        </a>
      </div>
    );
  }
}
