import * as React from "react";
import "./index.css";
import Input from "../BaseInput";

type EmailInputProps = {
  name?: string;
  className?: string;
  invalid?: boolean;
  defaultValue?: string;
  label: string;
  disabled?: boolean;
  onChange?: (text: string) => void;
};

class EmailInput extends React.Component<EmailInputProps> {
  render() {
    const {
      className,
      label,
      defaultValue,
      onChange,
      invalid,
      disabled,
      name,
    } = this.props;
    return (
      <Input
        maxLength={50}
        name={name}
        label={label}
        disabled={disabled}
        defaultValue={defaultValue}
        className={className}
        onChange={onChange}
        invalid={invalid}
        inputType={"email"}
      />
    );
  }
}

export default EmailInput;
