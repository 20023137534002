import * as React from "react";
import "./index.css";
import LeftAreaSignIn from "./SignIn";
import LeftMenu from "./LeftMenu";
import { RestaurantInfoStore } from "../../../../../infrastructure/store";
import { ApplicationState, RCustomerStore } from "../../../../../store";
import { connect } from "react-redux";
import { Theme } from "../../../../../infrastructure/config";
import { KeyboardReturn } from "@material-ui/icons";

type LeftAreaProps = {
  className: string;
} & RCustomerStore.CustomerState;

class LeftArea extends React.Component<LeftAreaProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className, isSignedIn } = this.props;
    return (
      <div className={className + " wa-left-area"}>
        {isSignedIn === false && (
          <div className="wa-la-sign-in-container">
            <LeftAreaSignIn />
            <a
              href={RestaurantInfoStore.getWebSite()}
              className="wa-la-return"
              style={{ color: Theme.color.onBackground }}
            >
              <KeyboardReturn
                className="wa-la-return-icon"
                htmlColor={Theme.color.onBackground}
              />
              Restaurant Page
            </a>
          </div>
        )}
        {isSignedIn === true && <LeftMenu />}
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.customer,
  RCustomerStore.actionCreators
)(LeftArea);
