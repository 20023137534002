import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore } from "../../../store";
import { FacebookPixel } from "../../../infrastructure/helper/";

type PageLayoutProps = {} & RCustomerStore.CustomerState;

class PageLayout extends Component<PageLayoutProps> {
  fbPixel = new FacebookPixel();

  componentDidMount = async () => {
    const { customer } = this.props;
    if (customer) this.fbPixel.setEmail(customer.email);
    this.fbPixel.track();
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

export default connect(
  (state: ApplicationState): RCustomerStore.CustomerState =>
    ({
      ...state.customer,
    } as RCustomerStore.CustomerState)
)(PageLayout);
