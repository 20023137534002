import React, { Component } from "react";
import "./index.css";
import { Theme } from "../../../../infrastructure/config";
import { PhoneAndroid } from "@material-ui/icons";

type ApproveBasketPhoneNumberProps = {
  onChange: (text: string) => void;
};

class ApproveBasketPhoneNumber extends Component<ApproveBasketPhoneNumberProps> {
  render() {
    const { onChange } = this.props;
    return (
      <>
        <span
          className="wa-ab-title"
          style={{ color: Theme.color.onBackground }}
        >
          Phone:
        </span>
        <div className="wa-ab-section">
          <div
            className="wa-pn-wrapper"
            style={{
              backgroundColor: Theme.color.containerBackground,
              borderColor: Theme.color.inputBorder,
            }}
          >
            <PhoneAndroid
              fontSize="large"
              className="wa-pn-icon"
              htmlColor={Theme.color.title}
            />
            <input
              className="wa-pn-input"
              placeholder="0 (___) ___ ___"
              onChange={(event) => onChange && onChange(event.target.value)}
              maxLength={11}
              type={"tel"}
              style={{ borderColor: Theme.color.inputBorder }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ApproveBasketPhoneNumber;
