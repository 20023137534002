import * as React from "react";
import { PaymentService } from "../../services";
import {
  SquareUpSettings,
  PaymentType,
  PaymentEnvironment,
} from "../../types/payment";
import { connect } from "react-redux";
import { ApplicationState, RCustomerStore, ROrderStore } from "../../store";
import { OrderStore } from "../../infrastructure/store";
import { withRouter, RouteComponentProps } from "react-router";
import ModalContainer from "../ModalContainer";
import "./index.css";

declare global {
  interface Window {
    Square: any;
  }
}

type SquareUpPaymentProps = {
  startPayment: boolean;
  deliveryDistanceId: string;
  onComplete: (orderId?: string, paymentHistoryId?: string) => void;
  onError: () => void;
  onCancel: () => void;
  orderTotal: number;
  environment: PaymentEnvironment;
} & RCustomerStore.CustomerState &
  RouteComponentProps &
  ROrderStore.OrderState;

type SquareUpPaymentState = {
  settings: SquareUpSettings;
  loaded: boolean;
  formLoaded: boolean;
  errors: string[];
};

class SquareUpPayment extends React.Component<
  SquareUpPaymentProps,
  SquareUpPaymentState
> {
  constructor(props: SquareUpPaymentProps) {
    super(props);
    this.dummyRender = this.dummyRender.bind(this);
    this.getSquareUpSettings = this.getSquareUpSettings.bind(this);
    this.nonceReceived = this.nonceReceived.bind(this);
    this.state = {
      settings: {} as SquareUpSettings,
      loaded: false,
      formLoaded: false,
      errors: [],
    };
  }

  public static defaultProps = {
    deliveryDistanceId: "",
  };

  componentDidMount = async () => {
    const { history } = this.props;
    let settings = await this.getSquareUpSettings();
    if (!settings) this.onClose(history.goBack);
    else this.setState({ settings: settings });
  };

  componentDidUpdate = async () => {
    const { history } = this.props;
    const { settings, formLoaded } = this.state;
    if (settings && settings.accessToken) {
      this.initialiseSquareUp(settings, formLoaded);
      return;
    }
    let actualSettings = await this.getSquareUpSettings();
    if (!actualSettings) this.onClose(history.goBack);
    else {
      this.initialiseSquareUp(actualSettings, false);
      this.setState({ settings: actualSettings });
    }
  };

  getSquareUpSettings = async (): Promise<SquareUpSettings | undefined> => {
    const { customer, environment } = this.props;
    if (customer === undefined || customer.id === undefined) return undefined;
    let settingsResult = await PaymentService.getSquareUpSettings(
      environment,
      customer.token.token
    );

    if (settingsResult.isSuccess) return settingsResult.data;
    else return undefined;
  };

  onClose = (func?: () => void) => {
    this.setState({ loaded: false, formLoaded: false });

    if (func) func();
  };

  initialiseSquareUp = async (
    settings: SquareUpSettings,
    formLoaded: boolean
  ): Promise<void> => {
    try {
      if (formLoaded) {
        this.setState({ loaded: true, formLoaded: true });
        return;
      }

      const scriptId = "squareup-js";
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");

        script.src =
          settings.environment == PaymentEnvironment.Production
            ? "https://sandbox.web.squarecdn.com/v1/square.js"
            : "https://sandbox.web.squarecdn.com/v1/square.js";
        script.type = "text/javascript";
        script.id = scriptId;
        script.async = false;
        document.getElementsByTagName("head")[0].appendChild(script);
      }

      const payments = window.Square.payments(
        settings.applicationId,
        settings.locationId
      );

      if (!document.getElementById("card-number-wrapper")) {
        const card = await payments.card();
        await card.attach("#card-container");

        const cardButton = document.getElementById("card-button");
        if (cardButton)
          cardButton.addEventListener("click", (e) =>
            this.handlePaymentMethodSubmission(e, card, cardButton, payments)
          );
      }
      this.setState({ loaded: true, formLoaded: true });
    } catch (error) {
      console.debug(error);
    }
  };

  handlePaymentMethodSubmission = async (
    event: MouseEvent,
    card: any,
    cardButton: any,
    payments: any
  ): Promise<void> => {
    event.preventDefault();

    try {
      this.setState({ errors: [] });
      cardButton.disabled = true;
      const token = await this.tokenize(card);
      if (token == "") throw "Error";
      let verificationToken = await this.verifyBuyer(payments, token);
      if (!verificationToken || verificationToken === "") throw "Error";

      await this.nonceReceived(token, verificationToken);
    } catch (e) {
      console.error(e);
      this.setState({
        errors: [
          (e as any).message
            ? (e as any).message
            : "An unexpected error has occurred.",
        ],
      });
    } finally {
      cardButton.disabled = false;
    }
  };

  tokenize = async (card: any): Promise<string> => {
    try {
      const result = await card.tokenize();

      if (result.status === "OK") return result.token;
      else return "";
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  verifyBuyer = async (payments: any, token: string): Promise<string> => {
    const { customer, orderTotal } = this.props;
    if (!customer || orderTotal <= 0) return "";

    let customerName = this.splitCustomerName(customer.fullName);
    const verificationDetails = {
      amount: orderTotal.toFixed(2).toString(),
      billingContact: {
        familyName: customerName[1],
        givenName: customerName[0],
        email: customer.email,
        country: "GB",
      },
      currencyCode: "GBP",
      intent: "CHARGE",
    };

    try {
      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );

      return verificationResults.token;
    } catch (error) {
      console.debug("Error", error);
      throw error;
    }
  };

  splitCustomerName = (fullName: string): string[] => {
    let splittedName = fullName.split(" ");
    let lastName;
    if (splittedName.length > 1) lastName = splittedName.pop();
    else lastName = "";
    let fistName = splittedName.join(" ");
    if (!lastName) lastName = "";

    return [fistName, lastName];
  };

  nonceReceived = async (nonce: string, verificationToken: string) => {
    const {
      customer,
      deliveryDistanceId,
      onComplete,
      selectedOrderType,
      environment,
    } = this.props;
    if (nonce && customer) {
      this.setState({ loaded: false });
      let orderProductGroup =
        OrderStore.getOrderProductGroup(selectedOrderType);
      let paymentResult = await PaymentService.processPayment(
        {
          environment: environment,
          paymentType: PaymentType.SquareUp,
          token: nonce,
          verificationData: verificationToken,
        },
        orderProductGroup,
        deliveryDistanceId,
        customer.id,
        customer.token.token
      );
      if (paymentResult.isSuccess) {
        onComplete(
          paymentResult.data.orderId,
          paymentResult.data.paymentHistoryId
        );
        this.setState({ loaded: true });
      } else {
        this.setState({ errors: [paymentResult.message] });
        this.setState({ loaded: true });
      }
    }
  };

  createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: false,
      currencyCode: "GBP",
      countryCode: "GB",
      total: {
        label: "",
        amount: "",
        pending: false,
      },
    };
  };

  dummyRender() {
    const { startPayment, onCancel } = this.props;
    const { settings, loaded, errors } = this.state;
    return (
      <ModalContainer
        show={startPayment}
        showHeader={true}
        className="wa-square-up-payment"
        headerText="Online Payment"
        showLoading={!loaded || !settings}
        onHide={() => {
          this.setState({ loaded: false });
          this.onClose(onCancel);
        }}
      >
        <form id="payment-form">
          <div id="card-container"></div>

          <button id="card-button" type="button">
            PAY NOW
          </button>
          <div
            id="payment-status-container"
            className={errors && errors.length > 0 ? "visible" : ""}
          >
            {errors.map((errorMessage) => (
              <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
            ))}
          </div>
        </form>
      </ModalContainer>
    );
  }

  render() {
    return <this.dummyRender />;
  }
}

export default withRouter(
  connect(
    (
      state: ApplicationState
    ): RCustomerStore.CustomerState & ROrderStore.OrderState =>
      ({ ...state.customer, ...state.order } as RCustomerStore.CustomerState &
        ROrderStore.OrderState)
  )(SquareUpPayment)
);
