import * as React from "react";
import "./index.css";
import { TextHelper } from "../../../../../../../infrastructure/helper";
import {
  OrderLimit as OrderLimitType,
  OrderType,
} from "../../../../../../../types/order";
import { Theme } from "infrastructure/config";

type OrderLimitProps = {
  orderType: OrderType;
  showDivider: boolean;
  orderLimit?: OrderLimitType;
  limitsWithDistance?: OrderLimitType[];
};

class OrderLimit extends React.Component<OrderLimitProps> {
  public static defaultProps = {
    showDivider: false,
  };

  render() {
    const {
      orderType,
      orderLimit,
      limitsWithDistance,
      showDivider,
    } = this.props;

    let hasLimitsWithDistance =
      limitsWithDistance !== undefined &&
      limitsWithDistance !== null &&
      limitsWithDistance.length > 0;
    return (
      <>
        <div className="wa-ol-container">
          <span>
            {orderLimit !== undefined &&
            orderLimit !== null &&
            orderLimit.minOrderAmount > 0
              ? "There is a minimum spend on a "
              : "The minimum spend on a "}
            <span
              style={{
                color:
                  orderType === OrderType.Delivery
                    ? Theme.color.deliveryButton
                    : orderType === OrderType.Takeaway
                    ? Theme.color.takeawayButton
                    : orderType === OrderType.EatIn
                    ? Theme.color.eatInButton
                    : Theme.color.button,
              }}
              className="wa-ol-order-type"
            >
              {TextHelper.getOrderTypeText(orderType)}
            </span>
            &nbsp;order&nbsp;
            {hasLimitsWithDistance === false && (
              <>
                is&nbsp;
                <span className="wa-ol-main-limit-value">
                  {orderLimit !== undefined &&
                  orderLimit !== null &&
                  orderLimit.minOrderAmount > 0
                    ? `£${orderLimit.minOrderAmount.toFixed(2)}`
                    : "£0"}
                </span>
              </>
            )}
          </span>
          {hasLimitsWithDistance && limitsWithDistance ? (
            limitsWithDistance
              .filter(
                (item) =>
                  item.deliveryDistance !== undefined &&
                  item.deliveryDistance.isAvailable === true
              )
              .map((item, index) => (
                <div className="wa-ol-limit-container" key={index}>
                  <span className="wa-ol-limit-value">
                    {orderLimit !== undefined &&
                    orderLimit !== null &&
                    orderLimit.minOrderAmount > 0
                      ? `-£${item.minOrderAmount.toFixed(2)}`
                      : "-£0"}
                  </span>
                  &nbsp;for the distances&nbsp;
                  {item.deliveryDistance &&
                    (item.deliveryDistance.displayDistanceFrom === 0
                      ? `with in ${item.deliveryDistance.displayDistanceTo} mile(s)`
                      : item.deliveryDistance.displayDistanceTo !== null &&
                        item.deliveryDistance.displayDistanceTo !== undefined
                      ? `between ${item.deliveryDistance.displayDistanceFrom} mile(s) and ${item.deliveryDistance.displayDistanceTo} mile(s)`
                      : `longer than ${item.deliveryDistance.displayDistanceFrom} mile(s)`)}
                </div>
              ))
          ) : (
            <></>
          )}
        </div>
        {showDivider && (
          <div
            style={{ borderColor: Theme.color.inputBorder }}
            className="wa-ol-divider"
          ></div>
        )}
      </>
    );
  }
}

export default OrderLimit;
