import apiRequest, {
  ApiRequestConfig,
} from "../infrastructure/helper/apiRequest";
import {
  HttpRequestMethod,
  HttpRequestOptions,
} from "../infrastructure/helper/httpRequest";
import {
  ShoppingCart,
  AddToShoppingCart,
  ShoppingCartItem,
  UpdateShoppingCartItem,
  ShoppingCartSummary,
} from "../types/shoppingCart";
import { OrderProductGroupType, OrderType } from "../types/order";
import { ApiResponse } from "types/response";

export async function getShoppingCart(
  customerId: string,
  orderProductGroup: OrderProductGroupType,
  orderType: OrderType,
  token: string
): Promise<ApiResponse<ShoppingCart>> {
  return await apiRequest<ShoppingCart>({
    url: `shopping-cart?customerId=${customerId}&orderProductGroup=${orderProductGroup}&orderType=${orderType}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getShoppingCartSummary(
  customerId: string,
  deliveryDistanceId: string | null,
  orderProductGroup: OrderProductGroupType,
  orderType: OrderType,
  token: string
): Promise<ApiResponse<ShoppingCartSummary>> {
  return await apiRequest<ShoppingCartSummary>({
    url: `shopping-cart/summary?customerId=${customerId}&deliveryDistanceId=${deliveryDistanceId}&orderProductGroup=${orderProductGroup}&orderType=${orderType}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function emptyShoppingCart(
  customerId: string,
  orderProductGroup: OrderProductGroupType,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `shopping-cart/empty?customerId=${customerId}&orderProductGroup=${orderProductGroup}`,
    options: {
      method: HttpRequestMethod.DELETE,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function getShoppingCartItem(
  customerId: string,
  shoppingCartItemId: string,
  orderProductGroup: OrderProductGroupType,
  token: string
): Promise<ApiResponse<ShoppingCartItem>> {
  return await apiRequest<ShoppingCartItem>({
    url: `shopping-cart/item?shoppingCartItemId=${shoppingCartItemId}&customerId=${customerId}&orderProductGroup=${orderProductGroup}`,
    options: {
      method: HttpRequestMethod.GET,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function migrateShoppingCart(
  previousCustomerId: string,
  currentCustomerId: string,
  orderProductGroup: OrderProductGroupType,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest({
    url: `shopping-cart/migrate?previousCustomerId=${previousCustomerId}&currentCustomerId=${currentCustomerId}&orderProductGroup=${orderProductGroup}`,
    options: {
      method: HttpRequestMethod.POST,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function removeFromShoppingCart(
  shoppingCartItemId: string,
  customerId: string,
  orderProductGroup: OrderProductGroupType,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `shopping-cart?shoppingCartItemId=${shoppingCartItemId}&orderProductGroup=${orderProductGroup}&customerId=${customerId}&customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.DELETE,
      authToken: token,
    } as HttpRequestOptions,
  } as ApiRequestConfig);
}

export async function updateShoppingCartItem(
  customerId: string,
  updateShoppingCartItem: UpdateShoppingCartItem,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `shopping-cart/update-item?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.POST,
      authToken: token,
    } as HttpRequestOptions,
    data: updateShoppingCartItem,
  } as ApiRequestConfig);
}

export async function addToShoppingCart(
  addToShoppingCart: AddToShoppingCart,
  customerId: string,
  token: string
): Promise<ApiResponse<any>> {
  return await apiRequest<any>({
    url: `shopping-cart?customerId=${customerId}`,
    options: {
      method: HttpRequestMethod.PUT,
      authToken: token,
    } as HttpRequestOptions,
    data: addToShoppingCart,
  } as ApiRequestConfig);
}
