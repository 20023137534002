import * as React from "react";
import "./index.css";
import { Theme } from "../../infrastructure/config";
import { CircularProgress } from "@material-ui/core";

type LoadingProps = {
  show: boolean;
  className: string;
};

class Loading extends React.Component<LoadingProps> {
  public static defaultProps = {
    className: "",
    show: false,
  };

  render() {
    const { children, show, className } = this.props;

    return (
      <>
        <div
          className={"wa-loading" + (show ? " active" : "")}
          style={{ backgroundColor: Theme.color.containerBackground }}
        >
          <CircularProgress
            className="wa-loading-icon"
            style={{ color: Theme.color.primary }}
          />
          <span
            style={{ color: Theme.color.title }}
            className="wa-loading-text"
          >
            Loading...
          </span>
        </div>
        <div
          className={
            "wa-loading-child" +
            (show ? " hidden" : "") +
            (className ? " " + className : "")
          }
        >
          {children}
        </div>
      </>
    );
  }
}

export default Loading;
