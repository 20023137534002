import "./index.css";
import React, { Component } from "react";
import { OrderSelectedAvailableTime, OrderType } from "../../../../types/order";
import { Theme } from "../../../../infrastructure/config";
import { KeyboardArrowRight, AccessTime } from "@material-ui/icons";
import OrderSelectTime from "../../../partial/OrderSelectTime";

type ApproveBasketScheduleProps = {
  onSelectScheduleTime: (scheduleTime?: OrderSelectedAvailableTime) => void;
  asap: boolean;
  orderType: OrderType;
  scheduleTime?: OrderSelectedAvailableTime;
};

type ApproveBasketScheduleState = {
  showOrderTimes: boolean;
};

class ApproveBasketSchedule extends Component<
  ApproveBasketScheduleProps,
  ApproveBasketScheduleState
> {
  state = {
    showOrderTimes: false,
  };

  onClick = () => {
    this.setState({ showOrderTimes: true });
  };

  onSelectTime = (scheduleTime?: OrderSelectedAvailableTime) => {
    const { onSelectScheduleTime } = this.props;
    onSelectScheduleTime(scheduleTime);
    this.setState({ showOrderTimes: false });
  };

  render() {
    const { asap, scheduleTime, orderType } = this.props;
    const { showOrderTimes } = this.state;
    return (
      <>
        <span
          className="wa-ab-title"
          style={{ color: Theme.color.onBackground }}
        >
          When:
        </span>
        <div className="wa-schedule-wrapper">
          <button
            onClick={(e) => {
              e.preventDefault();
              this.onClick();
            }}
            className={"wa-schedule-selection"}
            style={{
              backgroundColor: Theme.color.containerBackground,
              borderColor: Theme.color.inputBorder,
            }}
          >
            {showOrderTimes && (
              <OrderSelectTime
                onSelect={this.onSelectTime}
                asap={asap}
                orderType={orderType}
                show={showOrderTimes}
                onHide={() => this.setState({ showOrderTimes: false })}
              />
            )}
            <AccessTime fontSize={"large"} htmlColor={Theme.color.title} />
            <div className="wa-schedule-selection-body">
              {(scheduleTime === undefined || scheduleTime === null) &&
              !asap ? (
                <span
                  className="wa-schedule-selection-title"
                  style={{ color: Theme.color.secondary }}
                >
                  {"Select"}
                </span>
              ) : (
                <span
                  className="wa-schedule-selection-title"
                  style={{ color: Theme.color.title }}
                >
                  {scheduleTime
                    ? scheduleTime.dayText
                      ? `${scheduleTime.dayText} ${scheduleTime.timeText}`
                      : `${scheduleTime.timeText}`
                    : asap
                    ? "ASAP (As soon as possible)"
                    : "Select"}
                </span>
              )}
            </div>
            <KeyboardArrowRight
              fontSize={"large"}
              htmlColor={Theme.color.touchableIcon}
            />
          </button>
        </div>
      </>
    );
  }
}

export default ApproveBasketSchedule;
