import { OrderProductGroupType } from "types/order";

export interface Product {
  id: string;
  name: string;
  description: string;
  displayOrder: number;
  price: number;
  discountedPrice?: number;
  orderProductGroup: OrderProductGroupType;
  productOptions: ProductOption[];
}

export interface ProductOption {
  id: string;
  name: string;
  type: ProductOptionType;
  displayOrder: number;
  isRequired: boolean;
  limitBy: ProductOptionLimitType;
  limitQuantity: number;
  productOptionItems: ProductOptionItem[];
}

export interface ProductOptionItem {
  id: string;
  name: string;
  price: number;
  selected: boolean;
  displayOrder: number;
  quantity: number;
}

export interface SelectedOption {
  productOptionItemId: string;
  quantity: number;
}

export enum ProductOptionType {
  None = 0,
  Single = 1,
  Multiple = 2,
  MultipleQuantity = 3,
}

export enum ProductOptionLimitType {
  None = 0,
  Minimum = 1,
  Equal = 2,
  Maximum = 3,
}
