import * as React from "react";
import MenuList from "./MenuList";
import MenuCategoryList from "./MenuCategoryList";
import MenuTabList, { ActiveTab } from "./MenuTabList";
import MenuInfo from "./MenuInfo";
import { Category } from "../../../../types/category";
import { ProductService } from "../../../../services/";
import { Product } from "../../../../types/product";
import { connect } from "react-redux";
import { ApplicationState, ROrderStore } from "store";
import { OrderStore } from "../../../../infrastructure/store";
import { OrderProductGroupType } from "types/order";

type MenuProps = {
  className: string;
} & ROrderStore.OrderState;

type MenuState = {
  activeTab: ActiveTab;
  categories: Category[];
  filteredCategories: Category[];
  searchText: string;
};

class Menu extends React.Component<MenuProps, MenuState> {
  public state = {
    activeTab: ActiveTab.MenuList,
    categories: [] as Category[],
    filteredCategories: [] as Category[],
    searchText: "",
  };

  public static defaultProps = {
    className: "",
  };

  componentDidMount = async () => {
    let productList = await this.getProducts();
    this.setState({ categories: productList });
  };

  getProducts = async (): Promise<Category[]> => {
    const { selectedOrderType } = this.props;
    let orderProductGroup = OrderStore.getOrderProductGroup(selectedOrderType);
    if (
      orderProductGroup !== OrderProductGroupType.EatIn &&
      orderProductGroup !== OrderProductGroupType.DeliveryTakeaway
    ) {
      return [] as Category[];
    }
    let productListResult = await ProductService.getProducts(orderProductGroup);

    if (productListResult.isSuccess) return productListResult.data;
    else return [] as Category[];
  };

  onChange = (activeTab: ActiveTab) => {
    this.setState({ activeTab: activeTab });
  };

  onSearchTextChange = (text: string) => {
    const { categories } = this.state;
    let allCategories = [...categories];
    if (text && text.trim() !== "") {
      if (allCategories && allCategories.length > 0) {
        let filteredCategories = this.filterCategories(allCategories, text);
        this.setState({
          filteredCategories: filteredCategories,
          searchText: text,
        });
      } else {
        this.setState({ filteredCategories: [], searchText: text });
      }
    } else {
      this.setState({ filteredCategories: [], searchText: text });
    }
  };

  filterCategories = (categoryList: Category[], text: string): Category[] => {
    let result = [] as Category[];
    if (!categoryList || categoryList.length <= 0) return result;
    categoryList.forEach((category) => {
      let products = this.filterProducts(category.products, text);
      if (products.length > 0)
        result.push({
          name: category.name,
          id: category.id,
          products: products,
        } as Category);
    });

    return result;
  };

  filterProducts = (products: Product[], text: string): Product[] => {
    text = text ? text : "";
    let productList = [] as Product[];
    if (!products || products.length <= 0) return [];
    products.forEach((product) => {
      if (
        product.name &&
        product.name.toLowerCase().includes(text.toLowerCase())
      ) {
        productList.push(product);
      }
    });

    return productList;
  };

  render() {
    const { className } = this.props;
    const {
      activeTab,
      categories,
      filteredCategories,
      searchText,
    } = this.state;
    let renderCategoryList = [] as Category[];
    if (searchText && searchText.length > 0) {
      renderCategoryList = filteredCategories ? filteredCategories : [];
    } else {
      renderCategoryList = categories;
    }

    return (
      <div className={className}>
        <MenuTabList activeTab={activeTab} onChange={this.onChange} />
        {activeTab === ActiveTab.CategoryList && (
          <MenuCategoryList categories={categories} />
        )}
        {activeTab === ActiveTab.MenuList && (
          <MenuList
            onSearchTextChange={this.onSearchTextChange}
            categories={renderCategoryList}
          />
        )}
        {activeTab === ActiveTab.Info && <MenuInfo />}
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState): ROrderStore.OrderState =>
    ({ ...state.order } as ROrderStore.OrderState)
)(Menu);
