import React from "react";
import "./index.css";
import SelectOrderTypeButton from "../select-order-type-button";

export interface DeliveryTakeAwayButtonProps {
  text: "Delivery" | "Takeaway" | "Delivery / Takeaway";
}

class DeliveryTakeAwayButton extends React.Component<
  DeliveryTakeAwayButtonProps
> {
  public render() {
    const { text } = this.props;

    return <SelectOrderTypeButton text={text} />;
  }
}

export default DeliveryTakeAwayButton;
