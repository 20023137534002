import * as React from "react";
import "./index.css";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { Theme } from "../../../../../infrastructure/config";
import MenuItemExtraSingleItem from "../MenuItemExtraSingleItem";
import { ProductOption, SelectedOption } from "../../../../../types/product";

type MenuItemExtraSingleProps = {
  className: string;
  option: ProductOption;
  index: number;
  onSelect: (index: number, option: ProductOption) => void;
};

type MenuItemExtraSingleState = {
  expanded: boolean;
  selectedText: string;
  option: ProductOption;
  isValid: boolean;
};

class MenuItemExtraSingle extends React.Component<
  MenuItemExtraSingleProps,
  MenuItemExtraSingleState
> {
  items: any;
  constructor(props: MenuItemExtraSingleProps) {
    super(props);
    this.items = {};

    let optionItemsLength =
      props.option && props.option.productOptionItems
        ? props.option.productOptionItems.length
        : 0;
    this.createItemRefs(optionItemsLength);
  }

  public static defaultProps = {
    className: "",
  };

  public state = {
    expanded: false,
    option: this.props.option,
    selectedText: "Choose",
    isValid: true,
  };

  createItemRefs = (length: number): void => {
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      this.items[`item${index}`] = React.createRef();
    }
  };

  toggle = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  getItemOptions = (): SelectedOption[] => {
    const { option } = this.state;
    const { productOptionItems } = option;

    if (productOptionItems && productOptionItems.length > 0) {
      let itemOptions: SelectedOption[] = [];

      for (let index = 0; index < productOptionItems.length; index++) {
        let item = this.items[`item${index}`].current;
        if (item) {
          let itemOption = item.getItemOption() as SelectedOption;
          if (itemOption) itemOptions.push(itemOption);
        }
      }
      return itemOptions;
    } else {
      return [];
    }
  };

  componentDidMount = () => {
    this.detectInitialSelectedText();
  };

  detectInitialSelectedText = () => {
    const { option, selectedText } = this.state;
    let newSelectedText = selectedText;
    if (
      option &&
      option.productOptionItems &&
      option.productOptionItems.length >= 0
    ) {
      option.productOptionItems.forEach((item) => {
        if (item.selected) {
          newSelectedText = item.name;
        }
      });
      this.setState({ selectedText: newSelectedText });
    }
  };

  onSelect = (selectedIndex: number) => {
    const { option, selectedText } = this.state;
    const { onSelect, index } = this.props;
    let newSelectedText = selectedText;
    if (
      option &&
      option.productOptionItems &&
      option.productOptionItems.length > selectedIndex
    ) {
      option.productOptionItems.forEach((item, i) => {
        if (i === selectedIndex) {
          item.selected = true;
          newSelectedText = item.name;
        } else item.selected = false;
      });
      this.setState(
        {
          option: option,
          selectedText: newSelectedText,
          expanded: false,
        },
        () => {
          onSelect(index, option);
        }
      );
    }
  };

  markAsValid = () => {
    this.setState({ isValid: true });
  };

  isValid = (): boolean => {
    const { option } = this.state;
    let isValid = true;
    let totalQuantity = this.getTotalQuantity();
    if (totalQuantity !== 1 && option.isRequired) isValid = false;

    this.setState({ isValid: isValid });
    return isValid;
  };

  getQuantity = (index: number): number => {
    let item = this.items[`item${index}`].current;
    if (item) return item.getQuantity() as number;
    else return 0;
  };

  getTotalPrice = (): number => {
    const { option } = this.state;
    const { productOptionItems } = option;
    if (!productOptionItems || productOptionItems.length <= 0) return 0;
    let totalPrice = 0;
    for (let index = 0; index < productOptionItems.length; index++) {
      let item = this.items[`item${index}`].current;
      if (item) totalPrice += item.getTotalPrice() as number;
    }

    return totalPrice;
  };

  getTotalQuantity = (): number => {
    const { option } = this.state;
    const { productOptionItems } = option;
    if (!productOptionItems || productOptionItems.length <= 0) return 0;
    let totalQuantity = 0;
    for (let index = 0; index < productOptionItems.length; index++) {
      totalQuantity += this.getQuantity(index);
    }

    return totalQuantity;
  };

  render() {
    const { className, option } = this.props;
    const { expanded, selectedText, isValid } = this.state;
    return (
      <div
        className={
          className +
          " wa-menu-item-extra-single" +
          (!isValid ? " invalid" : "")
        }
        style={{
          backgroundColor: Theme.color.containerBackground,
          borderColor: !isValid
            ? Theme.color.error
            : Theme.color.backgroundDarker,
        }}
      >
        <button
          onClick={this.toggle}
          className="wa-menu-item-extra-single-title-container"
          style={{
            backgroundColor: Theme.color.containerBackground,
          }}
        >
          <span
            className="wa-menu-item-extra-single-title"
            style={{
              color: Theme.color.primary,
            }}
          >
            {option.name}
          </span>

          <div
            className="wa-menu-item-extra-single-right-container"
            style={{ color: Theme.color.title }}
          >
            <span className="wa-menu-item-extra-single-selected">
              {selectedText}
            </span>
            {expanded === true && <ExpandLess />}
            {expanded === false && <ExpandMore />}
          </div>
        </button>
        {option.productOptionItems &&
          option.productOptionItems.length > 0 &&
          option.productOptionItems.map((item, index) => (
            <MenuItemExtraSingleItem
              show={expanded}
              onSelect={this.onSelect}
              ref={this.items[`item${index}`]}
              index={index}
              key={index}
              item={item}
            />
          ))}
      </div>
    );
  }
}

export default MenuItemExtraSingle;
